import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule , HTTP_INTERCEPTORS} from '@angular/common/http';
import {GetappointmentsService} from '../getappointments.service';
import {AuthenticationService} from './services/authentication.service';
import {RemindersService} from './services/reminders.service';
import {LoginService} from './services/login.service';
import {ReviewsService} from '../reviews.service';
import { NgxSpinnerModule } from "ngx-spinner";

import {CustomersService} from '../app/customers.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {AuthGuard} from '../app/guard/AuthGuard';
import { ChartsModule } from 'ng2-charts';
import {TokenInterceptorService} from '../app/token-interceptor.service';
import {ForgotpasswordService} from '../app/services/forgotpassword.service';
import {DataTablesModule} from 'angular-datatables';
import { StarRatingModule } from 'angular-star-rating';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RecoverypasswordComponent } from './recoverypassword/recoverypassword.component';
import { WelcomepasswordComponent } from './welcomepassword/welcomepassword.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppointmentsComponent } from './components/appointments/appointments.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { RemindersComponent } from './components/reminders/reminders.component';
import { CustomersComponent } from './components/customers/customers.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DashboardhomeComponent } from './components/dashboardhome/dashboardhome.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MtacComponent } from './components/mtac/mtac.component';
import { AllreviewsComponent } from './components/allreviews/allreviews.component';
//import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { CustomerRequestsComponent } from './components/customer-requests/customer-requests.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CarInspectionComponent } from './components/car-inspection/car-inspection.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IndustriesComponent } from './components/industries/industries.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { FooterComponent } from './components/footer/footer.component';
import { NewnavComponent } from './newnav/newnav.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RecoverypasswordComponent,
    WelcomepasswordComponent,
    DashboardComponent,
    AppointmentsComponent,
    ReviewsComponent,
    RemindersComponent,
    CustomersComponent,
    ReportsComponent,
    SettingsComponent,
    DashboardhomeComponent,
    MtacComponent,
    AllreviewsComponent,
    CustomerRequestsComponent,
    SidenavComponent,
    NavbarComponent,
    CarInspectionComponent,
    IndustriesComponent,
    ContactusComponent,
    FooterComponent,
    NewnavComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    StarRatingModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    NgbModule,
    ChartsModule,
    NgSelectModule,
    RouterModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    CarouselModule,
    MatCarouselModule.forRoot(),
    IvyCarouselModule
    
  ],
  providers: [GetappointmentsService,
    ReviewsService,
    CustomersService,
    AuthenticationService,
    AuthGuard,
    LoginService,
    ForgotpasswordService,
    RemindersService,
    
    
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi:true
    }],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class AppModule { }
