<style>
    .navNav{
      background: transparent; position: absolute;
    }
    li{
      margin-right: 20px;
    }
    .active{
       
    }
    .navImg{
      width: 80%;  margin-left: 50%;
    }
    .navDiv{
      margin-left: 600px;
    }
    .navButton{
        background: linear-gradient(#E96443, #904965); border-radius: 8px;
        box-shadow: 0px 1px 3px #00000029;color:white;
      }
      .navLi{
        
      }
      .socialIcons{
      width: 53px; height: 167px; background: linear-gradient(#9C006C, #00749A); 
  border-bottom-left-radius: 27px; border-top-left-radius: 27px; position: fixed; right: 0; top: 40%;
  }
  .socialIconsImg1{
    width: 33px; height: 33px; margin-top: 25px;
  }
  .socialIconsImg2{
    width: 33px; height: 33px;
  }
    @media screen and (min-width:320px) and (max-width:1199px){
      .navNav{
        background: linear-gradient(#00749A, #551040);width: 100%;position: relative;
    }
      .navImg{
        width: 15%;height: 15%;  margin-left: 0%;
      }
      .navDiv{
        margin-left: 40%;
      }
      .navButton{
        background: linear-gradient(#E96443, #904965); border-radius: 10%;
        box-shadow: 0px 1px 3px #00000029;color:white;width: 40%;
      }
      .navLi{
        width: 40%;
      }
      .socialIcons{
          width: 33px; height: 167px; background: linear-gradient(#9C006C, #00749A); 
      border-bottom-left-radius: 27px; border-top-left-radius: 27px; position: fixed; right:0;
      }
      .socialIconsImg1{
    width: 33px; height: 33px; margin-top: 25px;
  }
  .socialIconsImg2{
    width: 33px; height: 33px;
  }
    }
    @media screen and (min-width:1450px) and (max-width:2300px){
      .navDiv{
        margin-left: 925px;
      }
      nav{
        margin-left: 9%;
      }
    }
   
  
  
  </style>
 <nav class="navbar navbar-expand-lg navbar-light bg navNav">
  <a class="navbar-brand" href="#"><img class="navImg" src="../../assets/designs/mtaclogo_FileQA (1).png" alt="" style="width:150px; "></a><!--width: -webkit-fill-available;-->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" 
  aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse navDiv" id="navbarNav" >
    <ul class="navbar-nav">
      <li class="nav-item abc navLi" data-toggle="collapse" data-target="#navbarNav" aria-expanded="false" routerLinkActive="active">
        <a class="nav-link "  href="#" style="color: #E26246;" [routerLink]="['../mtac']" > 
          <span style="color: white;"> How it works</span></a>
      </li>
      <li class="nav-item abc navLi"  data-toggle="collapse" data-target="#navbarNav" aria-expanded="false"    routerLinkActive="active">
        <a class="nav-link" href="#"  style="color: #E26246;" [routerLink]="['../industries']"> 
          <span style="color: white;">Industries</span> </a>
      </li>
      <li class="nav-item abc navLi"  data-toggle="collapse" data-target="#navbarNav" aria-expanded="false"    routerLinkActive="active">
        <a class="nav-link" href="#"  style="color: #E26246; " [routerLink]="['../contactus']">
          <span style="color: white;">Contact us</span></a>
      </li>
      <button class="btn btn navButton"  data-toggle="collapse" data-target="#navbarNav" aria-expanded="false" [routerLink]="['../login']" >Login</button>
    </ul>
  </div>
</nav>

