import { Injectable } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { LoginData } from '../login/logins';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http:HttpClient) { }

  public LoginData(data:LoginData):Observable<any>{
    let url=`${environment.apiUrl}register/login`;
    console.log('form submitted services page');
    return this.http.post<any>(url,data);

  }
  saveContactUs(data){
    let url=`http://137.59.201.54:84/api/superAdmin/AddCustomerMessage`;
    return this.http.post<any>(url, data)
  }
}
