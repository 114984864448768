import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../app/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    userData: any;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserEmail;
        // debugger;
        if (currentUser) {
           if(state.url === '/login' || state.url === '/'){
            this.router.navigate(['/dashboard']);
            return false;
           }
            return true;
        }
        if(state.url === '/login' || state.url === '/'){
          return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
