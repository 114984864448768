import { Injectable } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarInspectionService {

  constructor(private http:HttpClient) { }

  getmake(){
    let url=`${environment.apiUrl}hitech/GetMakes?ClientId=${1}`;
    return this.http.get(url);
  }
  getModelsById(id){
    let url=`${environment.apiUrl}hitech/GetModelsByMakeId?makeId=`+id+'&ClientId='+1;
    return this.http.get(url);
  }
  getCarInspections(){
    let url=`${environment.apiUrl}MTac/getallncinspectiondata?ClientId=${1}`;
    return this.http.get(url);
}
AddCarinspection(data){
  let url=`${environment.apiUrl}MTac/postncinspectiondata`;
   return this.http.post<any>(url,data);

 }
 
}
