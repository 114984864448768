<div class="welcome_bg">
  <link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
  <!------ Include the above in your HEAD tag ---------->
<img src="../../assets/images/PATTERN_bg2.png" alt="pattern_desing" class="pattern_desing"/>
<div class="border_page">
  <div class="container login-container">
    <div class="row" style="height:500px;">
      <div class="col-md-5 login-form-1">
          <img src="../../assets/images/welcome_man.png" alt="logo" class="welcome-logo"/>
      </div>
      <div class="col-md-5 login-form-2">
        <div class="recover_bg">
          <h6 class="welcome_h6"> Welcome Back</h6>
        <form [formGroup]="wellcomeForm">
          <label>Password</label>
          <input type="password" formControlName="password" placeholder="New Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
          </div>

          <label style="margin-top: 10px;">Confirm Password</label>
          <input type="password" formControlName="confirmPassword" placeholder=" Confirm Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
          <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
              <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
          </div>
            <div class="form-group">
                <input style="margin-top: 25px;" type="submit" (click)="CheckValidation()" class="btnSubmit" value="Submit" />
            </div>
        </form>
      </div>
      </div>
  </div>
          </div>
        </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
  </ngx-spinner>
