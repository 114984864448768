
  <nav class="navbar navbar-expand-md navbar-dark" style="box-shadow: 0 0 5px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  background-color: #fff;">
    <div class="flex-row d-flex">

        <button type="button" class="navbar-toggler mr-2 " data-toggle="offcanvas" title="Toggle responsive left sidebar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <a href="www.mtacpro.com/#/dashboard"><img src="../../assets/images/logo-crop.png" alt="dashboardlogo" style="width: 200px;
        margin-left: 20px;"/></a>
    </div>

    <div class="navbar-collapse collapse" id="collapsingNavbar">
      <b style="position: absolute;right: 15%;">
        <p style="right: 18%;
        font-size: 16px;
        position: absolute;">Welcome</p><br>
         <img src="../../../assets/images/hitech-03.png" alt="company_logo" class="company_logo"> 
      </b>
      <!-- <p  *ngIf="hitechName" style="right: 14%;
      font-size: 16px;
      margin-right: 15px; top: 52px;
      position: absolute;">{{UserName}}</p> -->
          <a class="nav-item nav-link logoutbutton" (click)="logout()">Logout
            <i class="fa fa-sign-out" style="color: #6f09e8;font-size: 20px;"></i> </a>
    </div>

</nav>
