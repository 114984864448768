import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mtac',
  templateUrl: './mtac.component.html',
  styleUrls: ['./mtac.component.scss']
})
export class MtacComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {


  }

}
