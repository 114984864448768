import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import {GetappointmentsService} from '../../../getappointments.service'
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  getUsersByClient:[];
  public dtOptions: any = {};
  public showTable: boolean = false;
  UserAddForm:FormGroup;
  private clickedUser: any = {};
  public statusChangeAction: string;
  clientId: any;
  constructor(private user:GetappointmentsService,private fb: FormBuilder) {
      
  this.UserAddForm=this.fb.group({

    UserName:['',Validators.compose([Validators.required, Validators.minLength(5),Validators.maxLength(50)])],
    Email:['',Validators.compose([Validators.required,Validators.email])],
    Password:['',Validators.compose([Validators.required, Validators.minLength(5),Validators.maxLength(50)])],
    Roles:['',Validators.required],
    ClientId: 1,
  })
  
   }
   getUsers()
   {
    this.user
    .getUsersByClientId()
    .pipe(take(1))
    .subscribe((data: any) => {
      
      this.getUsersByClient = data;
      this.showTable=true;
      console.log(data);
    });
   }
   public get UserAddControl() { return this.UserAddForm.controls; }
   UserAdd() : void{
    const data = {
      UserName: this.UserAddControl["UserName"].value,
      Email: this.UserAddControl["Email"].value,
      Password: this.UserAddControl["Password"].value,
      RoleId: parseInt(this.UserAddControl["Roles"].value),
      ClientId: JSON.parse(this.clientId),
      }
    console.log(data);

      if (data) {
        this.user
          .AddUser(data)
         .pipe(take(1))
          .subscribe((res: any) => {
          if (res.statusCode=== 1){
            swal.fire(
              'Success',
              'User Added Succesfully.',
            'success'
           );
           //  window.alert('User Added Succesfully.');
              this.UserAddForm.reset();
            
              console.log(res);
            }
            else{
              if (res.statusCode===2){
                swal.fire(
                  'Cancelled!',
                  'This Email already Registred',
                  'warning'
                );
              //  window.alert('This Email already Registred');
            }
            else{
              if(res.statusCode===3)
              {
                swal.fire(
                  'Cancelled!',
                  'This Email already Registred',
                  'error'
                );
            //    window.alert('Failed to save the User');
              }
            }
           
          }
          
          });
      }
  
    }
    public statusClick( customer: any): void {
      this.clickedUser = customer;
      this.statusChangeAction = customer.status ? 'De-activate' : 'Activate';
      // this.modalService.open(modalContent, { backdrop: 'static', keyboard: false });
    }

    public changeStatus(): void {
   
  
      this.user.changeUserStatus(this.clickedUser.userId).pipe(take(1)).subscribe((res: any) => {
        if (res.statusCode === 1) {
          this.clickedUser.status = !this.clickedUser.status;
          swal.fire(
            'Success',
            'User '+this.statusChangeAction+' Succesfully.',
          'success'
         );
        }
        else{
          if (res.statusCode===2){
            swal.fire(
              'Cancelled!',
              'User Data Not Found',
              'warning'
            );
          }
          //  window.alert('This Email already Registred');
        }
       
      });
    }
   ngOnInit(): void {
    this.user.getClientId();
    this.getUsers();
    this.clientId  = localStorage.getItem("ClientId");
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [ 5, 10, 25, 50, 100],
      processing: true,
      dom: 'lfrtipB',
      buttons: [
           'excel', 'pdf', 'print'

      ]
    };

  }

}