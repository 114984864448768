import { Injectable } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { data } from 'jquery';


@Injectable({
  providedIn: 'root'
})
export class RemindersService {
  ClientId: any;

  constructor(private http:HttpClient) {
    this.ClientId  = localStorage.getItem("ClientId");
   }
   getClientId()
{
  this.ClientId  = localStorage.getItem("ClientId");
 return this.ClientId;
}
  GetReviewReminderData(){
    let url=`${environment.apiUrl}superAdmin/getallreviewremainders?ClientId=${this.ClientId}`;
    console.log(data);
    return this.http.get(url);
  }
  GetServicesReminderData(){
    let url=`${environment.apiUrl}superAdmin/getallserviceremainders?ClientId=${this.ClientId}`;
    console.log(data);
    return this.http.get(url);
  }
  changeReviewReminderStatus(appointmentId) {
    let url=`${environment.apiUrl}superAdmin/changereviewremaindstatus?ClientId=`+this.ClientId+"&AppointmentId="+appointmentId;
    return this.http.post<any>(url,data);
  }
  changeServiceReminderStatus(appointmentId) {
    let url=`${environment.apiUrl}superAdmin/changeserviceremaindstatus?ClientId=`+this.ClientId+"&AppointmentId="+appointmentId;
    return this.http.post<any>(url,data);
  }
}
