import { Injectable } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http'
import { Observable } from 'rxjs';
import { CustomerData } from './components/customers/customerss';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  ClientId: any;

  constructor(private http:HttpClient) {
    this.ClientId  = localStorage.getItem("ClientId");
   }

  getdata(): Observable<Array<CustomerData>>{
    let url: string=`${environment.apiUrl}superAdmin/GetAllCustomers?ClientId=${this.ClientId}`;
    return this.http.get<Array<CustomerData>>(url);
  }
  
  getCustomerAppointmentHistory(customerId){
    let url=`${environment.apiUrl}superAdmin/getappointmentsbycustomer?CustomerId=`+customerId+'&ClientId='+this.ClientId;
    return this.http.get(url);
  }
  getFilterdDataCustomers(startDate,endDate){
    let url=`${environment.apiUrl}superAdmin/GetAllCustomers?ClientId=`+1+'&startDate='+startDate+'&endDate='+endDate;
    return this.http.get(url);
  }
}
