import { Injectable, Injector } from '@angular/core';
import {HttpInterceptor} from '@angular/common/http';
import {AuthenticationService} from '../app/services/authentication.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private injector:Injector, private router: Router,private route: Router,
    private authenticationService:AuthenticationService,private SpinnerService: NgxSpinnerService) { }
  public intercept(req, next){
    const authenticationService = this.injector.get(AuthenticationService);
    const token = authenticationService.getToken;
    const tokenizedReq = req.clone({
       setHeaders:{
         Authorization: `Bearer ${authenticationService.getToken}`
       }
     });
     //  return next.handle(tokenizedReq);
     return next.handle(tokenizedReq).pipe(catchError(err => {
      if (err.status === 401){
      // if ([401, 403].includes(err.status) && this.authenticationService.currentUser) {
          // auto logout if 401 or 403 response returned from api
          this.SpinnerService.hide();
          this.authenticationService.logout();
          this.SpinnerService.hide();
          this.route.navigateByUrl("/login");

      }
      const error = (err && err.error && err.error.message) || err.statusText;
      console.error(err);
      return throwError(error);
    }))
  }
}
