<!---->
<div class="main_bg">
  <link
    href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css"
    rel="stylesheet"
    id="bootstrap-css"
  />
  <!------ Include the above in your HEAD tag ---------->
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <div class="rowCol1Div">
        <a href="http://mtacpro.com/" routerLink="">
          <img
            src="../../assets/login/Mtac- LOGO.png"
            alt="logo"
            class="login-logo"
          /></a>
      </div>
     
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <div class="rowCol2Div">
        <form class="rowCol2Form" action=""  [formGroup]="recoverypasswordForm" >
          <p class="rowCol2FormP">Recover Password</p>
          <p>Enter your registered email below to receive
            Password reset code</p>
          <div>
            <label for="">Email</label><br>
            <input class="rowCol2Input form-control" type="email" formControlName="email"> 
          </div>
          <div *ngIf="isValidInput('email')" class="alert alert-danger">
            <div *ngIf="recoverypasswordForm.controls['email'].errors.required">
                  Email is required.
            </div>
          <div *ngIf="recoverypasswordForm.controls['email'].errors.pattern">
               Must be a valid email format.
          </div>
          </div>
          
          <br>
          
          <br>
          <div>
            <button class="btn btn-primary rowCol2Button" type="submit" (click)="onSubmit()">Submit</button>
          </div>
          <br>
          
        </form>
      </div>
      
    </div>
  </div>

<!---->
<!--
<div class="main_bg">
  <link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">


  <div class="container login-container">
              <div class="row" style="height:500px;">
                  <div class="col-md-4 login-form-1">
                    <a href="http://mtacpro.com/" routerLink="">
                      <img
                        src="../../assets/images/logo-crop.png"
                        alt="logo"
                        class="login-logo"
                      /></a>

                      <div class="recover_bg" style="margin-top:20px;">
                        <h4 class="recovery_h4"> Recover Password</h4>

                        <p  class="recovery_p">Enter your registered email below to receive<br>
                           Password reset code</p>
                      <form [formGroup]="recoverypasswordForm">
                          <div class="form-group" style="margin-top: 5px;">
                            <div class="input-group">
                              <input type="email" formControlName="email" class="form-control logininput" placeholder="Enter Email Address" value="" />
                              <i class="fa fa-envelope" aria-hidden="true" style="color: dimgray;
                              right: 20px;margin-top: 10px;position: absolute;"></i>
                            </div>
                            </div>
                            <div *ngIf="isValidInput('email')" class="alert alert-danger">
                              <div *ngIf="recoverypasswordForm.controls['email'].errors.required">
                                    Email is required.
                              </div>
                            <div *ngIf="recoverypasswordForm.controls['email'].errors.pattern">
                                 Must be a valid email format.
                            </div>
                            </div>
                          <div class="form-group">
                              <input type="submit" (click)="onSubmit()" class="btnSubmit" value="Submit" />
                          </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-md-4 login-form-2">

                  </div>
              </div>
          </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
  </ngx-spinner>

  -->