import { Component, OnInit, ɵConsole } from '@angular/core';
import { from } from 'rxjs';
import { take } from 'rxjs/operators';
import {FormBuilder,FormGroup,Validators,FormControl,} from '@angular/forms';
import{ CarInspectionService} from 'src/app/services/car-inspection.service'
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-car-inspection',
  templateUrl: './car-inspection.component.html',
  styleUrls: ['./car-inspection.component.css']
})
export class CarInspectionComponent implements OnInit {
  public dtOptions: any = {};
  public dtOptionsone: any = {};
public showTable: boolean = false;
carInspectionList:[]
makesdata:[];
modelsdata:[];
Inspection: FormGroup;
  constructor( private car:CarInspectionService,private fb:FormBuilder,private SpinnerService: NgxSpinnerService) {
    this.Inspection = this.fb.group({
      ClientID: 1,
      Name: ['',Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(50)])],
      NC_LIC_Number: [''],
      VehicleID: ['',Validators.required],
      DueDate:['',Validators.compose([Validators.required,Validators.email])],
      ValidToDate: ['',Validators.required],
      VehicleBrand: ['',Validators.required],
      GrossWeight: ['',Validators.required],
      ShippingWeight:[''],
      Style: ['',Validators.required],
      Year: ['',Validators.required],
      Fuel: ['',Validators.required],
      Classification: ['',Validators.required],
      TotalFee: ['',Validators.required],
      Country: ['',Validators.required],
      Address: ['',Validators.required],
     
    });

   

    this.car
    .getCarInspections()
    .pipe(take(1))
    .subscribe((data: any) => {
      console.log(data);
      this.carInspectionList = data;
      console.log(this.carInspectionList);
      this.showTable = true;
      this.SpinnerService.hide();
   
    });
    this.car
    .getmake()
    .pipe(take(1))
    .subscribe((data: any) => {

      this.makesdata = data;
      console.log(data);
    });
   }

   getModels( id :number)
   {
     this.car
     .getModelsById(id)
     .pipe(take(1))
     .subscribe((data: any) => {
 
       this.modelsdata = data;
       console.log(data);
     });
   }

   InspectionAdd() : void{
    

  console.log(this.Inspection.value);
   
    
     if (this.Inspection.value) {
       this.car
         .AddCarinspection(this.Inspection.value)
        .pipe(take(1))
         .subscribe((res: any) => {
         if (res.statusCode=== 1){
             window.alert('Car inspection Details Added Succesfully.');
             (<any>$("#demo-1")).modal('hide');
             window.location.reload();
             this.Inspection.reset();
             console.log(res);
             this.car
             .getCarInspections()
             .pipe(take(1))
             .subscribe((data: any) => {
               console.log(data);
               this.carInspectionList = data;
               console.log(this.carInspectionList);
               this.showTable = true;
               this.SpinnerService.hide();
            
             });
            
           }
           else{
             if (res.statusCode!==1){
               window.alert('Please enter valied details');
           }
         }
         });
     }
   }
  ngOnInit(): void {
    
  }
 
}
