<app-navbar></app-navbar>
<div class="row mainrow_margin">
   <div class="col-md-2">
    <app-sidenav></app-sidenav>
   </div>
   <div class="col-md-10">
<div class="container">
    <!-- Nav tabs -->
    <div class="row main_section">
     <h4> Report Details</h4>
      <br>
      </div>
    <ul class="nav nav-tabs nav_section" role="tablist">
      <li class="nav-item btnmtac">
        <a class="nav-link active" data-toggle="tab" href="#Appointments">Appointments</a>
      </li>
      <li class="nav-item btnmtac">
        <a class="nav-link" data-toggle="tab" href="#Reviews">Reviews</a>
      </li>
      <li class="nav-item btnmtac">
        <a class="nav-link" data-toggle="tab" href="#ReviewReminders">Review Reminders</a>
      </li>
      <li class="nav-item btnmtac">
        <a class="nav-link" data-toggle="tab" href="#serviceReminders">Service Reminders</a>
      </li>
    </ul>


<!-- Tab panes -->
<div class="tab-content">
    <div id="Appointments" class="container tab-pane active">
     <div class="card reportcard_appointment">
      <div style="margin:15px;">
        <div class="chart-container" >
            <h5 class="section_twoheading">Appointments</h5>
            <ul class="nav nav-tabs tabsbuttons" role="tablist">
                <li class="nav-item btnmtac1">
                    <a class="nav-link active" data-toggle="tab"(click)="getchartnumber1(3)">3M</a>
                </li>
                <li class="nav-item btnmtac1">
                    <a class="nav-link" data-toggle="tab" (click)="getchartnumber1(6)">6M</a>
                </li>
                <li class="nav-item btnmtac1">
                  <a class="nav-link" data-toggle="tab" (click)="getchartnumber1(11)">YTD</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber1(12)">1Y</a>
            </li>
            <li class="nav-item btnmtac1">
              <a class="nav-link" data-toggle="tab" (click)="getchartnumber1(36)">3Y</a>
          </li>
          <li class="nav-item btnmtac1">
            <a class="nav-link" data-toggle="tab" (click)="getchartnumber1(40)">MAX</a>
        </li>
              </ul>

            <canvas width="100%!important"
              baseChart
              #visitSaleChart1
              [chartType]="'bar'"
              [datasets]="visitSaleChartData1"
              [labels]="visitSaleChartLabels1"
              [options]="visitSaleChartOptions1"
              [colors]="visitSaleChartColors1"
            ></canvas>

              <br>

          </div>
      </div>
    </div>

    <div class="row dashboardappointments card" style="margin-top:-10px;">
        <div class="container">

          <div class="row" style="margin-top:1%;margin-bottom: 1%;">
            <div class="col-md-4">
              <h5 class="appoinment_heading">Appointments History</h5>
            </div>
          </div>

          <div class="row">
              <div class="col-md-8">
                   <div class="row">
                    <div class="col-md-5 form-group">
                      <div class="row">
                          <div class="col-md-3">
                            <label class="form_row_heading" style="margin-top: 4px;">From</label>
                          </div>
                          <div class="col-md-9">
                            <input type="text" placeholder="MM/DD/YYYY" (change)="getStartDate($event.target.value)"
                            onfocus="(this.type='date' )" class="wizard-required"  class="form-control wizard-required" id=txtFrom name="dropDate" #from>

                          </div>
                      </div>

                       <!-- <div class="wizard-form-error" style="color: red;">Date is Required</div> -->
                      </div>
                      <div class="col-md-5 form-group">
                       <div class="row">
                        <div class="col-md-2">
                          <label class="form_row_heading" style="margin-top: 4px;">To</label>
                        </div>
                        <div class="col-md-9">
                          <input type="text" placeholder="MM/DD/YYYY" (change)="getEndDate($event.target.value)"
                          onfocus="(this.type='date' )"   class="wizard-required" id=txtTo bsDatepicker minDate="bindstartDate" class="form-control wizard-required"  name="dropDate" #to >

                        </div>

                       </div>

                         <!-- <div class="wizard-form-error" style="color: red;">Date is Required</div> -->
                        </div>
                        <div class="col-md-">
                          <div class="row">
                              <div class="col-md-5">
                                <button type="submit"   [disabled]="(from.value.length === 0  || to.value.length === 0  )"  class="btn btn-primary"   data-dismiss="modal"   (click)="getFilterdData()">Go</button>
                              </div>
                              <!-- <div class="col-md-7">
                                <button type="button"   (click)= AppoitnmentsfromFilter() class="btn btn-danger">Reset</button>
                              </div> -->
                          </div>


                        </div>
                  </div>

              </div>

              <div class="col-md-4">
                <div class="row">
                    <div class="col-md-5">
                      <label class="form_row_heading" style="margin-top: 4px;">Select Status : </label>
                    </div>
                    <div class="col-md-7">
                      <select  class="form-control" id="status"   name="status"  (change)="AppointmentStatus($event.target.value)">
                        <option value="9">Accepted</option>
                        <option value="1">Confirmed</option>
                        <option value="2">Technician Assigned</option>
                        <option value="3">IN-Progress</option>
                        <option value="4">Work Done-Technician</option>
                        <option value="5">Confirmed by Supervisor</option>
                        <option  value="6">Pickup Scheduled</option>
                        <option value="7">Cancelled</option>
                        <option value="8">Re-schedule</option>
                        <option selected value="0">All Appointments</option>
                    </select>
                    </div>
                </div>


              </div>
          </div>

            <div class="table-responsive">
              <table class="table table-bordered" id="example" *ngIf="showTableAppointment" datatable [dtOptions]="dtOptions"   style="width:100%;">
                <thead class="thead-dark">
                    <tr>
                     <th>Ticket Id</th>
                    <th>First<br/>Name</th>
                    <th>Last<br/>Name</th>
                    <th>Phone<br/>Number</th>
                    <th>Service<br>Date</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Year</th>
                    <th>Details</th>

                </tr>
                </thead>
                <tbody>
                <tr *ngFor= "let datas of data">
                  <td>{{datas.appointmentId}}</td>
                  <td>{{datas.firstName}}</td>
                 <td>{{datas.lastName}}</td>
                 <td>{{datas.phoneNo}}</td>
                 <td>{{datas.dropOffDate | date:'MM-dd-yyyy'}}</td>
                 <td>{{datas.vechileBrand}}</td>
                 <td>{{datas.vechileModel}}</td>
                 <td>{{datas.vechileManufacturingYear}}</td>
                 <td>
                  <button class="btn btn-primary"  data-toggle="modal" data-target=".bd-example-modal-lg10" (click)="GetAppointmentviewById(datas.appointmentId)" style="    width: 35px;
                  padding: 5px;margin-left:2px;"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                </tr>
                </tbody>
            </table>
            <div class="viewmore">
              <a href="" routerLink="/appointments">Go to Appointments >></a>

             </div>
            </div>

        </div>
      </div>
    </div>
    <div id="Reviews" class="container tab-pane fade">
      <div class="card reportcard_appointment">
        <div style="margin:15px;">
          <h5 class="section_twoheading">Reviews</h5>
          <ul class="nav nav-tabs tabsbuttons" role="tablist">
              <li class="nav-item btnmtac1">
                  <a class="nav-link active" data-toggle="tab"(click)="getchartnumber3(3)">3M</a>
              </li>
              <li class="nav-item btnmtac1">
                  <a class="nav-link" data-toggle="tab" (click)="getchartnumber3(6)">6M</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber3(11)">YTD</a>
            </li>
            <li class="nav-item btnmtac1">
              <a class="nav-link" data-toggle="tab" (click)="getchartnumber3(12)">1Y</a>
          </li>
          <li class="nav-item btnmtac1">
            <a class="nav-link" data-toggle="tab" (click)="getchartnumber3(36)">3Y</a>
        </li>
        <li class="nav-item btnmtac1">
          <a class="nav-link" data-toggle="tab" (click)="getchartnumber3(40)">MAX</a>
      </li>
            </ul>
          <canvas width="700px"  height="300px"
            baseChart
            #visitSaleChart1
            [chartType]="'bar'"
            [datasets]="visitSaleChartData3"
            [labels]="visitSaleChartLabels3"
            [options]="visitSaleChartOptions3"
            [colors]="visitSaleChartColors3"
          ></canvas>
          </div>
      </div>

      <div class="row dashboardappointments card" style="margin-top:-10px;">
          <div class="container">
            <h5 class="appoinment_heading">Reviews</h5>
            <div class="row">
              <div class="col-md-7">

              </div>
              <div class="col-md-5">
                  <div class="row">
                    <div class="col-md-5">
                      <label class="form_row_heading" style="margin-top: 4px;">Select Source : </label>
                    </div>
                    <div class="col-md-7">
                      <select  class="form-control" id="status"   name="status"  (change)="ReviewStatus($event.target.value)">

                        <option value="1">MTac</option>
                        <option value="2">FaceBook</option>
                        <option value="4">Twitter</option>
                        <option value="3">Google</option>
                        <option value="7">Yelp</option>
                        <option value="5">Others</option>
                        <option  value="6">Yahoo</option>
                        <option selected value="0">All Reviews</option>

                    </select>
                    </div>
                  </div>
              </div>

          </div>
            <div class="row" style="margin-top:1%;margin-left: 28px;margin-bottom: 1%;">
              <div class="col-md-3">
               <h4 style="margin-left: -30px;
               font-weight: 600;
               font-size: 22px;
               font-family: 'Poppins';"></h4>
              </div>
              <div class="col-md-3">
               </div>
             <div class="col-md-3">

                 </div>

            </div>
          <div class="table-responsive">
              <table class="table table-bordered" id="example" *ngIf="showTableReviews" datatable [dtOptions]="dtOptions"   style="width:100%;">
                <thead class="thead-dark">
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Rating</th>
                    <th>Review</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor= "let ReviewDatas of ReviewsBySource">
                    <td>{{ReviewDatas.dateofService | date:'MM-dd-yyyy'}}</td>
                    <td>{{ReviewDatas.firstName}} {{ReviewDatas.lastName}}</td>
                    <td>{{ReviewDatas.email}}</td>
                    <td><ngb-rating [max]="5" [(rate)]="ReviewDatas.rating" [readonly]="true"></ngb-rating></td>
                    <td style="width:250px;">{{ReviewDatas.review}}</td>
                    <td style="width:250px;">{{ReviewDatas.comment}}</td>
                   </tr>
                </tbody>
              </table>
        </div>
        <div class="viewmore">

        </div>
        </div>
        </div>

      </div>
    <div id="ReviewReminders" class="container tab-pane fade">
      <div class="card reportcard_appointment">
        <div style="margin:15px;">
        <h5 class="section_twoheading">Review Reminders</h5>
        <ul class="nav nav-tabs tabsbuttons" role="tablist">
            <li class="nav-item btnmtac1">
                <a class="nav-link active" data-toggle="tab"(click)="getchartnumber4(3)">3M</a>
            </li>
            <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber4(6)">6M</a>
            </li>
            <li class="nav-item btnmtac1">
              <a class="nav-link" data-toggle="tab" (click)="getchartnumber4(11)">YTD</a>
          </li>
          <li class="nav-item btnmtac1">
            <a class="nav-link" data-toggle="tab" (click)="getchartnumber4(12)">1Y</a>
        </li>
        <li class="nav-item btnmtac1">
          <a class="nav-link" data-toggle="tab" (click)="getchartnumber4(36)">3Y</a>
      </li>
      <li class="nav-item btnmtac1">
        <a class="nav-link" data-toggle="tab" (click)="getchartnumber4(40)">MAX</a>
    </li>
          </ul>

          <canvas width="700px"  height="300px"
             baseChart
            #visitSaleChart1
            [chartType]="'bar'"
            [datasets]="visitSaleChartData4"
            [labels]="visitSaleChartLabels4"
            [options]="visitSaleChartOptions4"
            [colors]="visitSaleChartColors4"
          ></canvas>

        </div>
    </div>

    <div class="row dashboardappointments card" style="margin-top:-10px;">
        <div class="container">
          <h3>Review Reminders</h3>
          <div class="row" style="margin-top:1%;margin-left: 28px;margin-bottom: 1%;">
            <div class="col-md-3">
             <h4 style="margin-left: -30px;
             font-weight: 600;
             font-size: 22px;
             font-family: 'Poppins';"></h4>
            </div>
            <div class="col-md-3">
             </div>
           <div class="col-md-3">

               </div>

          </div>
          <div class="table-responsive">
            <table class="table table-bordered" id="example" *ngIf="showTable" datatable [dtOptions]="dtOptions"   style="width:100%;">
              <thead class="thead-dark">
                  <tr>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th>Service Name</th>
                  <th>Servcie Date</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor= "let list of getReviewRemindersData">
               <td>{{list.name}}</td>
               <td>{{list.mobile}}</td>
               <td>{{list.email}}</td>
               <td>{{list.serviceNames}}</td>
               <td>{{list.lastServicedOn | date:'MM-dd-yyyy'}}</td>
              </tr>
              </tbody>
          </table>
        </div>
        </div>
      </div>
    </div>


    <div id="serviceReminders" class="container tab-pane fade">
      <div class="card reportcard_appointment">
        <div style="margin:15px;">
          <h5 class="section_twoheading">Service Reminders</h5>
          <ul class="nav nav-tabs tabsbuttons" role="tablist">
              <li class="nav-item btnmtac1">
                  <a class="nav-link active" data-toggle="tab"(click)="getchartnumber2(3)">3M</a>
              </li>
              <li class="nav-item btnmtac1">
                  <a class="nav-link" data-toggle="tab" (click)="getchartnumber2(6)">6M</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber2(11)">YTD</a>
            </li>
            <li class="nav-item btnmtac1">
              <a class="nav-link" data-toggle="tab" (click)="getchartnumber2(12)">1Y</a>
          </li>
          <li class="nav-item btnmtac1">
            <a class="nav-link" data-toggle="tab" (click)="getchartnumber2(36)">3Y</a>
        </li>
        <li class="nav-item btnmtac1">
          <a class="nav-link" data-toggle="tab" (click)="getchartnumber2(40)">MAX</a>
      </li>
            </ul>

        <canvas width="500px"  height="200px"
          baseChart
          #visitSaleChart1
          [chartType]="'bar'"
          [datasets]="visitSaleChartData2"
          [labels]="visitSaleChartLabels2"
          [options]="visitSaleChartOptions2"
          [colors]="visitSaleChartColors2"
        ></canvas>
        </div>
    </div>

    <div class="row dashboardappointments card" style="margin-top:-10px;">
        <div class="container">

          <h3>Service Reminders</h3>
          <div class="row" style="margin-top:1%;margin-left: 28px;margin-bottom: 1%;">
            <div class="col-md-3">
             <h4 style="margin-left: -30px;
             font-weight: 600;
             font-size: 22px;
             font-family: 'Poppins';"></h4>
            </div>
            <div class="col-md-3">
             </div>
           <div class="col-md-3">

               </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered" id="example" *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="width:100%;">

              <thead class="thead-dark">
                  <tr>
                  <th>Name</th>
                  <th>Phone<br/>Number</th>
                  <th>Email</th>
                  <th>Service Name</th>
                  <th>Last Service Date</th>
                  <th>Next Service Date</th>


              </tr>
              </thead>
              <tbody>
              <tr *ngFor= "let list of ServiceRemindersData">

               <td>{{list.name}}</td>
               <td>{{list.mobile}}</td>
               <td>{{list.email}}</td>
               <td>{{list.serviceName}}</td>
               <td>{{list.lastServicedOn | date:'MM-dd-yyyy'}}</td>
               <td>{{list.dueDate | date:'MM-dd-yyyy'}}</td>
              </tr>
              </tbody>
          </table>
        </div>
        </div>
      </div>
    </div>

</div>
</div>
</div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
  </ngx-spinner>
  <div class="modal fade bd-example-modal-lg5" id="demo-7" tabindex="-1" style="overflow:scroll;">
    <div class="modal-dialog modal-dialog-centered appointment_popup_main">
        <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title" id="exampleModalLabel-2"><b>Filters</b></h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
              <h6  ><b>Date Range</b></h6>
             <div class="row">
              <div class="col-md-6 form-group">
                <label class="form_row_heading">From<span class="text-danger">*</span></label>
                 <input type="text" placeholder="MM/DD/YYYY" (change)="getStartDate($event.target.value)"
                 onfocus="(this.type='date' )" class="wizard-required"  class="form-control wizard-required" formControlName="dropOffDate" name="dropDate" #from>

                </div>
                <div class="col-md-6 form-group">
                  <label class="form_row_heading">To<span class="text-danger">*</span></label>
                   <input type="text" placeholder="MM/DD/YYYY" (change)="getEndDate($event.target.value)"
                   onfocus="(this.type='date' )" class="wizard-required" id="date" bsDatepicker minDate="bindstartDate" class="form-control wizard-required" formControlName="dropOffDate" name="dropDate"#to>

                  </div>
            </div>
             </div>
             <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
             <button type="submit" class="btn btn-success"  [disabled]="(from.value.length === 0  || to.value.length === 0  )"   data-dismiss="modal"   (click)="getFilterdData()">Submit</button>
         </div>
        </div>
    </div>
  </div>

  <div class="modal fade bd-example-modal-lg5" id="demo-8" tabindex="-1" style="overflow:scroll;">
    <div class="modal-dialog modal-dialog-centered appointment_popup_main">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel-2">Select Start Date and End Date </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
             <div class="row">
              <div class="col-md-6 form-group">
                <label class="form_row_heading">Start Date <span class="text-danger">*</span></label>
                 <input type="text" placeholder="MM/DD/YYYY" (change)="getStartDate($event.target.value)"
                 onfocus="(this.type='date' )" class="wizard-required"  class="form-control wizard-required" formControlName="dropOffDate" name="dropDate">

                 <!-- <div class="wizard-form-error" style="color: red;">Date is Required</div> -->
                </div>
                <div class="col-md-6 form-group">
                  <label class="form_row_heading">End Date <span class="text-danger">*</span></label>
                   <input type="text" placeholder="MM/DD/YYYY" (change)="getEndDate($event.target.value)"
                   onfocus="(this.type='date' )" class="wizard-required" id="date" bsDatepicker minDate="bindstartDate" class="form-control wizard-required" formControlName="dropOffDate" name="dropDate">

                   <!-- <div class="wizard-form-error" style="color: red;">Date is Required</div> -->
                  </div>
            </div>
             </div>
             <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
             <button type="submit" class="btn btn-success"   data-dismiss="modal"   (click)="getFilterdData()">Submit</button>
         </div>
        </div>
    </div>
  </div>

  <div class="modal fade bd-example-modal-lg5" id="demo-9" tabindex="-1" style="overflow:scroll;">
    <div class="modal-dialog modal-dialog-centered appointment_popup_main">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel-2">Select Start Date and End Date </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
             <div class="row">
              <div class="col-md-6 form-group">
                <label class="form_row_heading">Start Date <span class="text-danger">*</span></label>
                 <input type="text" placeholder="MM/DD/YYYY" (change)="getStartDate($event.target.value)"
                 onfocus="(this.type='date' )" class="wizard-required"  class="form-control wizard-required" formControlName="dropOffDate" name="dropDate">

                 <!-- <div class="wizard-form-error" style="color: red;">Date is Required</div> -->
                </div>
                <div class="col-md-6 form-group">
                  <label class="form_row_heading">End Date <span class="text-danger">*</span></label>
                   <input type="text" placeholder="MM/DD/YYYY" (change)="getEndDate($event.target.value)"
                   onfocus="(this.type='date' )" class="wizard-required" id="date" bsDatepicker minDate="bindstartDate" class="form-control wizard-required" formControlName="dropOffDate" name="dropDate">

                   <!-- <div class="wizard-form-error" style="color: red;">Date is Required</div> -->
                  </div>
            </div>
             </div>
             <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
             <button type="submit" class="btn btn-success"   data-dismiss="modal"   (click)="getFilterdData()">Submit</button>
         </div>
        </div>
    </div>
  </div>

  <div class="modal fade bd-example-modal-lg5" id="demo-10" tabindex="-1" style="overflow:scroll;">
    <div class="modal-dialog modal-dialog-centered appointment_popup_main">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel-2">Select Start Date and End Date </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
             <div class="row">
              <div class="col-md-6 form-group">
                <label class="form_row_heading">Start Date <span class="text-danger">*</span></label>
                 <input type="text" placeholder="MM/DD/YYYY" (change)="getStartDate($event.target.value)"
                 onfocus="(this.type='date' )" class="wizard-required"  class="form-control wizard-required" formControlName="dropOffDate" name="dropDate">

                 <!-- <div class="wizard-form-error" style="color: red;">Date is Required</div> -->
                </div>
                <div class="col-md-6 form-group">
                  <label class="form_row_heading">End Date <span class="text-danger">*</span></label>
                   <input type="text" placeholder="MM/DD/YYYY" (change)="getEndDate($event.target.value)"
                   onfocus="(this.type='date' )" class="wizard-required" id="date" bsDatepicker minDate="bindstartDate" class="form-control wizard-required" formControlName="dropOffDate" name="dropDate">

                   <!-- <div class="wizard-form-error" style="color: red;">Date is Required</div> -->
                  </div>
            </div>
             </div>
             <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
             <button type="submit" class="btn btn-success"    data-dismiss="modal"   (click)="getFilterdData()">Submit</button>
         </div>
        </div>
    </div>
  </div>

   <!-- view customer model popup -->
   <div class="modal fade bd-example-modal-lg10" id="demo-customers" tabindex="-1" style="overflow:scroll;">
    <div class="modal-dialog modal-xl appointment_popup_main">
      <div class="modal-content" style="padding-left:15px;padding-right:15px;">
        <h4 class="modal-title col-md-9 text-danger" id="exampleModalLabel" style="margin-bottom:0px;
        margin-top:15px;
        margin-left: 15px;">Appointment Details</h4>
        <button type="button" class="btn btn-danger" data-dismiss="modal"  style="position: absolute;
        right: 4px;border-radius: 50%;
        top: 2px;">&times;</button>
        <button type="button" class="close" data-dismiss="modal"><i class="icon-xs-o-md"></i></button>
        <div class="modal-body" style="font-size: 13px;">


          <div class="row" style="margin-top: 10px;margin-bottom: 10px; margin-left: 240px; font-size: 14px;">
            <div class="col-md-3">
              <label class="font-weight-bold">Customer ID</label>
              <label> : {{appointment.customerId}}</label>

            </div>

            <div class="col-md-3">
              <label class="font-weight-bold">Appointment ID</label>
              <label> : {{appointment.appointmentId}}</label>

            </div>
            <div  class="col-md-4">
              <label class="font-weight-bold">Appointment Type</label>

              <label> : {{appointment.appointmentFrom}}</label>
            </div>

          </div>
          <div class="row">

            <div class="col-md-6 form-group">

                <ul class="nav nav-tabs1" style="height: 38px;margin-bottom: 15px;">
                  <li class="nav-item">
                    <a class="nav-link active" style="    background-color: #211e50;
                    color: white;border-top-right-radius: 25px ;    height: 38px; font-weight: 700; padding-bottom: 27px;">Customer Details</a>
                  </li>


                </ul>
            </div>


        </div>
            <div class="row" style="margin-top: -15px">
              <div class="col-md-4 form-group">
                <label class="font-weight-bold"> Name </label>
                <label> : {{appointment.firstName}}  {{appointment.lastName}}</label>

                    </div>

                    <div class="col-md-4 form-group">
                      <label class="font-weight-bold">Mobile Number </label>
                      <label> : {{appointment.phoneNo}}</label>
                         </div>
                    <div class="col-md-4">
                      <label class="font-weight-bold">Email </label>
                      <label> : {{appointment.email}}</label>
                        </div>




          </div>



          <div class="row">

              <div class="col-md-6 form-group">


                  <ul class="nav nav-tabs1" style="height: 38px; margin-bottom: 15px;">
                    <li class="nav-item">
                      <a class="nav-link active" style="    background-color: #211e50;
                      color: white;border-top-right-radius: 25px ;    height: 38px;font-weight: 700;padding-bottom: 27px;padding-right: 30px;">Vehicle Details</a>
                    </li>

                  </ul>
              </div>


          </div>


          <div class=" row" style="margin-top: -15px">
            <div class="col-md-4 form-group">
              <label class="font-weight-bold">Year </label>
              <label> : {{appointment.vechileManufacturingYear}}</label><br>
          </div>
              <div class="col-md-4 form-group">

                  <!--  <input type="text" class="form-control" id="txtMake" placeholder=" Make(Brand) ">-->
                  <label class="font-weight-bold">Make</label>
                  <label>: {{appointment.vechileBrand}}</label><br>
              </div>
              <div id="ddlModel" class="form-group col-md-4">
                <label class="font-weight-bold">Model </label>
                <label> : {{appointment.vechileModel}}</label><br>
              </div>

          </div>


          <div class="row" >

            <div class="col-md-4">
              <label class="font-weight-bold">Mileage </label>
              <label> : {{appointment.mileage}} miles</label>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold">VIN </label>
                  <label>: {{appointment.vehicleIdentificationNumber}}</label>
                    </div>
          </div>

          <div class="row">

            <div class="col-md-6 form-group">



                <ul class="nav nav-tabs1" style="height: 38px; margin-top: 15px; ">
                  <li class="nav-item">
                    <a class="nav-link active" style="    background-color: #211e50;
                    color: white;border-top-right-radius: 25px ;    height: 38px;font-weight: 700; padding-bottom: 27px;padding-right: 30px;">Service Details</a>
                  </li>

                </ul>
            </div>
          </div>
          <div class="row" style="margin-top: -10px;">
            <div  class="col-md-4">
              <label class="font-weight-bold ">Services Requested</label>

              <label class="mt-3 mb-3">: {{appointment.serviceRequested}}</label>
            </div>
            <div class="col-md-4">
              <label class="font-weight-bold">Additional Services</label>

              <label  class="mt-3 mb-3">: {{appointment.additionalServices}}</label>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold">Removed Services</label>

                  <label  class="mt-3 mb-3">: {{appointment.removedServices}}</label>
                    </div>
          </div>

<div class="row" style="margin-top: 10px;">

  <div  class="col-md-4">
    <label class="font-weight-bold">Service Date</label>

    <label> : {{appointment.dropOffDate |  date:'MM-dd-yyyy'}}</label>
  </div>
  <div  class="col-md-4">
    <label class="font-weight-bold">Drop Off Time</label>

    <label> : {{appointment.dropOffTime}}</label>
  </div>

  <div class="col-md-4">
    <label class="font-weight-bold">Comments</label>

    <label> : {{appointment.comments}}</label>
  </div>
</div>
<div style="margin-top: 15px;">
  <div class="row">
    <div  class="col-md-6">
      <label class="font-weight-bold">Appointment status</label>

      <label> :  {{VechileStatus(appointment.status)}}</label>
    </div>
  </div>
</div>




        </div>
    </div>
    </div>
  </div>



