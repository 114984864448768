import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,FormGroup,Validators,FormControl,
} from '@angular/forms';
import {allreviewsData} from './allreviewss';
import{ReviewsService} from '../../../reviews.service'
import { map, take } from 'rxjs/operators';
import{ ReviewsData } from '../reviews/ReviewData'
import {Router, ActivatedRoute } from "@angular/router";
import swal from 'sweetalert2';
import {GetappointmentsService} from '../../../getappointments.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-allreviews',
  templateUrl: './allreviews.component.html',
  styleUrls: ['./allreviews.component.css']
})
export class AllreviewsComponent implements OnInit {
  appointmentId:string;
  allreviewsForm: FormGroup;
//review:[];
  recom: boolean;
  config: any;
  reviewcount:number;

  pipe = new DatePipe('en-US');
        now = Date.now();
        public ReviewData: ReviewsData = {
          reviewTotalCount: 0,
          reviewFiveCount: 0,
          reviewFourCount: 0,
          reviewThreeCount: 0,
          revieTwoCount: 0,
          reviewOneCount: 0,
          recommedationCount: 0,
          ratingAverage:0,
          allReviews:[],
          fbReviews:[],
          googleReviews:[],
          mTacReviews:[],
          otherReviews:[],
          twitterReviews:[],
          yahooReviews:[],
          yelpReviews:[]
        };
  public appointment: any = {
    appointmentId: 0,
    firstName: '',
    lastName: '',
    phoneNo: '',
    email:'',
    vechileBrand:'',
    vechileModel: '',
    vechileManufacturingYear:'',
    preferredContact:'',
    dropOffDate:'',
    dropOffTime:'',
    serviceRequestedMessage:'',
    vehicleIdentificationNumber:'',
    status:'',
    mileage:0,
    customerId:0,
    clientId:0,
    reviewStatus:'',
   
    comments:[''],
  
  };
  UserName: string;
  clientId: string;
  constructor(private service:GetappointmentsService,
    private fb: FormBuilder, 
    private user: ReviewsService,
    private route: ActivatedRoute,
    
    private SpinnerService: NgxSpinnerService) { 
      
    }

  ngOnInit(): void {
    this.UserName  =localStorage.getItem("usernamek");
    
  //  this.user.getClientId();
    this.initForm();
     this.appointmentId= this.route.snapshot.queryParamMap.get("param");
     this.clientId= this.route.snapshot.queryParamMap.get("ClientId");
console.log(this.clientId);
     if(this.appointmentId!=null)
     {
      if (this.appointmentId.includes(" "))
      {
        this.appointmentId = this.appointmentId.replace(' ', '+');
        console.log(this.appointmentId);
      }
      (<any>$("#exampleModaloneside")).modal('show');
       this.GetAppointmentById(this.appointmentId);

     }
    this.getallReviews();
    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.reviewcount
    };
  }

  pageChanged(event){
    this.config.currentPage = event;
  }
  

  initForm(){
    this.allreviewsForm = this.fb.group({
    firstName:['',Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(50)])],
    lastName: ['',Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(50)])],
    email:['',Validators.compose([Validators.required,Validators.email])],
    phone: ['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    review: ['',Validators.compose([Validators.required])],
    dateofService: ['',Validators.compose([Validators.required])],
    comment: ['',Validators.compose([Validators.required])],
    recommendation:['',Validators.compose([Validators.required])],
    rating :['',Validators.compose([Validators.required])],
    ClientId:0,
    appointmentId:0

    });

  }
  public get allreviewsFormControl() { return this.allreviewsForm.controls; }
  // public submitForm(): void {

  //    console.log(this.allreviewsForm.value);
  // }



  private getallReviews(): void{
    this.SpinnerService.show();
    this.user.getReviewsonAllReviews(this.clientId).pipe(take(1)).subscribe((data:any) =>{

     this.ReviewData=data;
    this.reviewcount= this.ReviewData.allReviews.length;
    
     this.SpinnerService.hide();

     // console.log("Review data",data);
    })
  }

  
  public getSocialMediaImg(mediaId: number): string{
    switch(mediaId) {
      case 1: {
        return 'http://mtacpro.com/assets/images/logo-crop.png'
        break;

      }
      case 2: {
        return 'https://www.facebook.com/images/fb_icon_325x325.png'

        break;

      }
      case 3: {
        return 'https://logosvector.net/wp-content/uploads/2015/09/google-plus-new-icon-logo.png'
        break;

      }
       case 4: {
       return 'https://cdn2.iconfinder.com/data/icons/metro-uinvert-dock/256/Twitter_NEW.png'
         break;

       }
      case 5: {
        return 'https://cdn.iconscout.com/icon/premium/png-256-thumb/review-59-918101.png'
       
        break;

      }
      case 6: {
        return 'https://png.pngtree.com/png-clipart/20190613/original/pngtree-yahoo-purple-icon-png-image_3591532.jpg'

        break;
    }
    
    case 7: {
      return ' https://image.flaticon.com/icons/png/512/168/168812.png'

      break;
  }
    }

      }


      GetAppointmentById(appointmentId :string)
      {
        this.SpinnerService.show();
        if(appointmentId!=null)
        {
          this.service
          .getAppointmentByIdForReview(appointmentId)
          .pipe(take(1))
          .subscribe((data: any) => {
           console.log(data);
            this.appointment = data;
    
            let  dateFormat = this.pipe.transform(this.appointment.dropOffDate, 'yyyy-MM-dd');
            this.allreviewsForm.controls['firstName'].setValue(this.appointment.firstName);
            this.allreviewsForm.controls['lastName'].setValue(this.appointment.lastName);
            this.allreviewsForm.controls['email'].setValue(this.appointment.email);
            this.allreviewsForm.controls['phone'].setValue(this.appointment.phoneNo);
            this.allreviewsForm.controls['dateofService'].setValue(dateFormat);
            this.allreviewsForm.controls['ClientId'].setValue(this.appointment.clientId);
            this.allreviewsForm.controls['appointmentId'].setValue(this.appointment.appointmentId);
            this.SpinnerService.hide();
          });
        }
       else{
        
       }
      }
      public submitForm(): void {
       
        const data = {
          AppointmentId: this.allreviewsFormControl["appointmentId"].value,
          firstName: this.allreviewsFormControl["firstName"].value,
          lastName: this.allreviewsFormControl["lastName"].value,
          email: this.allreviewsFormControl["email"].value,
          phone: this.allreviewsFormControl["phone"].value,
          dateofService: this.allreviewsFormControl["dateofService"].value,
          review: this.allreviewsFormControl["review"].value,
          recommendation: this.allreviewsFormControl["recommendation"].value,
          comment:this.allreviewsFormControl["comment"].value,
          rating :parseInt(this.allreviewsFormControl["rating"].value),
          // status: false,
          ClientId:this.allreviewsFormControl["ClientId"].value,
          
          }
        console.log(data);
        if (data) {
          this.SpinnerService.show();
          this.user
            .Addreview(data)
            .pipe(take(1))
            .subscribe((res: any) => {
              this.SpinnerService.hide();
              if (res.statusCode=== 1){
                this.allreviewsForm.reset();
                swal.fire(
                  'Success',
                  'Thankyou for you valuable Feeback and Time..',
                'success'
             );
              
                console.log(res);
              }
              else{
                if (res.statusCode==2){
                  swal.fire(
                    'Cancelled!',
                    'Failed to Save.',
                    'error'
                  );
               
              }
              else{
                if (res.statusCode==3){
                  swal.fire(
                    'Cancelled!',
                    'Your Feedback is Already Taken.',
                    'error'
                  );
               
              }
            }
            }
            
            });
        }
      }

      
    }
