<div class="w3-row" style="background-color: #2C2C2C; padding-top: 2%; padding-bottom: 1%;">
    <div class="w3-col l4 m4" style="color: white;padding-left: 5%;">
      <img style=" width: 150px; height: 67.58px;" src="../../../assets/designs/mtaclogo_FileQA (1).png" alt="">
      <p style="font-size: 14px; padding-top: 10px;">Take actions that make an impact. Providing services
        for all to grow and prosper.</p>
    </div>
    <div class="w3-col l4 m4" style=" color: rgb(223, 223, 223);padding-left: 5%;">
      <p style="font-size: 16px;">Contact <span style="color: #E26246;">us</span> </p>
      <p style="font-size: 14px;"> <img style="padding-right: 1%;" src="../assets/mail.png" alt=""> connect@manteio.in</p>
      <p style="font-size: 14px;"><img style="padding-right: 1%;" src="../assets/call.png" alt=""> +91 9391385170</p>
      <p style="font-size: 14px;"> <img style="padding-right: 2%;" src="../assets/pin.png" alt="">The Platina, B-605,
        Gachibowli, Telangana 500032</p>
    </div>
    <div class="w3-col l4 m4" style=" color: white;padding-left: 5%;">
      <p style="font-size: 16px; margin-bottom: 0px; margin-left: 50px;">Powered <span style="color: #E26246;" >by</span></p>
      <img class="manteioLogo" src="../assets/Manteio logo.png" alt=""><br>
      <a href="https://www.facebook.com/Mtacpro/" target="_blank" style="padding-left: 14%;"><img src="../assets/facebook.png" alt=""></a>
      <a href="https://www.instagram.com/mtacpro/" target="_blank" style="padding-left: 2%;"><img src="../assets/instagram.png" alt=""></a>
      <a href="https://in.linkedin.com/company/manteioitsolutions" target="_blank" style="padding-left: 2%;"><img src="../assets/linkedin.png" alt=""></a>
      <a href="https://mobile.twitter.com/manteio_it"  target="_blank" style="padding-left: 2%;"><img src="../assets/twitter.png" alt=""></a>
      <a href="https://www.youtube.com/channel/UC7E1pqLn5KASrzgSLY4xAPQ" target="_blank" style="padding-left: 2%;"><img src="../assets/youtube.png" alt=""></a>
    </div>
  </div>
  <div style="background-color: #202020;height: 28px;">
    <p class="text-center" style="font-size: 9px; color: white; padding-top: 0.5%;"> © Copyright 2020. All rights reserved.</p>
  </div>