<app-navbar></app-navbar>
<div class="row mainrow_margin">
   <div class="col-md-2">
    <app-sidenav></app-sidenav>
   </div>
   <div class="col-md-10">
  <div class="container">
       <!-- Nav tabs -->
       <div class="row main_section">
        <h4> Reminder Details</h4>
         <br>
         </div>
    <ul class="nav nav-tabs" role="tablist" style="margin-top: 10px;">
      <li class="nav-item btnmtac">
        <a class="nav-link active" data-toggle="tab" href="#Service">Service</a>
      </li>
      <li class="nav-item btnmtac">
        <a class="nav-link" data-toggle="tab" href="#Reviews">Reviews</a>
      </li>

      </ul>

       <!-- Tab panes -->
    <div class="tab-content" style="margin-left:-13px;">
        <div id="Reviews" class="container tab-pane"><br>
            <h4>Review Reminders</h4>
            <div class="table-responsive">
              <table class="table table-striped table-bordered" id="example" *ngIf="showTable1" datatable [dtOptions]="dtOptions"  style="width:100%;">
                  <thead class="thead-dark">
                    <tr>
                      <th>Name</th>
                      <th>Phone<br/>Number</th>
                      <th style="width: 140px;">Email</th>
                      <th>Service Names</th>
                      <th>Servcie Date</th>
                      <th>Send or Stop <br>Reminders</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor= "let list of ReviewRemindersData">

                   <td>{{list.name}}</td>
                   <td>{{list.mobile}}</td>
                   <td>{{list.email}}</td>
                   <td>{{list.serviceNames}}</td>
                   <td>{{list.lastServicedOn | date:'MM-dd-yyyy'}}</td>
                   <td>
                    <i class="fa fa-toggle-on fa-2x" data-toggle="modal" data-target=".bd-example-modal-lg5" [ngClass]="list.status?'active':'fa-rotate-180 inactive'"
                        (click)="statusClick(list)"></i> </td>
                  </tr>
                  </tbody>
              </table>
            </div>
          </div>


           <div id="Service" class="container tab-pane active "><br>
            <h4>Service Reminders</h4>
            <div class="table-responsive">
              <table class="table table-striped table-bordered" id="example" *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="width:100%;">
                  <thead class="thead-dark">
                      <tr>
                      <th>Name</th>
                      <th>Phone<br/>Number</th>
                      <th style="width: 140px;">Email</th>
                      <th>Service Name</th>
                      <th>Last Service Date</th>
                      <th>Next Service Date</th>
                      <th>Send or Stop <br>Reminders</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor= "let list of ServiceRemindersData">

                   <td>{{list.name}}</td>
                   <td>{{list.mobile}}</td>
                   <td>{{list.email}}</td>
                   <td>{{list.serviceName}}</td>
                   <td>{{list.lastServicedOn | date:'MM-dd-yyyy'}}</td>
                   <td>{{list.dueDate | date:'MM-dd-yyyy'}}</td>
                   <td>
                    <i class="fa fa-toggle-on fa-2x" data-toggle="modal" data-target=".bd-example-modal-lg6" [ngClass]="list.status?'active':'fa-rotate-180 inactive'"
                        (click)="statusClick1(list)"></i>  </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
  </div>
</div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
  </ngx-spinner>


  <div class="modal fade bd-example-modal-lg5" id="demo-reviews" tabindex="-1" style="overflow:scroll;">
    <div class="modal-dialog modal-dialog-centered appointment_popup_main">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel-2">{{statusChangeAction}}  Review</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure want to {{statusChangeAction}} Review Reminders?</p>
            </div>
            <div class="modal-footer">
                 <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success"  data-dismiss="modal" (click)="changeStatus()">{{statusChangeAction}}</button>
            </div>
        </div>
    </div>
  </div>


  <div class="modal fade bd-example-modal-lg6" id="demo-reviews" tabindex="-1" style="overflow:scroll;">
    <div class="modal-dialog modal-dialog-centered appointment_popup_main">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel-2">{{statusChangeAction}}  Review</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure want to {{statusChangeAction}} Service Reminders?</p>
            </div>
            <div class="modal-footer">
                 <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success"  data-dismiss="modal" (click)="changeStatus1()">{{statusChangeAction}}</button>
            </div>
        </div>
    </div>
  </div>
