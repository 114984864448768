// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 //production: true,
  // apiUrl:'https://cors-anywhere.herokuapp.com/http://18.222.194.156:81/api/'
 // apiUrl:'http://18.222.194.156:81/api/'
//apiUrl:'https://localhost:44314/api/'
//apiUrl:'http://18.222.194.156:94/api/'  //Working
//apiUrl:'http://137.59.201.54:84/api/'  //plesk
 // apiUrl:'https://localhost:44354/api/'
 //apiUrl:'https://apim2.mtestsample.co/api/'  //Secured Api
 apiUrl:'https://api.mtacpro.com/api/'


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
