  <!-- <app-navbar></app-navbar>  -->
 <nav class="navbar navbar-expand-md navbar-dark main-nav-bar" >
   <div class="flex-row d-flex" style="margin-left: 4rem;">

       <button type="button" class="navbar-toggler mr-2 " data-toggle="offcanvas" title="Toggle responsive left sidebar">
           <span class="navbar-toggler-icon"></span>
       </button>
       <a href="www.mtacpro.com/#/dashboard"><img src="../../assets/images/logo-crop.png" alt="dashboardlogo"  class="logo-crop"/></a>
   </div>

   <div class="navbar-collapse collapse" id="collapsingNavbar">
     <b style="position: absolute;right: 10%;">
       <p style="right: 10%;
       font-size: 16px;
       position: absolute;">Welcome</p><br>
       <!-- <img src="../../../assets/images/hitech-03.png" alt="company_logo" class="company_logo"> -->
      <p>{{UserName}}</p>
     </b>
        
   </div>

</nav>
  <img src="../../../assets/images/blueanimationright.png" alt="company_logo"  class="blueanimationright" >
<script>
    $('.btn').click(function () {
        $('.modal')
            .prop('class', 'modal fade') // revert to default
            .addClass($(this).data('direction'));
        $('.modal').modal('show');
    });
</script>

<section style="margin-top: 100px;">
    <div class="container">
        <div class="row">
            <div class="first-text">
             <p>We value your input and encourage you to let us know more details about 
                 your experience with us.
                We strive to provide the highest quality service and care deeply about
                 how our work affects customers like you.</p>
             <br>
             <button id="button" class="btn btn-sm topappointmentbtn" style="width:230px; text-align: center;" data-toggle="modal" data-target="#exampleModaloneside" data-direction='top'>
             Write a Review
            </button>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="row" style="margin-top:4%;">
                    <div class="col-md-6">
                      <div class="hidiv" style="color:#413d3d;">
                        <h5 ><b>Hi-Tech Automotive</b></h5>
                        <p style="color:#e33d3d ;">Overall Ratings</p>
                        <div >
                            <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                            <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                            <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                            <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                            <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span> <span><b>Total ({{ReviewData.reviewTotalCount}}) Reviews</b></span>
                        </div>
                         <div>
                             <span><b>5 Star</b></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span style="color: #e33d3d;"><b>{{ReviewData.reviewFiveCount/ReviewData.reviewTotalCount*100 | number: '1.0-0'}}%</b></span>
                         </div>
                         <div>
                             <span><b>4 Star</b></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star" style="font-size:25px;padding:5px;"></span>
                             <span style="color: #e33d3d;"> <b>{{ReviewData.reviewFourCount/ReviewData.reviewTotalCount*100 | number: '1.0-0'}}%</b></span>
                         </div>
                         <div>
                             <span><b>3 Star</b></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star" style="font-size:25px;padding:5px;"></span>
                             <span style="color: #e33d3d;"><b>{{ReviewData.reviewThreeCount/ReviewData.reviewTotalCount*100 | number: '1.0-0'}}%</b></span>
                         </div>
                         <div>
                             <span><b>2 Star</b></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star" style="font-size:25px;padding:5px;"></span>
                             <span style="color: #e33d3d;"><b>{{ReviewData.revieTwoCount/ReviewData.reviewTotalCount*100 | number: '1.0-0'}}%</b></span>
                         </div>
                         <div>
                             <span><B>1 Star</B></span>
                             <span class="fa fa-star checked" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star" style="font-size:25px;padding:5px;"></span>
                             <span class="fa fa-star" style="font-size:25px;padding:5px;"></span>
                             <span style="color:  #e33d3d;"><b>{{ReviewData.reviewOneCount/ReviewData.reviewTotalCount*100 | number: '1.0-0'}}%</b></span>
                         </div>
                         <div >
                            <i class="fas fa-thumbs-up" style="color: #3695e6; font-size: 35px;margin-right: 10px;margin-top: 20%;"></i>{{ReviewData.recommedationCount/ReviewData.reviewTotalCount*100 | number: '1.0-0'}}% Would Recommend
                        </div>
                      </div>

                    
                    </div>
                    <div class="col-md-6">
                       <img src="../../../assets/images/lady-in-red.png" alt="bottomimage" class="lady-in-red"  style="
                       
                       width: 100%;" />
                    </div>
                </div>
          
            </div>
       
           <div class="col-md-6">
       
               <div class="row">
               
                        <img src="../../../assets/images/map.png" alt="map-image" class="map-image" />
               
                        <div class="row" style="margin-top: 5%; margin-left: 1%;width: 100%;">
                            <div class="col-md-6" style="margin-bottom: 5px;">
                                <span class="hitech_location">Address</span><br/>
                                <br>
                                608 N Polk st<br />
                                Pineville, NC 28134<br />
                                (704)889-5289<br />
                                Send a message<br />
                                <a href="https://hi-techautomotivecenter.com/" target="blank">hi-techautomotivecenter.com</a>
                            </div>
                            <div class="col-md-6"  style="margin-bottom: 5px;">
                                <span class="hitech_location">Business Hours</span><br />
                                <br>
                                Mon 7:30 AM - 6.00 PM<br />
                                Tue 7:30 AM - 6.00 PM<br />
                                Wed 7:30 AM - 6.00 PM<br />
                                Thu 7:30 AM - 6.00 PM<br />
                                Fri 7:30 AM - 6.00 PM<br />
                                Sat 7:30 AM - 3.00 PM<br />
                            </div>
                        </div>
                    </div>
               </div>
           </div>
    </div>
</section>
<section>
    <div class="container">
        <div >
            <pagination-controls (pageChange)="pageChanged($event)" style="margin-left: 50%;
            margin-top: 5%;"></pagination-controls>
            <div >
                <div class="row" *ngFor= "let list of ReviewData.allReviews | paginate: config" >
                    <div class="reviewcard">
                        <div id="example1">
                            <p style="color: #ec2548;">
                               {{list.firstName}} {{list.lastName}} <span>|</span>
       <span><ngb-rating [max]="5" [(rate)]="list.rating" [readonly]="true"></ngb-rating></span>
       <span><img height="30px" width="30px" src="{{getSocialMediaImg(list.sourceId)}}"/></span>
                                <span  class='fa fa-calendar' style="color: black; float: right;">
                                   {{list.reviewDate | date:'dd/MM/yyyy'}}
                                </span>
       
                            </p>
                            <p>{{list.review}}</p>
                            <p>{{list.comment}}</p>
                              <div *ngIf="list.recommendation == 'Yes'">
       
                                <p style="margin-left: 55%;"><i class="fas fa-thumbs-up " style="color:#3695e6;font-size: 15px;"></i> Recommended</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pagination-controls (pageChange)="pageChanged($event)" style="margin-left: 50%;
            margin-top: 5%;"></pagination-controls>
           </div>
    </div>
</section>
<!-- <section>
    <div class="container">
        <div class="p-div">
            With over 35 years of automotive knowledge Hi Tech Automotive Pineville has what you need.
             Our professional automotive service offers you years of combined expertise from our 
             well-trained technicians. We understand what makes customer service truly outstanding experience.
        </div>
    </div>
    </section> -->

<section  class="reviews_hitechview">
            <div class="container extraspace_pdiv">
                <div class="p-div">
                   <span><i class="fa fa-quote-left cotation_iconss" aria-hidden="true"></i></span> With over 35 years of automotive knowledge Hi Tech Automotive Pineville has what you need.
                     Our professional automotive service offers you years of combined expertise from our 
                     well-trained technicians. We understand what makes customer service truly outstanding experience.
                </div>
            </div>
</section>



<section>
    <div class="container">
    <div class="last-text" >Customer satisfaction is our top priority.
         We always appreciate the information and advice you share.
         Don't settle for anything less.</div>
</div>
</section>



<div class="modal fade" id="exampleModaloneside" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
 <div class="modal-dialog modal-lg" role="document">
     <div class="modal-content">
        <button type="button" class="btn btn-danger" data-dismiss="modal"  style="position: absolute;
        right: 5px;border-radius: 50%;top: 5px;width: 35px;height: 35px;">
        <i class="fa fa-times" style="color:#fff;font-size: 20px;
        margin-left: -3px;" aria-hidden="true"></i></button>
         <h6 class="modal-title col-md-9" id="exampleModalLabel" style="margin-top:20px;margin-left:20px;">Write a Review</h6>
         <div class="modal-body">
             <form class="contactform" style="margin:15px;"[formGroup]="allreviewsForm" (ngSubmit)="submitForm()">

                 <div class="row">
                     <div class="col-md-7">
                         <div class="form-row">
                             <div class="form-group col-md-6">
                                 <input  type="text" placeholder="Frist Name" class="form-control"  formControlName="firstName"  name="firstName">
                                 <span style="color:  #d70b0b;font-size: 14PX;"  *ngIf="!allreviewsForm.controls['firstName'].valid&&allreviewsForm.controls['firstName'].dirty">
                                    <span *ngIf="allreviewsForm.controls.firstName.errors.required">First Name is Required.</span>
                                    <span *ngIf="allreviewsForm.controls.firstName.errors.minlength">First name minimum should be length of 5.</span>
                                    <span *ngIf="allreviewsForm.controls.firstName.errors.maxlength">First name maximum should be length of 50.</span>
                                  </span>
                             </div>
                             <div class="form-group col-md-6">
                                 <input type="text" placeholder="Last Name" class="form-control"  formControlName="lastName"  name="lastName">
                                 <span style="color:  #d70b0b;font-size: 14PX;"  *ngIf="!allreviewsForm.controls['lastName'].valid&&allreviewsForm.controls['lastName'].dirty">
                                    <span *ngIf="allreviewsForm.controls.lastName.errors.required">Last Name is Required.</span>
                                    <span *ngIf="allreviewsForm.controls.lastName.errors.minlength">Last name minimum should be length of 5.</span>
                                    <span *ngIf="allreviewsForm.controls.lastName.errors.maxlength">Last name maximum should be length of 50.</span>
                                  </span>
                             </div>
                         </div>
                         <div class="form-row">
                             <div class="form-group col-md-6">
                                 <input type="text" placeholder="Email ID" class="form-control"  formControlName="email"  name="email">
                                 <span style="color:  #d70b0b;font-size: 14PX" *ngIf="!allreviewsForm.controls['email'].valid&&allreviewsForm.controls['email'].dirty">
                                    <span *ngIf="allreviewsForm.controls.email.errors.required">Email is Required.</span>
                                    <span *ngIf="allreviewsForm.controls.email.errors.email">Email is not in correct pattern.</span>
                                  </span>
                             </div>
                             <div class="form-group col-md-6">
                                 <input type="text" placeholder="Phone Number" class="form-control"  formControlName="phone"  name="phone" maxlength="10">
                                 <span style="color:  #d70b0b;font-size: 14PX" *ngIf="!allreviewsForm.controls['phone'].valid&&allreviewsForm.controls['phone'].dirty">
                                    <span *ngIf="allreviewsForm.controls.phone.errors.required">Phone Number is Required.</span>
                                    <span  style="color: #d70b0b; margin-top:15px ;" *ngIf="allreviewsForm.controls.phone.errors.pattern">Please, Enter 10 digit Mobile Number.</span>
                                
                                  </span>
                             </div>
                         </div>
                         <div class="form-row">
                            <div class="form-group col-md-6">

                                <input placeholder="Date of Service" class="form-control" type="date" formControlName="dateofService" name="dateofService" />
                                <span style="color:  #d70b0b;font-size: 14PX" *ngIf="!allreviewsForm.controls['phone'].valid&&allreviewsForm.controls['phone'].dirty">
                                   <span *ngIf="allreviewsForm.controls.phone.errors.required">Phone Number is Required.</span>
                               
                                 </span>
                            </div>
                             <div class="form-group col-md-6">
                                 <input type="text" placeholder="Review Title" class="form-control" formControlName="review" name="ReviewTitle">
                                 <span style="color:  #d70b0b;font-size: 14PX;"  *ngIf="!allreviewsForm.controls['review'].valid&&allreviewsForm.controls['review'].dirty">
                                    <span *ngIf="allreviewsForm.controls.review.errors.required">Review Title is Required.</span>
                                   
                                    
                                  </span>
                             </div>
                            
                         </div>
                         <h6 class="modal-title col-md-9" id="exampleModalLabel" style="margin-top:20px;">Your Review</h6><br />
                         <div class="form-row" style="margin-bottom: 20px; margin-left: 10px;">
                             <div class="form-group col-md-12">
                                 <textarea  style="height: 100px;" type="text" placeholder="Write Here..." class="form-control" formControlName="comment"  name="comment"></textarea>
                                 <span style="color:  #d70b0b;font-size: 14PX;"  *ngIf="!allreviewsForm.controls['comment'].valid&&allreviewsForm.controls['comment'].dirty">
                                    <span *ngIf="allreviewsForm.controls.comment.errors.required">Review is Required.</span>
                                
                                  </span>
                             </div>
           
                         </div>

                         <label>Would you like to Recommend this to a friend?</label>
                         <div class="form-row" style="margin-bottom: 20px; margin-left: 10px;">
                             <div class="form-group col-md-8">
                                 <input type="radio" class="form-check-input" formControlName="recommendation" name="recommendation" value="yes">
                                 <label class="form-check-label" for="materialInline1">Yes</label>
                                 <br />
                                 <input type="radio" class="form-check-input" formControlName="recommendation" name="recommendation" value="no">
                                 <label class="form-check-label" for="materialInline1">No</label>
                             </div>
                         </div>
                     </div>

                   
                     <div class="col-md-5">
                        <label>Please, rate our service</label>
                         <div class="rating">
                             <input type="radio"  formControlName=rating name=rating value=5 id="5"><label for="5">☆</label>
                              <input type="radio" formControlName=rating name=rating value=4 id="4"><label for="4">☆</label>
                               <input type="radio" formControlName=rating name=rating value=3 id="3"><label for="3">☆</label>
                                <input type="radio" formControlName=rating name=rating value=2 id="2"><label for="2">☆</label>
                                 <input type="radio" formControlName=rating name=rating value=1 id="1"><label for="1">☆</label>
                         </div>

                         <div>
                              <label>Follow Us On</label>
                              <div class="row">


                                <a href="https://twitter.com/HiTechAutoNC" class="sicons" target="_blank">
                                    <i style="color: #1DA1F2;" class="fa fa-twitter fa-2x" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.facebook.com/HiTechAutoNC" class="sicons" target="_blank">
                                    <i style="color: #4267B2;" class="fab fa-facebook-f fa-2x"></i>
                                </a>

                                <a href="https://www.yelp.com/biz/hi-tech-automotive-pineville" class="sicons" target="_blank">
                                    <i style="color: #c41200;" class="fa fa-yelp fa-2x" aria-hidden="true"></i>
                                </a>
                                <a href="https://local.yahoo.com/info-13216363-hitech-automtv-pineville-incorporated-pineville" class="sicons" target="_blank">
                                    <i style="color: #720e9e;" class="fa fa-yahoo fa-2x" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.google.com/search?q=Hi+Tech+Automotive+Pineville&rlz=1C1GCEU_enUS819US819&oq=Hi+Tech+Automotive+Pineville&aqs=chrome..69i57j69i60.31j0j4&sourceid=chrome&ie=UTF-8" class="sicons" target="_blank">
                                    <i style="color: #db4a39;" class="fa fa-google-plus-square fa-2x" aria-hidden="true"></i>
                                </a>
                                       
                              </div>
                         </div>
                     </div>
                     </div>
                     <input type="hidden"   formControlName= appointmentId>
                
                    
                         <div class="row"> 
<div> 
    <button type="button" class="btn" style="float: left;"  data-dismiss="modal" style="   background-color: #7c00fc;
    color: #fff;">Cancel</button>
    <button [disabled]="!allreviewsForm.valid"  style="float: right;"   type="submit" class="btn  btn-danger ml-5 ">Submit</button>
</div>

                         </div>
                         
                        
                   
                 

                    </form>


             <img src="../../../assets/images/review-popup.png" alt="bottomimage" class="bottomimage"  />

         </div>
     </div>
 </div>
</div>
<img src="../../../assets/images/illustraion-2.png" alt="bottomimage"  class="illustraion-2" />
<img src="../../../assets/images/illustration3.png" alt="bottomimage"  class="illustration3" />

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
    </ngx-spinner>