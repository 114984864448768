<style>
    .sw-theme-dots .sw-toolbar {
    background: #fff;
    border-radius: 0!important;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -40px!important;
    margin-bottom: 0!important;
    }
  </style>
  <app-navbar></app-navbar>
  <div class="row mainrow_margin">
     <div class="col-md-2">
      <app-sidenav></app-sidenav>
     </div>
     <div class="col-md-10">
  <div class="row" style="margin-top:3%;margin-left: 28px;margin-bottom: 1%;">
       <div class="col-md-6">
        <h4 style="margin-left: -30px;
        font-weight: 600;
        font-size: 22px;
        font-family: 'Poppins';"></h4>
       </div>
       <div class="col-md-6">
        <button type="button" class="btn btn-secondary" style="width: 150px;background-color: #211e50;border:#211e50;margin-left: -10px;" data-toggle="modal" data-target="#demo-5">Car-Inspection</button>
      </div>
    </div>
  
    <div class="container">
      <div class="table-responsive">
        <table class="table table-striped table-bordered" id="example" *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="width:100%;">
          <thead class="thead-dark">
              <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Year</th>
              <th>Make</th>
              <th>VehicleID</th>
              <th>NC_LIC_Number</th>
              <th>valid Date</th>
              <th>Address</th>
              <th>Total Fee</th>
            
          </tr>
          </thead>
          <tbody>
          <tr *ngFor= "let list of carInspectionList">
            <td>{{list.id}}</td> 
           <td>{{list.name}}</td> 
           <td>{{list.year}}</td>
           <td>{{list.make}}</td>   
           <td>{{list.vehicleID}}</td>
           <td>{{list.nC_LIC_Number}}</td> 
           <td> {{list.validToDate | date:'MM-dd-yyyy'}}</td>  
           <td>{{list.address}}</td>  
           <td>{{list.totalFee}}</td>
          </tr>
          </tbody>
      </table>
  
      </div>
    </div>
   <div>

    <!-- <div class="modal fade" id="demo-5" tabindex="-1">
      <div class="modal-dialog modal-xl appointment_popup_main" style="margin-left:90px;">
        <div class="modal-content" style="padding-left:15px;padding-right:15px;">
          <h4 class="modal-title col-md-9" id="exampleModalLabel" style="margin-bottom:15px;
          margin-top:15px;
          margin-left: 15px;">Car-Inspection</h4>
          <button type="button" class="btn btn-danger" data-dismiss="modal"  style="position: absolute;
          right: 4px;border-radius: 50%;
          top: 4px;">&times;</button>
          <button type="button" class="close" data-dismiss="modal"><i class="icon-xs-o-md"></i></button>
         
          <div class="modal-body">
            <form   [formGroup]="Inspection" (ngSubmit)="InspectionAdd()">
              
              
              <div class="row" style="margin-top: -15px">
                <div class="col-md-3 form-group">
                  <label>Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control " id="txtfName"  formControlName="Name" placeholder="Name" name="txtfName" aria-controls="example" required>
                   
                     
                </div>

                <div class="col-md-3 form-group">
                
                  <label>Year <span class="text-danger">*</span></label>
                  <select  class="form-control" id="txtYear"  formControlName="Year" name="txtYear">
                      <option value="" disabled selected hidden> Year </option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>

                  </select>
                </div>
                <div class="col-md-3 form-group">

                  <label>Make <span class="text-danger">*</span></label>
                
                  <select class="form-control wizard-required" id="ddlMake" name="ddlMake"  formControlName="VehicleBrand" (change)="getModels($event.target.value)">
                    <option value="" disabled selected hidden> Make </option>
                        
                    <option *ngFor= "let makes of makesdata" [value]="makes.makeId">{{makes.makeName}}</option>
                  </select>
                </div>
                <div class="col-md-3 form-group">
                  <label>Vehicle Id <span class="text-danger">*</span></label>
                    <input type="email" class="form-control " id="txtEmail"  formControlName="VehicleID" placeholder="Vehicle ID" name="VehicleID">
                   
                </div>
            </div>

           
            <div class="row" >
              <div class="col-md-3 form-group">
                <label>NC_LIC_Number <span class="text-danger">*</span></label>
                  <input type="text" class="form-control " id="txtfName"  formControlName="NC_LIC_Number" placeholder="NC_LIC_Number" name="txtfName" aria-controls="example" required>
                 
                   
              </div>

              <div class="col-md-3 form-group">
              
                <label>Valid Date <span class="text-danger">*</span></label>
                <input type="text" placeholder="MM/DD/YYYY"
                onfocus="(this.type='date' )" id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="ValidToDate" id="dropDate" name="dropDate">
               
               </div>
            
              <div class="col-md-3 form-group">

                <label>Country <span class="text-danger">*</span></label>
                <select  class="form-control" id="txtYear"  formControlName="Country" name="txtYear">
                  <option value="" disabled selected hidden> Country </option>
                  <option value="1">U.S.A</option>
                
                

              </select>
                
                 
              </div>
              <div class="col-md-3 form-group">
                <label>Address<span class="text-danger">*</span></label>
                  <input type="text" class="form-control " id="txtEmail"  formControlName="Address" placeholder="Address" name="VehicleID">
                 
              </div>
          </div>

          <div class="row" >
            <div class="col-md-3 form-group">
              <label>Fuel Type <span class="text-danger">*</span></label>
              <select  class="form-control" id="txtYear"  formControlName="Fuel" name="txtYear">
                <option value="" disabled selected hidden> select </option>
                <option value="Disiel">Disiel</option>
                <option value="Petrol">Petrol</option>
              </select>
            </div>

            <div class="col-md-3 form-group">
            
              <label>Gross Weight<span class="text-danger">*</span></label>
              <input type="text" class="form-control " id="txtfName"  formControlName="GrossWeight" placeholder="Gross Weight" name="txtfName" aria-controls="example" required>
                   
             </div>
          
            <div class="col-md-3 form-group">

              <label>Shipping Weight <span class="text-danger">*</span></label>
              <input type="text" class="form-control " id="txtfName"  formControlName="ShippingWeight" placeholder="ShippingWeight" name="txtfName" aria-controls="example" required>
                   
              
               
            </div>
            <div class="col-md-3 form-group">
              <label>Total Fee<span class="text-danger">*</span></label>
              <input type="text" class="form-control " id="txtfName"  formControlName="TotalFee" placeholder="Total Fee" name="txtfName" aria-controls="example" required>
                   
            </div>
        </div>

        



             
          <div class="form-group clearfix">
          
            <button type="submit"  style="float: right;"  class="btn  btn-danger text-white text-center" >Submit</button>
       
         
          </div>         

                            
            
          
          </form>
          </div>
      </div>
      </div>
    </div> -->