import { Injectable } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { getRecoverypassword } from '../recoverypassword/recoverypasswords';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ForgotpasswordService {

  constructor(private http:HttpClient) { }

  public recoverypasswordData(data:getRecoverypassword):Observable<any>{
    let url=`${environment.apiUrl}register/ForgetPassword?Email=` + data.email;
    console.log('form submitted recoverpassword page');
    return this.http.post<any>(url,data);

  }
  public Setpassword(data){
    let url=`${environment.apiUrl}register/SetPassword`;
    return this.http.post<any>(url,data);
  }
}
