<div style="margin-left: 0; margin-right: 0; max-width: 100%;">
<app-newnav></app-newnav>
<!--Social Icons -->
<div class="text-center socialIcons w3-hide-small w3-hide-medium">
    <a href="https://www.facebook.com/Mtacpro/" target="_blank"><img class="sideBarSocialIcons" style="margin-top: 25px;"
            src="../assets/socials icons/facebook - white.png" alt=""></a><br>
    <a href="https://www.instagram.com/mtacpro/" target="_blank"><img class="sideBarSocialIcons" src="../assets/socials icons/instagram - white.png"
            alt=""></a><br>
    <a href="https://mobile.twitter.com/manteio_it"  target="_blank"><img class="sideBarSocialIcons" src="../assets/socials icons/twitter - white.png" alt=""></a><br>
    <a href="https://in.linkedin.com/company/manteioitsolutions" target="_blank"><img class="sideBarSocialIcons" src="../assets/socials icons/linkedin - white.png" alt=""></a>
</div>
<!--------->
<div>
    <div class="coverPageImg">
        <div class="paddingMobile coverPageTitleDiv text-center " style=""><!--ABCText-->
            <p class="coverPageTitle" style="text-align: right; font-family: 'KonsensHeavy', sans-serif; height: 140px;">Manage your <span style="color: #E26246;">Office</span>
                from your <span style="color: #E26246;">Pocket!</span></p>
        </div>
        <div class="coverPageParaDiv " style="padding-left: 63.5%;">
            <p class="coverPagePara  ">Simple, Easy and Hassle free for a Streamlined, Productive Workflow. Get ahead of the Competition.</p>
        </div>
        <!--<div class="coverPageButtonDiv">
            <button class="btn btn coverPageButton">Know more</button>
        </div>-->
    </div>
    <!--Designs-->
    <div class="design3 ABC">
        <img src="../../assets/designs/Design 3.png" alt="">
    </div>
    <div class="design11">
        <img src="../../assets/designs/Design 11.png" alt="">
    </div>
    <div class="design15">
        <img src="../../assets/designs/DEsign 15.png" alt="">
    </div>
    <div class="design16">
        <img src="../../assets/designs/Design 16.png" alt="">
    </div>
    <div class="design14">
        <img src="../../assets/designs/Design 14.png" alt="">
    </div>
   
    <!---->

    <!--Row 1-->
    <div class="container">
        <div class="row row1Div">
            <div class="col-md-6 col-sm-12">
                <p class="row1Col1Para1" 
                style="font-family: 'konsenssemiBold', sans-serif; margin-top: 50px;">Explore More!</p><br>
                <p class="row1Col1Para2" style="margin-bottom: 0px;">Go beyond measurement and take the steps that will generate real business value.
                    Designed to be scalable and sustainable,
                    We help you drive the action.</p><br>
                <p class="row1Col1Para3">Now manage all of your bookings through one platform . Seamless automation enables your business to run like clockwork. Enable your customers to book from anywhere. With this interactive platform you can now worry a little less and perform a lot more.</p>
            </div>
            <div class="col-md-6 col-sm-12 row1Col2Div" ><!--style="padding-top: 65px;"-->
                <div class="" style=""><!--infinityChrome-->
                    <img src="../../assets/home/Appointments - box-copy.png" alt=""  class="row1Col2Img1 box">
    
                    <img src="../../assets/home/Reviews - box-copy.png" alt="" class="row1Col2Img2 box">
                    <img src="../../assets/home/Reminders - box-copy.png" alt="" class="row1Col2Img3 box">
                    <img src="../../assets/home/Reports - box-copy.png" alt=" " class="row1Col2Img4 box">
                </div>
               
            </div>
        </div>
    </div>
    
    <!---->
    <!--Row 2-->
    <div class="row2Div1"
        style="background-image: url(../../assets/home/Appointments\ -\ BG.png); background-position: center; background-repeat: no-repeat; background-size: 100% 200%;">
        <div class="container">
            <div class="row row2Div">
                <div class="col-md-6 col-sm-12 l6">
                    <marquee behavior="slide" direction="left" scrollamount="15">
                        <img src="../../assets/home/Appointments - image.png" alt="" class=" row2Col1Img Imagewidth">
                    </marquee>
    
                </div>
                <div class="col-md-6 col-sm-12 l6">
                    <p class="row2Col2Para1">Appointments</p>
                    <hr style="margin-left: 45%;" width="7.5%">
                    <hr style="margin-left: 49%;" width="7.5%">
                    <p class="row2Col2Para2">MTacPro simplifies your notifications for every new or tallied appointment.  A Smooth and glitch free experience is assured to glide your appointments effortlessly.</p>
                    <ul>
                        <li class="row2Col2Li">With online booking system, you can easily and seamlessly take customer bookings and save time.</li>
                        <li class="row2Col2Li">Make scheduling easy for you, and your customers. Customers can book. You can accept or decline from anywhere.</li>
                        <li class="row2Col2Li">Automate your reminders and follow ups that saves time, so you can do more.</li>
                    </ul>
                </div>
    
    
            </div>
        </div>
    </div>
    <!-- Row 3-->
    <div class="row3Div1"
        style="background-image: url(../../assets/home/Reviews\ -\ BG.png);
         background-position: center; background-repeat: no-repeat;background-size: 200% 200%;"><!-- background-size: 100% 200%;-->
       <div class="container">
        <div class="row row3Div2">
            <div class="col-md-6 col-sm-12">
                <p class="row3Col1Para1">Reviews</p>
                <hr class="hrLeft">
                <hr class="hrRight">
                <p class="row3Col1Para2">Get meaningful insights from your reviews! Give your potential clients that boost of confidence to choose you.</p>
                <ul>
                    <li class="row3Col1Li">Listen to every customer. Capture real-time feedback in the moments that matter. with MTacPro, you'll get a complete view of what customers are thinking and feeling.</li>
                    <li class="row3Col1Li">It is one-stop, Solution to gain valuable strategic feedback. Get the best feedback in any situation, start with the easiest to use, fastest to implement, most flexible solution.</li>
                </ul>
            </div>
            <div class="col-md-6 col-sm-12">
                <marquee behavior="slide" direction="" scrollamount="10">
                    <img src="../../assets/home/Reviews - image.png" alt="" style="width: 100%;" class=" row3Col2Img Imagewidth">
                </marquee>

            </div>
        </div>
       </div>
        
    </div>
    <!--Row 4 -->
    <div class="row4Div1"
        style="background-image: url(../../assets/home/Reminers\ -\ BG.png);  background-position: center; background-repeat: no-repeat; background-size: 200% 200%;">
        <div class="container">
            <div class="row row4Div2" style="padding-bottom: 60px;">
                <div class="col-md-6 col-sm-12 l6">
                    <marquee behavior="slide" direction="" scrollamount="5">
                        <img class=" row4Col1Img Imagewidth" style="padding-top: 60px; width: 100%;" src="../../../assets/home/Reminders - image copy.png" alt="">
                    </marquee>
    
                </div>
                <div class="col-md-6 col-sm-12 l6">
                    <p class="row4Col2Para1">Reminders</p>
                    <hr class="hrLeft">
                    <hr class="hrRight">
                    <p class="row4Col2Para2">Send confirmation and reminder emails and texts to improve no-show rates. Reminders to staff and clients whenever appointments are booked, Cancelled, or rescheduled.</p>
                    <ul>
                        <li class="row4Col2Li">With push notifications on your mobile for new booking information. Never forget anything, ever again.</li>
                        <li class="row4Col2Li">Never lose track of scheduled maintenance or upcoming service dates again – our reminder systems mean that internal or service tasks never need to be forgotten.</li>
                        <li class="row4Col2Li">Build trust and keep your customers informed with MTacPro’s automated reminder mechanism.</li>
                    </ul>
                </div>
            </div>
        </div>
        
    </div>
    <!-- Row 5-->
    <div class="row5Div1"
        style="background-image: url(../../assets/home/Look\ for\ -\ BG.png);  background-position: center; background-repeat: no-repeat; background-size: 100% 200%;">
       <div class="container">
        <div class="row row5Div2">
            <div class="col-md-6 col-sm-12">
                <p class="row5Col1Para1">Reports</p>
                <hr class="hrLeft">
                <hr class="hrRight">
                <p class="row5Col1Para2">Transparent analytics to see trends and check efficiency. Dig deeper into your data with filters and reports. Measure responses against industry benchmarks.</p>
                <ul>
                    <li class="row5Col1Li">Automatic detection surfacing, and prioritization of issues allows you to focus on other business objectives while Gaining understanding around the insights gathered from customers.</li>
                    <li class="row5Col1Li">Analyse all your reports and data briefly with interactive and comprehensive platform to boost productivity and create better strategies.</li>
                    <li class="row5Col1Li">Identify and facilitate goals with all your reports. Track the growth at your fingertips.</li>
                </ul>
            </div>
            <div class="col-md-6 col-sm-12">
                <marquee behavior="slide" direction="" scrollamount="5">
                    <img src="../../assets/home/Look for - image.png" alt="" class=" row5Col2Img Imagewidth">
                </marquee>

            </div>
        </div>
       </div>
        
    </div>
    <!---->
    <!--Row 6-->
    <div class="row6Div1"
        style="background-image: url(../../assets/home/Start\ planning\ today\ -\ BG.png); width: 100%; background-position: center; background-repeat: no-repeat; background-size: 200% 200%; ">
        <div class="container">
            <div class="row row6Div2">
                <div class="col-md-6 col-sm-12">
                    <marquee behavior="slide" direction="" scrollamount="20">
                        <img class="row6Col1Img Imagewidth" style="padding-top: 15px;" src="../../assets/home/Start planning today - image.png" alt="">
                    </marquee>
    
                </div>
                <div class="col-md-6 col-sm-12 l6">
                    <div class="row row6Col2RowDiv">
                        <div class="col-md-3">
                            <p class="row6Col2Para1">Start planning</p>
                        </div>
                        <div class="col-md-6">
                            <p class="row6Col2Para2">TODAY</p>
                        </div>
                    </div>
                    <p class="row6Col2Para3">Start planning today - Do more and grow. Get ahead of the competition</p>
                    <div class="row6Col2ButtonDiv">
                        <button [routerLink]="['../contactus']" class="btn btn row6Col2Buttton">Join us</button>
                    </div>
    
    
                </div>
            </div>
        </div>
        
    </div>
    <!---->

</div>
<app-footer></app-footer>
</div>