import { Component, OnInit } from '@angular/core';
import { GetappointmentsService } from '../../../getappointments.service';
import { max, min, take } from 'rxjs/operators';
import { DashboardData } from '../dashboardhome/dashboard.model';
import {Chart} from 'chart.js';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import {ReviewsService} from '../../../reviews.service';
import { RemindersService } from 'src/app/services/reminders.service';
import{ ReviewsData} from '../reviews/ReviewData';
import { NgxSpinnerService } from "ngx-spinner";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  public showTable: boolean = false;
  public showTableReviews :boolean=false;
  public reset:string;
  public go :string;
  public SourceId:string;
   ReviewsBySource :any=[];
  public dtOptions: any = {};
 public appointment :any=[]
  rating=0;
  getReviewRemindersData: [];
  getServiceRemindersData:[];
  public ReviewData: ReviewsData = {
    reviewTotalCount: 0,
    reviewFiveCount: 0,
    reviewFourCount: 0,
    reviewThreeCount: 0,
    revieTwoCount: 0,
    reviewOneCount: 0,
    recommedationCount: 0,
    ratingAverage:0,
    allReviews:[],
    fbReviews:[],
    googleReviews:[],
    mTacReviews:[],
    otherReviews:[],
    twitterReviews:[],
    yahooReviews:[],
    yelpReviews:[]
  };
  public visitSaleChartOptions1: ChartOptions;
  public visitSaleChartOptions2: ChartOptions;
  public showTableAppointment:boolean=false;
  [x: string]: any;
  public res :any=[];
  private monthNames: Array<string> = [];
  private totalAppiontmentcount = [];
  // private monthwiseReviewRemaindercount = [];
  private totalReviewCount:Array<number> = [];
  private monthwiseReviewRemaindercount:Array<number> = [];
  private monthwiseServiceRemaindercount:Array<number> = [];
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{ }], yAxes: [{ ticks: {
      min: 0,
      max: 300,
    }}] },
  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: ChartDataSets[] = [
    { data: [10, 20, 30, 50, 700, 80, 100], label: 'Company A' },
  ];
  public dashboardData: DashboardData = {
    totalcustomersCount: 0,
    totalReviewCount: 0,
    serviceReminderCount: 0,
    reviewReminderCount: 0,
    monthWiseReviewsAndAppointment:[],
  };
  data : any=[]
  barchart: any;
  month: any;
  constructor(private user: GetappointmentsService,private Reviews:ReviewsService,
    private SpinnerService: NgxSpinnerService,private reminder:RemindersService) {
  }
  public ngOnInit(): void {
    this.go="show";
    this.reset="hide";
  //  this.clientId  = localStorage.getItem("ClientId");
    this.user.getClientId();
    window.onload=function(){
    
      document.getElementById("3months").classList.add("gf-button");
    
      
    };
    this.SpinnerService.show();
    this.user
      .Reportsdata()
      .pipe(take(1))
      .subscribe((data: DashboardData) => {
        console.log(data),"Graphs Datyas";
        this.dashboardData = data;
        this.SpinnerService.hide();
        this.populateChart1(this.dashboardData);
        this.populateChart2(this.dashboardData);
        this.populateChart3(this.dashboardData);
        this.populateChart4(this.dashboardData);
        this.GetAppoitmentsByStatus();
        this.GetReviewsBySourceId(this.SourceId);
        this.getReviews();
        this.GetReviewReminderData();
        this.GetServiceReminderData();
      });
  }
  public GetAppoitmentsByStatus():void
  {
    this.showTableAppointment=false;
    if(this.appointmentStatus===undefined|| this.appointmentStatus==="")
    {
      this.appointmentStatus="0";
    }
      this.user.getAppointmentsByStaus(this.appointmentStatus).subscribe(res =>{
        console.log(res ,"Appointments By status");
        this.showTableAppointment = true; 
        this.data=res;
      })
     
  }
  public ReviewStatus(sourceId):void
  {
    this.SourceId=sourceId;
    this.GetReviewsBySourceId(this.SourceId);
  }
  public GetReviewsBySourceId(sourceId):void
  {
    this.showTableReviews=false;
   
    if(this.SourceId===undefined|| this.SourceId==="")
    {
      this.SourceId="0";
    }
   // this.data=[];
      this.user.getReviewsBySourceId(this.SourceId).subscribe(res =>{
        console.log(res ,"reviews By sourceId");
        this.showTableReviews = true; 
        this.ReviewsBySource=res;
        this.data.reverse();  
      })
     
  }
   // view appoinments
   GetAppointmentviewById( appointmentId :number)
   {
   
     this.SpinnerService.show();
     this.user
     .getAppointmentById(appointmentId)
     .pipe(take(1))
     .subscribe((data: any) => {
       // this.selectedItemsForService=this.appointment.appointmentServices.appointmentId;
//console.log(this.selectedItemsForService);

       this.appointment = data;
     console.log(this.appointment)
     });
     this.SpinnerService.hide();
   }

  public AppointmentStatus(Status):void
  {
    this.appointmentStatus=Status;
    this.GetAppoitmentsByStatus();
  }
  private getReviews(): void{
    this.showTableReviews=false;
    this.Reviews.getReviews().pipe(take(1)).subscribe((data:any) =>{
      this.showTableReviews = true;
      this.ReviewData=data; 
    })
  }
  private GetReviewReminderData():void {
    this.showTable=false;
    this.reminder.GetReviewReminderData().pipe(take(1)).subscribe((data:any) =>{
      this.showTable = true;
      this.getReviewRemindersData=data;
    })
}
private GetServiceReminderData(): void{
  this.showTable=false;
  this.reminder.GetServicesReminderData().pipe(take(1)).subscribe((data:any) =>{
    this.showTable = true;
    this.ServiceRemindersData=data;
    this.SpinnerService.hide();
   
  })
}
getStartDate(startDate :string,)
  {
    this.startDate=startDate;

  }

  getEndDate(endDate :string,)
  {
    this.endDate=endDate;
   
  }

  getFilterdData( ):void
  {
    this.showTableAppointment=false;
   
      this.appointmentStatus="0";
  
    this.data=[];
      this.user.getAppointmentsByFilter(this.appointmentStatus,this.startDate,this.endDate).subscribe(res =>{
        console.log(res ,"Appointments By status");
        this.data=[];
        this.data=res;
        console.log("this is resuilt",this.data);
        this.showTableAppointment = true; 
        // this.go="hide";
        // this.reset="show";
        // this.data.reverse();
      
      })


    // this.showTableAppointment=false;
    // this.SpinnerService.show();
    // // this.user
    // // .getFilterdDataAppointments(this.startDate,this.endDate)
    // // .pipe(take(1))
    // // .subscribe((data: any) => {
    // //   this.data=<string[]>data;
    // //   console.log(data);
    // // }); 
   
    // this.res = this.data.filter(m => m.dropOffDate > this.startDate && m.dropOffDate < this.endDate);
    // this.data=[];
    // this.showTableAppointment = true; 
    // this.data=this.res;
    // this.SpinnerService.hide();
  }
   //get back to appointmnets from filters 
   AppoitnmentsfromFilter()
   {
    this.go="show";
    this.reset="hide";
this.startDate='';
this.endDate='';
       this.GetAppoitmentsByStatus();
   }
   public VechileStatus(id: string): string{
    switch(id) {
      case "1": {
        return 'Confirmed'
        break;

      }
      case "2": {

       return 'Technician Assigned'
        break;

      }
      case "3": {

  return 'IN-Progress'
        break;

      }
      case "4": {

      return 'Work Done-Technician'
        break;

      }
      case "5": {

        return 'Confirmed by Supervisor'
          break;
  
        }
        case "6": {

          return 'Pickup Scheduled'
            break;
    
          }
          case "7": {

            return 'Cancelled'
              break;
      
            }
            case "8": {

              return 'Re-scheduled'
                break;
        
              }
              case "9": {

                return 'Accepted'
                  break;
          
                }
    }

      }
 
  // appointment chat

getchartnumber1(id: number){
  this.graphRequest1 = id;
  this.populateChart1(this.dashboardData);
}
private populateChart1(chartData1: any) {
 
  const salesData = [];
  this.visitSaleChartLabels1 = [];
if(this.graphRequest1==null)
{
this.graphRequest1=3
}

if(this.graphRequest1==3)
{
chartData1.threeMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  salesData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.monthName+' '+_.year);

});
}
else if(this.graphRequest1==6)
{
chartData1.sixMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  salesData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.monthName+' '+_.year);

});
}
else if(this.graphRequest1==11)
{
chartData1.yeartoDate.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  salesData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.monthName+' '+_.year);

});
}
else if(this.graphRequest1==12)
{
chartData1.oneYear.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  salesData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.monthName+' '+_.year);

});
}
else if(this.graphRequest1==36)
{
chartData1.threeYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  salesData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.year);

});
}

else if(this.graphRequest1==40)
{
chartData1.maxYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  salesData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.year);

});
}



  this.visitSaleChartData1 = [
    {
      label: "Appiontments",
      data: salesData,
      maxBarThickness:6,
      categoryPercentage: 1
    },
  ];
  this.visitSaleChartOptions1 = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display:true,
            drawBorder: true,
            color: "#c4cade",
            zeroLineColor: "#606c91"
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14,
            beginAtZero : true,
            min:0,
            stepSize:0.2,
            lineHeight: 1,
            maxTicksLimit:11,
            suggestedMax:100,
            suggestedMin:10,
            autoSkip:false,
            
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: true,
            color: "#606c91",
            zeroLineColor: "rgba(235,237,242,1)",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14,
            

          },
        },
      ],
    },
  };

  this.visitSaleChartColors1 = [
    { backgroundColor:[ "#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "#f990a7",
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Red",
    "Blue"],borderColor:"#CFD9DF" },
    { backgroundColor: [ "#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "#f990a7",
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Red",
    "Blue"],borderColor: "rgba(54, 162, 235, 1)" },
  ];

}

// reminder chat



getchartnumber2(id: number){
  this.graphRequest2 = id;
  this.populateChart2(this.dashboardData);
}
private populateChart2(chartData1: any) {
 
  const serviceRemindersData = [];
 
  this.visitSaleChartLabels2 = [];
if(this.graphRequest2==null)
{
this.graphRequest2=3
}

if(this.graphRequest2==3)
{
chartData1.threeMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest2==6)
{
chartData1.sixMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest2==11)
{
chartData1.yeartoDate.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest2==12)
{
chartData1.oneYear.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest2==36)
{
chartData1.threeYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}

else if(this.graphRequest2==40)
{
chartData1.maxYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}



  this.visitSaleChartData2 = [
    {
      label: "Service Remainers",
      data: serviceRemindersData,
      maxBarThickness:6,
      categoryPercentage: 0.2
    },
  ];
  this.visitSaleChartOptions2 = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            color: "#c4cade",
            zeroLineColor: "#606c91",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14,
            beginAtZero : true,
            min:0,
            stepSize:0.2,
            lineHeight: 1,
            maxTicksLimit:11,
            suggestedMax:100,

          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: true,
            color: "#606c91",
            zeroLineColor: "rgba(235,237,242,1)",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14


          },
        },
      ],
    },
  };

  this.visitSaleChartColors2 = [
    { backgroundColor:[ 
      "#4C4CFF",
      "#f990a7",
    "#aad2ed",
    "#FF00FF",
      "#3cb371",
    "#0000FF",
    "#9966FF",
   
    "#00FFFF",
    "Blue",
    "Red",
    "Blue"],borderColor:"#CFD9DF" },
    { backgroundColor: [  "#f990a7",
    "#aad2ed",
    "#FF00FF",
      "#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "Blue",
    "Red",
    "Blue"],borderColor: "rgba(54, 162, 235, 1)" },
  ];

}


getchartnumber3(id: number){
  this.graphRequest3 = id;
  this.populateChart3(this.dashboardData);
}
private populateChart3(chartData1: any) {
  const reviewsData = [];

  this.visitSaleChartLabels3 = [];
if(this.graphRequest3==null)
{
this.graphRequest3=3
}

if(this.graphRequest3==3)
{
chartData1.threeMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.reviewsCounts.totalCount);
  this.visitSaleChartLabels3.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest3==6)
{
chartData1.sixMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.reviewsCounts.totalCount);
  this.visitSaleChartLabels3.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest3==11)
{
chartData1.yeartoDate.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.reviewsCounts.totalCount);
  this.visitSaleChartLabels3.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest3==12)
{
chartData1.oneYear.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.reviewsCounts.totalCount);
  this.visitSaleChartLabels3.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest3==36)
{
chartData1.threeYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.totalReviewCount.totalCount);
  this.visitSaleChartLabels3.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}

else if(this.graphRequest3==40)
{
chartData1.maxYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.totalReviewCount.totalCount);
  this.visitSaleChartLabels3.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}



  this.visitSaleChartData3 = [
    {
      label: "Reviews",
      data: reviewsData,
      maxBarThickness:6,
      categoryPercentage: 0.2
    },
  ];
  this.visitSaleChartOptions3 = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            color: "#c4cade",
            zeroLineColor: "#606c91",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14,
            beginAtZero : true,
            min:0,
            stepSize:0.2,
            lineHeight: 1,
            maxTicksLimit:11,
            suggestedMax:100,

          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: true,
            color: "#606c91",
            zeroLineColor: "rgba(235,237,242,1)",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14


          },
        },
      ],
    },
  };

  this.visitSaleChartColors3 = [
    { backgroundColor:[ "#f990a7", "Red","#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Blue"],borderColor:"#CFD9DF" },
    { backgroundColor: [  "Red","#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "#f990a7",
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Blue"],borderColor: "rgba(54, 162, 235, 1)" },
  ];

}

getchartnumber4(id: number){
  this.graphRequest4 = id;
  this.populateChart4(this.dashboardData);
}
private populateChart4(chartData1: any) {
  const reviewsRemindersData = [];
  
  this.visitSaleChartLabels4 = [];
if(this.graphRequest4==null)
{
this.graphRequest4=3
}

if(this.graphRequest4==3)
{
chartData1.threeMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.monthName+' '+_.year);

});
}
else if(this.graphRequest4==6)
{
chartData1.sixMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.monthName+' '+_.year);

});
}
else if(this.graphRequest4==11)
{
chartData1.yeartoDate.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.monthName+' '+_.year);

});
}
else if(this.graphRequest4==12)
{
chartData1.oneYear.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.monthName+' '+_.year);

});
}
else if(this.graphRequest4==36)
{
chartData1.threeYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.year);

});
}

else if(this.graphRequest4==40)
{
chartData1.maxYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.year);

});
}



  this.visitSaleChartData4 = [
    {
      label: "Reviews Reminders",
      data: reviewsRemindersData,
      maxBarThickness:6,
      categoryPercentage: 0.2
    },
  ];
  this.visitSaleChartOptions4 = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            color: "#c4cade", 
            zeroLineColor: "#606c91",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14,
            beginAtZero : true,
            min:0,
            stepSize:0.2,
            lineHeight: 1,
            maxTicksLimit:11,
            suggestedMax:100,

          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: true,
            color: "#606c91",
            zeroLineColor: "rgba(235,237,242,1)",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14


          },
        },
      ],
    },
  };

  this.visitSaleChartColors4 = [
    { backgroundColor:[ " #22ffff", "#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "#f990a7",
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Red",
    "Blue"],borderColor:"#CFD9DF" },
    { backgroundColor: [ "#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "#f990a7",
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Red",
    "Blue"],borderColor: "rgba(54, 162, 235, 1)" },
  ];

}
}
