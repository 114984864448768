import { Component, OnInit } from '@angular/core';
import { RemindersService } from 'src/app/services/reminders.service';
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";

import swal from 'sweetalert2';
@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.css']
})
export class RemindersComponent implements OnInit {
  data=[]
  private clickedReview: any = {};
  private clickedService: any = {};
  public statusChangeAction: string;
  ReviewRemindersData: [];
  ServiceRemindersData:[];
  public showTable: boolean = false;
  public showTable1: boolean = false;
  public dtOptions: any = {};
  ClientId: any;

  constructor(private reminder:RemindersService,private SpinnerService: NgxSpinnerService) { }


  ngOnInit(): void {
    this.SpinnerService.show();
   // this.reminder.getClientId();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [ 5, 10, 25, 50, 100],
      processing: true,
      dom:
      "<'row'<'col-sm-6'i><'col-sm-6'f>>" +
    "<'row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-4'l><'col-sm-8'p>>" +
    "<'row'<'col-sm-6'B>>",
    buttons: [
      'excel', 'print',
 {
   extend:'pdfHtml5',
   text:'Pdf',
   orientation:'landscape',
   
}],
    };
    this.GetReviewReminderData();
    this.GetServiceReminderData();

  }

 
  private GetReviewReminderData():void {
    this.showTable1 = false;
    this.reminder.GetReviewReminderData().pipe(take(1)).subscribe((data:any) =>{
      console.warn(data);
      this.showTable1 = true;
      this.ReviewRemindersData=data;
     
      console.log(data);
    })
}
private GetServiceReminderData(): void{
  this.showTable = false;
  this.reminder.GetServicesReminderData().pipe(take(1)).subscribe((data:any) =>{
    console.warn(data);
    this.showTable = true;
    this.ServiceRemindersData=data;
  
    this.SpinnerService.hide();
    console.log(data);
  })
}

public statusClick( reviewRemiander: any): void {
  this.clickedReview = reviewRemiander;
  this.statusChangeAction = reviewRemiander.status ? 'Stop' : 'Send';
  // this.modalService.open(modalContent, { backdrop: 'static', keyboard: false });
}

public changeStatus(): void {
 

  this.reminder.changeReviewReminderStatus(this.clickedReview.appointmentId).pipe(take(1)).subscribe((res: any) => {
    if (res.statusCode === 1) {
      this.clickedReview.status = !this.clickedReview.status;
      swal.fire(
        'Success',
        'Review Reminders will '+ this.statusChangeAction+' to this Customer.',
      'success'
     );
    }
    else{
      if (res.statusCode===2){
        swal.fire(
          'Cancelled!',
          'Review Reminder Not Found',
          'warning'
        );
      }
    }
   
  });
}
public statusClick1( serviceRemiander: any): void {
  this.clickedService = serviceRemiander;
  this.statusChangeAction = serviceRemiander.status ? 'Stop' : 'Send';
  // this.modalService.open(modalContent, { backdrop: 'static', keyboard: false });
}

public changeStatus1(): void {
 

  this.reminder.changeServiceReminderStatus(this.clickedService.appointmentId).pipe(take(1)).subscribe((res: any) => {
    if (res.statusCode === 1) {
      this.clickedService.status = !this.clickedService.status;
      swal.fire(
        'Success',
        'Service Reminders will '+ this.statusChangeAction+' to this Customer.',
      'success'
     );
    }
    else{
      if (res.statusCode===2){
        swal.fire(
          'Cancelled!',
          'Service Reminder Not Found',
          'warning'
        );
      }
      //  window.alert('This Email already Registred');
    }
   
  });
}
}
