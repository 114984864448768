
 <!-- temp-->
 <style>
   
  body, html {
   overflow-x: hidden;
 }
 
 .abc a:hover {
   border-bottom: 2px solid;
 }
 
 .active {
   border-bottom: 2px solid;
   border-color: #E26246;
 }
 .navNav{
   background: transparent; position: absolute;margin-top: -42.4%
 }
 li{
   margin-right: 20px;
 }
 .active{
    
 }
 .navImg{
   width: 100%;  margin-left: 42%; height: 0%;
 }
 .navDiv{
   margin-left: 600px;
 }
 .navButton{
     background: linear-gradient(#E96443, #904965); border-radius: 8px;
     box-shadow: 0px 1px 3px #00000029;color:white;
   }
   .navLi{
     
   }
   .socialIcons{
   width: 53px; height: 167px; background: linear-gradient(#9C006C, #00749A); 
border-bottom-left-radius: 27px; border-top-left-radius: 27px; position: fixed; right: 0; top: 40%;
}
.socialIconsImg1{
 width: 33px; height: 33px; margin-top: 25px;
}
.socialIconsImg2{
 width: 33px; height: 33px;
}
 @media screen and (min-width:320px) and (max-width:1199px){
   .navNav{
     background: linear-gradient(#00749A, #551040);width: 100%;position: relative;padding-top: 45%;
 }
   .navImg{
     width: 15%;height: 15%;  margin-left: 0%;
   }
   .navDiv{
     margin-left: 40%;
   }
   .navButton{
     background: linear-gradient(#E96443, #904965); border-radius: 10%;
     box-shadow: 0px 1px 3px #00000029;color:white;width: 40%;
   }
   .navLi{
     width: 40%;
   }
   .socialIcons{
       width: 33px; height: 167px; background: linear-gradient(#9C006C, #00749A); 
   border-bottom-left-radius: 27px; border-top-left-radius: 27px; position: fixed; right:0;
   }
   .socialIconsImg1{
 width: 33px; height: 33px; margin-top: 25px;
}
.socialIconsImg2{
 width: 33px; height: 33px;
}
 }
 @media screen and (min-width:1450px) and (max-width:2300px){
   .navDiv{
     margin-left: 925px;
   }
   nav{
     margin-left: 9%;
   }
 }



</style>
<nav class="navbar navbar-expand-lg navbar-light bg navNav">
<a class="navbar-brand" href="#"><img class="navImg" src="../../../assets/designs/mtaclogo_FileQA (1).png" alt="" style="width:150px;"></a><!--width: -webkit-fill-available;-->
<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" 
aria-expanded="false" aria-label="Toggle navigation">
 <span class="navbar-toggler-icon"></span>
</button>
<div class="collapse navbar-collapse navDiv" id="navbarNav" >
 <ul class="navbar-nav">
   <li class="nav-item abc navLi" data-toggle="collapse" data-target="#navbarNav" aria-expanded="false" routerLinkActive="active">
     <a class="nav-link "  href="#" style="color: #E26246;" [routerLink]="['../mtac']" > 
       <span style="color: white;"> How it works</span></a>
   </li>
   <li class="nav-item abc navLi"  data-toggle="collapse" data-target="#navbarNav" aria-expanded="false"    routerLinkActive="active">
     <a class="nav-link" href="#"  style="color: #E26246;" [routerLink]="['../industries']"> 
       <span style="color: white;">Industries</span> </a>
   </li>
   <li class="nav-item abc navLi"  data-toggle="collapse" data-target="#navbarNav" aria-expanded="false"    routerLinkActive="active">
     <a class="nav-link" href="#"  style="color: #E26246; " [routerLink]="['../contactus']">
       <span style="color: white;">Contact us</span></a>
   </li>
   <button class="btn btn navButton"  data-toggle="collapse" data-target="#navbarNav" aria-expanded="false" [routerLink]="['../login']" >Login</button>
 </ul>
</div>
</nav>


<!---->
 <!--design 01-->
 <div class="design01">
   <img src="../../assets/designs/Design 01.png" alt="" class="design01Img">
</div>
<!---->

 <!--Social Icons -->
 <div class="text-center socialIcons  w3-hide-small w3-hide-medium">
 <a href="https://www.facebook.com/Mtacpro/" target="_blank"><img style="width: 33px; height: 33px; margin-top: 25px;" src="../assets/socials icons/facebook - white.png" alt=""></a><br>
 <a href="https://www.instagram.com/mtacpro/" target="_blank"><img style="width: 33px; height: 33px;" src="../assets/socials icons/instagram - white.png" alt=""></a><br>
 <a href="https://mobile.twitter.com/manteio_it"  target="_blank"><img style="width: 33px; height: 33px;" src="../assets/socials icons/twitter - white.png" alt=""></a><br>
 <a href="https://in.linkedin.com/company/manteioitsolutions" target="_blank"><img style="width: 33px; height: 33px;" src="../assets/socials icons/linkedin - white.png" alt=""></a>
 </div>
   
 <!--Design 02-->
 <div class="design02">
   <img src="../assets/designs/Design 02.png" alt="" class="design02Img">
 </div>
 <!---->
 
 <img src="../../assets/industries/Industries – 1.png" alt="" class="industriesImg" >
 <style>
   .lappyImg{
    animation-name: stretch;
  animation-duration: 1.5s; 
  animation-timing-function: ease-out; 
  animation-delay: 0s;
  animation-direction: normal;
  animation-iteration-count: inherit;
  animation-fill-mode: none;
  animation-play-state: running; 
   }
   @keyframes stretch {
  0% {
    transform: scale(.1);
    background-color: none;
    border-radius: 100%;
  }
  50% {
    background-color: none;
  }
  100% {
    transform: scale(1.0);
    background-color: none;
  }
}
/*---======----*/
   @keyframes rotate {
 0% {
   transform: rotate(0);
 }
 100% {
   transform: rotate(360deg);
 }
}
 </style>
 <img src="../assets/industries/lappy image.png" alt=""  class="lappyImg">
 <div class="industriesHeader">
   <p class="industriesHeaderPara1" style="font-family: 'konsenssemiBold', sans-serif">Industrie<span style="color: #E26246;">s</span></p><!-- font-family: KonsensSemibold, Regular;-->
   <p class="industriesHeaderPara2" style="width: 435px; padding-top: 10px;">Discover industry specific solutions that match your needs, regardless of your enterprise size.</p>
 </div>
 
 <div class="text-center">
   <p class="industriesMain" style="font-family: 'konsenssemiBold', sans-serif">Industries</p> <!--font-family:KonsensSemibold ☞, Regular ;-->
   <hr   style="margin-left: 45%;" width="7.5%">
   <hr style="margin-left: 49%;" width="7.5%">
 </div>
 
 <div class="text-center industriesMainDiv">
   <p class="industriesMainDivPara">MTacPro is tailored to suit the needs of diverse industries. MTacPro is the perfect fit for your business. Exploring and supporting all businesses from salons to mechanics and restaurants to retail.</p>
 </div>
 
 <style>
   .carousel-arrow-next{right:-30px;background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNOC41OSAxNi41OUwxMy4xNyAxMiA4LjU5IDcuNDEgMTAgNmw2IDYtNiA2LTEuNDEtMS40MXoiLz48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=)}
   img{
     width: 208px;
     height: 260px;
   }
   .carousel-arrow{
     background-color: red;
   }
 </style>
 <!--Carousel Start-->
 <!--style-->
 <style>
   .example {
     position: relative;
     padding: 0;
     width: 100%;
     display: block;
     cursor: pointer;
     overflow: hidden;
   }
   .content {
     opacity: 0;
     font-size: 14px;
     position: absolute;
     top: 2%;
     left: 3%;
     color: white;
     background-color: rgba(58, 4, 63, 0.5);
     width: 94%;
     height: 94%;
     border-radius: 5%;
     -webkit-transition: all 400ms ease-out;
     -moz-transition: all 400ms ease-out;
     -o-transition: all 400ms ease-out;
     -ms-transition: all 400ms ease-out;
     transition: all 400ms ease-out;
     text-align: center;
   }
   .example .content:hover {
     opacity: 1;
   }
   .example .content .text {
     height: 0;
     opacity: 1;
     transition-delay: 0s;
     transition-duration: 0.4s;
   }
   .example .content:hover .text {
     opacity: 1;
     transform: translateY(1%);
     -webkit-transform: translateY(1%);
   }
 </style>
 <!----->

<div class="container" style="padding-bottom: 50px;">
  <!--Row 1-->
  <div class="row">
    
    <div class="col-md-3" style="width: 100%; padding: 0px; height: 100%;">
                <div class="img__wrap">
                  <img class="Ind-Row-1" src="../../../assets/industries/Image 1.png" alt="" style="width: 100%;height: 100%;"  />
                  <div class="img__description filp_card blur" style="background:url('../../../assets/industries/Image\ 1\ copy.png');
                                      background-repeat: no-repeat; background-size: 100% 100%; opacity: 8.6; ">
                    <h3 class="text-center" style="color: white;margin-bottom: 6px; font-weight: 500;  margin-top: 35%;">Real <span style="color: #E26246;">Estate</span></h3>
                    <p class="text-center" style="font-size: 12px; color: white; line-height: 1.5;">Boost your credibility with published reviews and ratings. 
                     Manage appointments and your schedule effortlessly.</p>
                
                  </div>
                </div>
    </div>
    <div class="col-md-3" style="width: 100%; padding: 0px;height: 100%;">
      <div class="img__wrap">
        <img class="Ind-Row-1" src="../assets/industries/Image 2.png" alt="" style="width: 100%;height: 100%;"  />
        <div class="img__description filp_card blur" style="background:url('../../../assets/industries/Image\ 2\ copy.png');
                            background-repeat: no-repeat; background-size: 100% 100%; opacity: 8.6; ">
          <h3 class="text-center" style="color: white;line-height: 1.4;font-weight: 500; margin-bottom: 6px; margin-top: 35%;">Professional <span style="color: #E26246;">Services</span></h3>
          <p class="text-center" style="font-size: 12px; color: white;  line-height: 1.5;">Eliminate scheduling back-and-forth, manage client data, boost employee productivity, 
            accept online payments and automate marketing.</p> 
      
        </div>
      </div>
    </div>
    <div class="col-md-3" style="width: 100%; padding: 0px;height: 100%;">
      <div class="img__wrap">
        <img class="Ind-Row-1" src="../assets/industries/Image 3.png" alt="" style="width: 100%;height: 100%;"  />
        <div class="img__description filp_card blur" style="background:url('../../../assets/industries/Image\ 3\ copy.png');
                            background-repeat: no-repeat; background-size: 100% 100%; opacity: 8.6; ">
          <h3 class="text-center" style="color: white; margin-bottom: 6px;font-weight: 500; margin-top: 35%;">Event <span style="color: #E26246;">Planning</span></h3>
          <p class="text-center" style="font-size: 12px; color: white; line-height: 1.5;">Increase engagement between customers, prospects, employees and exhibitors. 
            Make valuable connections on the go </p>
      
        </div>
      </div>
    </div>
    <div class="col-md-3" style="width: 100%; padding: 0px;height: 100%;">
      <div class="img__wrap">
        <img class="Ind-Row-1" src="../assets/industries/Image 4.png" alt="" style="width: 100%;height: 100%;"  />
        <div class="img__description filp_card blur" style="background:url('../../../assets/industries/Image\ 4\ copy.png');
                            background-repeat: no-repeat; background-size: 100% 100%; opacity: 8.6; ">
          <h3 class="text-center" style="color: white;margin-bottom: 6px;font-weight: 500; margin-top: 35%;">Financial <span style="color: #E26246;">Services</span></h3>
          <p class="text-center" style="font-size: 12px; color: white; line-height: 1.5;">Manage appointments and tasks. Stay connected to the customer and increase client loyalty with MTacPro</p>
      
        </div>
      </div>
    </div>
    
    
  </div>

  <!--Row 2-->
  <div class="row">
  
    <!--<div class="col-md-2" style="width: 100%; padding: 0px; height: 100%;">
                <div class="img__wrap">
                  <img class="Ind-Row-1" src="../../../assets/industries/Image 6.png" alt="" style="width: 100%;height: 100%;"  />
                  <div class="img__description filp_card blur" style="background:url('../../../assets/industries/Image\ 6\ copy.png');
                                      background-repeat: no-repeat; background-size: 100% 100%; opacity: 8.6; ">
                    <h3 class="text-center" style="color: white;margin-bottom: 6px;font-weight: 500;line-height: 1.4;">Auto Wash and <span style="color: #E26246;">Assistance</span></h3>
                    <p class="text-center" style="font-size: 12px; color: white; line-height: 1.5;">MTacPro gives you all the features you need to manage your calendar and rapidly grow your car wash business.</p>
                
                  </div>
                </div>
    </div>
    <div class="col-md-2" style="width: 100%; padding: 0px;height: 100%;">
      <div class="img__wrap">
        <img class="Ind-Row-1" src="../assets/industries/Image 7.png" alt="" style="width: 100%;height: 100%;"  />
        <div class="img__description filp_card blur" style="background:url('../../../assets/industries/Image\ 7\ copy.png');
                            background-repeat: no-repeat; background-size: 100% 100%; opacity: 8.6; ">
          <h3 class="text-center" style="color: white;margin-bottom: 6px;font-weight: 500;">Other <span style="color: #E26246;">Services</span></h3>
          <p class="text-center" style="font-size: 12px; color: white; line-height: 1.5;">Increase your credibility and engage your customers by publishing reviews. 
            Manage tickets and appointments from anywhere and anytime.</p>
      
        </div>
      </div>
    </div>-->
    <div class="col-md-3" style="width: 100%; padding: 0px;height: 100%;">
      <div class="img__wrap">
        <img class="Ind-Row-1" src="../assets/industries/Image 8.png" alt="" style="width: 100%;height: 100%;"  />
        <div class="img__description filp_card blur" style="background:url('../../../assets/industries/Image\ 8\ copy.png');
                            background-repeat: no-repeat; background-size: 100% 100%; opacity: 8.6;  ">
          <h3 class="text-center" style="color: #E26246; margin-bottom: 6px; font-weight: 500; margin-top: 35%;">Construction </h3>
          <p class="text-center" style="font-size: 12px; color: white; line-height: 1.5;"><!--The only one you need to manage your worksite. -->MTacPro gives you eyes on the site when and where you need. 
            Coordinating and managing project has never been easier</p>
      
        </div>
      </div>
    </div>
    <div class="col-md-3" style="width: 100%; padding: 0px;height: 100%;">
      <div class="img__wrap">
        <img class="Ind-Row-1" src="../assets/industries/Image 5.png" alt="" style="width: 100%;height: 100%;"  />
        <div class="img__description filp_card blur" style="background:url('../../../assets/industries/Image\ 5\ copy.png');
                            background-repeat: no-repeat; background-size: 100% 100%; opacity: 8.6; ">
          <h3 class="text-center" style="color: #E26246; margin-bottom: 6px; font-weight: 500; margin-top: 35%;">Automation</h3>
          <p class="text-center" style="font-size: 12px; color: white; line-height: 1.5;">An excellent 
            <!--all in one platform that extends and goes beyond the standard of functionality.
            From--> automatically emailing customers with invite to create an account, 
            to auto-tagging orders and customers you can do it all</p>
      
        </div>
      </div>
    </div>
    <div class="col-md-3" style="width: 100%; padding: 0px;height: 100%;">
      <div class="img__wrap">
        <img class="Ind-Row-1" src="../assets/industries/Image 9.png" alt="" style="width: 100%;height: 100%;"  />
        <div class="img__description filp_card blur" style="background:url('../../../assets/industries/Image\ 9\ copy.png');
                            background-repeat: no-repeat; background-size: 100% 100%; opacity: 8.6; ">
          <h3 class="text-center" style="color: #E26246; margin-bottom: 6px;font-weight: 500; margin-top: 35%;">Retail</h3>
          <p class="text-center" style="font-size: 12px; color: white; line-height: 1.5;"><!--Engage your customers and encourage sales with MTacPro. -->Add reviews and score more with your potential clients. 
            Helping you manage everything you need at just your fingertips</p>
      
        </div>
      </div>
    </div>
    <div class="col-md-3" style="width: 100%; padding: 0px;height: 100%;">
      <div class="img__wrap">
        <img class="Ind-Row-1" src="../assets/industries/Image 10.png" alt="" style="width: 100%;height: 100%;"  />
        <div class="img__description filp_card blur" style="background:url('../../../assets/industries/Image\ 10\ copy.png');
                            background-repeat: no-repeat; background-size: 100% 100%; opacity: 8.6; ">
          <h3 class="text-center" style="color: #E26246; margin-bottom: 6px; font-weight: 500; margin-top: 35%;">Wellness</h3>
          <p class="text-center" style="font-size: 12px; color: white; line-height: 1.5;">Offer more than just appointments. Go beyond the usual and reduce the no shows. 
            Customize everything to match your brand experience</p>
      
        </div>
      </div>
    </div>
 
  </div>
</div>

 

 <app-footer></app-footer>
 <!--
 <style>
   img{
     height: 260px;
     width: 208px;
   }
 </style>
 <div class="w3-row">
   <div class="w3-col l1">
     <h1></h1>
   </div>
   <div class="w3-col l2">
     <img src="../assets/industries/Image 1.png" alt="">
     <img src="../assets/industries/Image 2.png" alt="">
   </div>
   <div class="w3-col l2">
     <img src="../assets/industries/Image 3.png" alt="">
     <img src="../assets/industries/Image 4.png" alt="">
   </div>
   <div class="w3-col l2">
     <img src="../assets/industries/Image 5.png" alt="">
     <img src="../assets/industries/Image 6.png" alt="">
   </div>
   <div class="w3-col l2">
     <img src="../assets/industries/Image 7.png" alt="">
     <img src="../assets/industries/Image 8.png" alt="">
   </div>
   <div class="w3-col l2">
     <img src="../assets/industries/Image 9.png" alt="">
     <img src="../assets/industries/Image 10.png" alt="">
   </div>
 </div>-->
 