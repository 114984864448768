<div class="main_bg">
  <link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css" />
  <!------ Include the above in your HEAD tag ---------->
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <div class="rowCol1Div">
        <a href="http://mtacpro.com/" routerLink="">
          <img src="../../assets/login/Mtac- LOGO.png" alt="logo" class="login-logo" /></a>
      </div>

    </div>
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <div class="rowCol2Div">
        <form class="rowCol2Form" action="" [formGroup]="loginForm" (ngSubmit)="submitForm()">
          <p class="rowCol2FormP">Login</p>
          <div>
            <label for="">Email</label><br>
            <input class="rowCol2Input form-control" type="email" formControlName="email">
          </div>
          <div *ngIf="isSubmitClicked && loginFormControl.email.errors" class="alert alert-danger">
            <div *ngIf="loginFormControl.email.errors.required">
              Email is required.
            </div>
            <div *ngIf="loginFormControl.email.errors.pattern">
              Must be a valid email format.
            </div>
          </div>
          <div>
            <label for="">Password</label><br>
            <input class="rowCol2Input form-control" type="password" formControlName="password">
          </div>
          <div *ngIf="isSubmitClicked && loginFormControl.password.errors" class="alert alert-danger">
            <div *ngIf="isSubmitClicked && loginFormControl.password.errors">
              Password is required.
            </div>
          </div>
          <br>
          <div class="custom-control custom-checkbox col-md-6">
            <input type="checkbox" class="custom-control-input" id="customControlInline" />
            <label class="custom-control-label checktext" for="customControlInline">Remember me</label>
          </div>
          <br>
          <div>
            <button class="btn btn-primary rowCol2Button" type="submit">Login</button>
          </div>
          <br>
          <div class="custom-control">
            <a href="" class="forgetpassword rowCol2Forgot" routerLink="/recoverypassword">Forgot Password?</a>
          </div>
        </form>
      </div>

    </div>
  </div>

  <!--
  <div class="container login-container">

    <div class="row" style="height: auto">
      <div class="col-md-4 login-form-1">
        <a href="http://mtacpro.com/" routerLink="">
        <img
          src="../../assets/images/logo-crop.png"
          alt="logo"
          class="login-logo"
        /></a>
        <br />
        <h3 style="text-align: left; margin-left: 5%">Login</h3>
        <form [formGroup]="loginForm" (ngSubmit)="submitForm()">
          <div class="form-group">
            <div class="input-group">
              <input
                type="email"
                class="form-control logininput"
                formControlName="email"
                placeholder="Username"
                value=""
              />
              <i
                class="fa fa-user"
                aria-hidden="true"
                style="color: dimgray; right: 10px;
                top: 10px;
                position: absolute;
                font-size: 20px; position: absolute"
              ></i>
            </div>
          </div>
          <div
            *ngIf="isSubmitClicked && loginFormControl.email.errors"
            class="alert alert-danger"
          >
            <div *ngIf="loginFormControl.email.errors.required">
              Email is required.
            </div>
            <div *ngIf="loginFormControl.email.errors.pattern">
              Must be a valid email format.
            </div>
          </div>

          <div class="form-group" style="margin-top: 35px">
            <div class="input-group">
              <input
                type="password"
                class="form-control logininput"
                formControlName="password"
                placeholder="Password"
                value=""
              />
              <i
                class="fa fa-lock"
                aria-hidden="true"
                style="color: dimgray; right: 10px;
                top: 10px;
                position: absolute;
                font-size: 20px; position: absolute"
              ></i>
            </div>
          </div>
          <div
            *ngIf="isSubmitClicked && loginFormControl.password.errors"
            class="alert alert-danger"
          >
            <div *ngIf="isSubmitClicked && loginFormControl.password.errors">
              Password is required.
            </div>
          </div>

          <div class="form-group">
            <input type="submit" class="btnSubmit" value="LOGIN" />
           
          </div>

          <div class="form-group">
            <div class="row" style="margin-left: 0px">
              <div class="custom-control custom-checkbox col-md-6">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customControlInline"
                />
                <label
                  class="custom-control-label checktext"
                  for="customControlInline"
                  >Remember me</label
                >
              </div>
              <div class="custom-control col-md-6" style="float: right">
                <a href="" class="forgetpassword" routerLink="/recoverypassword" >Forgot Password?</a >
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-4 login-form-2">
        <h2 class="login_h2">WELCOME!</h2>
        <br>
        <h5 class="login_h5">Enter your details and start <br>journey with MTac</h5>
      </div>
    </div>
  </div>-->
  <!-- <a  class="btn btnSubmit" [routerLink]="['app-dashboard']">LOGIN</a> -->
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>