import { Injectable } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { data, param } from 'jquery';
@Injectable({
  providedIn: 'root'
})
export class GetappointmentsService {
  ClientId: any;
  // getReviews() {
  //   throw new Error('Method not implemented.');
  // }

  constructor(private http:HttpClient) {
  
   }
getClientId()
{
  this.ClientId  = localStorage.getItem("ClientId");
 // alert(this.ClientId);
  
 return this.ClientId;

}

  getReqests(){
    let url=`${environment.apiUrl}superAdmin/GetContactusCustomers?ClientId=`+this.ClientId;
    return this.http.get(url);
  }
  getmake(){
    let url=`${environment.apiUrl}superAdmin/GetMakes?ClientId=`+this.ClientId;
    return this.http.get(url);
  }
  getModelsById(id){
    let url=`${environment.apiUrl}superAdmin/GetModelsByMakeId?makeId=`+id+'&ClientId='+this.ClientId;
    return this.http.get(url);
  }
  getAppointmentByIdForReview(appointmentId){
    let url=`${environment.apiUrl}superAdmin/getappointmentdetailsforreview?appointmentId=`+appointmentId;
    //  let url="https://localhost:44314/api/MTac/GetAppointmentbyId?appointmentId="+appointmentId;
    return this.http.get(url);
  }
  getAppointmentById(appointmentId){
    let url=`${environment.apiUrl}superAdmin/GetAppointmentbyId?appointmentId=`+appointmentId;
    //  let url="https://localhost:44314/api/MTac/GetAppointmentbyId?appointmentId="+appointmentId;
    return this.http.get(url);
  }
  getUsersByClientId(){
    let url=`${environment.apiUrl}register/GetAllUsersByClient?ClientId=`+this.ClientId;
    return this.http.get(url);
  }
  getServices(){
    let url=`${environment.apiUrl}superAdmin/GetServices?ClientId=`+this.ClientId;
    return this.http.get(url);
  }
  getTechnicions(){
    
    let url=`${environment.apiUrl}register/getallusersbyrole?ClientId=`+this.ClientId+'&RoleId='+4;
    return this.http.get(url);
  }

  getallactiveappointments(){
    let url=`${environment.apiUrl}superAdmin/getallactiveappointments?ClientId=`+this.ClientId;
    return this.http.get(url);
  }

  getalldata(){
    let url=`${environment.apiUrl}superAdmin/getcustomerlatestappointments?ClientId=`+this.ClientId;
    return this.http.get(url);
  } 
  
  getAppointmentsByStaus(appointmentStatus){
    let url=`${environment.apiUrl}superAdmin/GetAppointmentsByStatus?ClientId=`+this.ClientId+'&status='+appointmentStatus;
    return this.http.get(url);
  }
  getAppointmentsByFilter(appointmentStatus,startDate,endDate){
    let url=`${environment.apiUrl}superAdmin/GetAppointmentsByStatus?ClientId=`+this.ClientId+'&status='+appointmentStatus+'&startDate='+startDate+'&endDate='+endDate;
    return this.http.get(url);
  }
  dashboarddata(){
    let url=`${environment.apiUrl}superAdmin/DashBoard?ClientId=${this.ClientId}&months=${6}`;
    console.log(data);
    return this.http.get<any>(url);
  }
  Reportsdata(){
    let url=`${environment.apiUrl}superAdmin/Reports?ClientId=${this.ClientId}&months=${6}`;
    console.log(data);
    return this.http.get<any>(url);
  }

  WalkinAppointmentAddandUpdate(data){
   let url=`${environment.apiUrl}superAdmin/WalkinAppoinment`;
   // let url=`https://localhost:44314/api/MTac/WalkinAppoinment`;
    console.log(data);
    return this.http.post<any>(url,data);

  }
  AddUser(data){
    let url=`${environment.apiUrl}register/signup`;

     console.log(data);
     return this.http.post<any>(url,data);

   }
   AddCarinspection(data){
    let url=`${environment.apiUrl}superAdmin/WalkinAppoinment`;
     return this.http.post<any>(url,data);
  
   }

   changeUserStatus(userId) {
    let url=`${environment.apiUrl}register/changeUserStatus?ClientId=`+this.ClientId+"&UserId="+userId;
    return this.http.post<any>(url,data);
    
    
  }
  getFilterdDataAppointments(startDate,endDate){
    let url=`${environment.apiUrl}superAdmin/getallactiveappointments?ClientId=`+this.ClientId+'&StartDate='+startDate+'&EndDate='+endDate;
    return this.http.get(url);
  }
  

  gettodayandinprogressappointments(){
    let url=`${environment.apiUrl}superAdmin/gettodayandinprogressappointments?ClientId=`+this.ClientId;
    return this.http.get(url);
  }

  //for customer requets
  getFilterdCustomerRequsts(startDate,endDate){
    let url=`${environment.apiUrl}superAdmin/GetContactusCustomers?ClientId=`+this.ClientId+'&StartDate='+startDate+'&EndDate='+endDate;
    return this.http.get(url);
  }
  
  getReviewsBySourceId(sourceId){
    let url=`${environment.apiUrl}superAdmin/GetReviewsBySourceId?ClientId=`+this.ClientId+'&sourceId='+sourceId;
    return this.http.get(url);
  }
}
