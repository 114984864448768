import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../app/services/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  [x: string]: any;
  LoginData = [];
  user =  this.AuthenticationService;
  constructor(private AuthenticationService: AuthenticationService) {
    this.AuthenticationService.currentUserEmail;
    this.AuthenticationService.currentUserName;
  }

  ngOnInit(): void {

  }


  logout() {
    this.AuthenticationService.logout();
}
}
