import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as JwtDecode from 'jwt-decode';

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  clientId: any;
  getItem() {
      throw new Error("Method not implemented.");
  }
  isAuthenticated() {
      throw new Error("Method not implemented.");
  }
  // public userName: string;
  public email: string;
  public role: string;
  public userName: string;
  public token: any = null ;
  currentUser: any;
  currentUserValue: any;
  // private adminNoPermissions: Array<{
  //   role: string;
  //   category: string;
  //   permitionTypes: Array<string>;
  // }>;
  constructor(private route: Router) {
   // this.logdin(localStorage.getItem("A_accesstoken"));
   this.getUserDetails();
  }

  public get currentUserName(): string  {
    return this.userName;
  }

  public get currentUserRole(): string  {
    return this.role;
  }

  public get currentUserEmail(): string  {
    return this.email;
  }
  public getUserDetails(){
  //  this.user = new User();
    this.token =  localStorage.getItem("A_accesstoken");
    if (this.token) {
      localStorage.setItem("A_accesstoken", this.token);
      const user : any = JwtDecode(this.token);
      const time_to_login = Date.now() + 86400000; // one day
        localStorage.setItem('timer', JSON.stringify(time_to_login));
      // this.userName =
      // user["sub"];
    this.email =
      user["email"];
      this.role = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      this.userName = user["userName"];
    }
  }
  public logdin(token: any): void {

    if (token) {
      localStorage.setItem("A_accesstoken", token);
      const user : any = JwtDecode(token);
      console.log(user,"user");
      const time_to_login = Date.now() + 86400000; // one day
        localStorage.setItem('timer', JSON.stringify(time_to_login));
      // this.userName =
      // user["sub"];
    this.email = user["email"];
      this.role = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];


  localStorage.setItem("ClientId",user["ClientId"])
 

    } else {
      this.logout();
    }

  }
  public logout(): void {
    // this.userName = null;
    this.email = null;
    this.role = null;
    this.userName = null;
    localStorage.removeItem("A_accesstoken");
    localStorage.removeItem("ClientId");
    localStorage.removeItem("usernamek");
    localStorage.removeItem("RoleId");
    this.route.navigateByUrl("/login");
  }

  public get getToken(){
    return localStorage.getItem('A_accesstoken');
    console.log();
 }
}
