import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  VALID = "VALID"
  loginForm: FormGroup;
  public isSubmitClicked: boolean = false;
  constructor(private fb: FormBuilder, private service: LoginService) { }

  ngOnInit(): void {
    this.initForm();
  }
  public get loginFormControl() { return this.loginForm.controls; }
  isValidInput(fieldName): boolean {
    return (
      this.loginForm.controls[fieldName].invalid &&
      (this.loginForm.controls[fieldName].dirty ||
        this.loginForm.controls[fieldName].touched)
    );
  }
  public submitForm(loginForm: {first; last; email; mobile; subject; message}): void {
    this.isSubmitClicked = true;
    const xyz = this.loginForm.status
    console.log(xyz)
    if(this.VALID == xyz){
    const data = {
      "fromMtac": true,
      "firstName": loginForm["first"],
      "lastName": loginForm["last"],
      "email": loginForm["email"],
      "mobile": loginForm["mobile"],
      "subject": loginForm["subject"],
      "message": loginForm["message"]
    }
    console.log(data)
    if(data){
      this.service.saveContactUs(data).pipe(take(10)).subscribe((res:any)=> {
        if(res.statusCode===1){
          console.log('success')
           
          Swal.fire( {
            position: 'center',
            icon: 'success',
            title: 'Successfully Submitted',
            showConfirmButton: false,
            timer: 2500
          })
          window.setTimeout(function(){ 
            location.reload();
        } ,2500);
        }
      })
    }
  }

  }
  initForm(): void {
    this.loginForm = this.fb.group({
      email: ['',[Validators.required,Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'),],],
      first: ['',[Validators.required],],
      message: ['', Validators.required],
      last:[''],
      mobile:[''],
      subject:['']
    });
  }


}
