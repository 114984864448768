<app-navbar></app-navbar>
<div class="row mainrow_margin">
   <div class="col-md-2">
    <app-sidenav></app-sidenav>
   </div>
   <div class="col-md-10">
<div class="container">
    <ul class="nav nav-tabs" role="tablist" style="margin-top:30px;margin-left: 15px;
    margin-right: 15px;">
        <li class="nav-item btnmtac" style="    width: 80px;">
            <a class="nav-link active" data-toggle="tab" href="#Users">Users</a>
        </li>
        <li class="nav-item btnmtac" style="position: absolute;right: 35px;">
            <a class="nav-link" data-toggle="tab" href="#Invite"><i class="fa fa-plus" aria-hidden="true"></i> Invite Users</a>
        </li>
      </ul>
    <!-- Nav tabs -->


<!-- Tab panes -->
<div class="tab-content">
    <div id="Users" class="container tab-pane active"><br>
      <div class="table-responsive">
      <table class="table table-striped table-bordered" id="example" *ngIf="showTable" datatable [dtOptions]="dtOptions"   style="width:100%;">

          <thead class="thead-dark">
              <tr>
              <th style="font-weight: 400;">First Name</th>
              <th style="font-weight: 400;">Email</th>
              <th style="font-weight: 400;">Role</th>
              <th style="font-weight: 400;">Status</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor= "let list of getUsersByClient">
           <td>{{list.userName}}</td>
           <td>{{list.email}}</td>
           <td>{{list.roleName}}</td>
           <td>
            <i class="fa fa-toggle-on fa-2x" data-toggle="modal" data-target=".bd-example-modal-lg5" [ngClass]="list.status?'active':'fa-rotate-180 inactive'"
                (click)="statusClick(list)"></i>
        </td>
          </tr>
          </tbody>
      </table>

  </div>
    </div>
    <div id="Invite" class="container tab-pane fade">
        <br>
        <form [formGroup]="UserAddForm" (ngSubmit)="UserAdd()" >
            <label  style="margin-top: 15px;font-weight: 600;">Name</label>
            <div class="row">
                <div class="col-md-4 form-group">
                    <input  formControlName="UserName"  type="text"  class="form-control" id="name"   name="name">
                    <span style="color:  #d70b0b;font-size: 14PX;"  *ngIf="!UserAddForm.controls['UserName'].valid&&UserAddForm.controls['UserName'].dirty">
                        <span *ngIf="UserAddForm.controls.UserName.errors.required">First Name is Required.</span>
                        <span *ngIf="UserAddForm.controls.UserName.errors.minlength">User name minimum should be length of 5.</span>
                        <span *ngIf="UserAddForm.controls.UserName.errors.maxlength">User name maximum should be length of 50.</span>
                      </span>
                </div>
            </div>
            <label style="font-weight: 600;">Email</label>
            <div class="row" >
                <div class="col-md-4 form-group">
                    <input formControlName="Email"   type="text"  class="form-control" id="email"  name="email">
                    <span style="color:  #d70b0b;font-size: 14PX" *ngIf="!UserAddForm.controls['Email'].valid&&UserAddForm.controls['Email'].dirty">
                        <span *ngIf="UserAddForm.controls.Email.errors.required">Email is Required.</span>
                        <span *ngIf="UserAddForm.controls.Email.errors.email">Email is not in correct pattern.</span>
                      </span>
                </div>
            </div>
            <label style="font-weight: 600;">Password</label>
            <div class="row" >
                <div class="col-md-4 form-group">
                    <input formControlName="Password"   type="password"  class="form-control" id="password"  name="password">
                    <span style="color:  #d70b0b;font-size: 14PX;"  *ngIf="!UserAddForm.controls['Password'].valid&&UserAddForm.controls['Password'].dirty">
                        <span *ngIf="UserAddForm.controls.Password.errors.required">First NamePassword is Required.</span>
                        <span *ngIf="UserAddForm.controls.Password.errors.minlength">Password minimum should be length of 5.</span>
                        <span *ngIf="UserAddForm.controls.Password.errors.maxlength">Password maximum should be length of 50.</span>
                      </span>    
                </div>
            </div>

            <label style="font-weight: 600;">Role</label>
            <div class="row" >
                <div class="col-md-4 form-group">
                    <select class="form-control" id="ddlRoles" name="ddlRoles"  formControlName="Roles" >
                        <option value="" disabled> Select</option>
                        <option value="3" >Admin</option>
                        <option value="4" >Technician</option>
                        <option value="5" >Supervisor</option>
                   
                    </select>

                      <span style="color: #d70b0b;" *ngIf="UserAddForm.controls.Roles.touched && UserAddForm.controls.Roles.invalid">
                              <span *ngIf="UserAddForm.controls.Roles.errors.required">Role is Required.</span>
      
                            </span> 
                    
                   
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                     <button [disabled]="!UserAddForm.valid" type="submit" class="btn  btn-danger text-white text-center">Invite User</button>
                </div>
        </div>
        </form>


</div>



</div>
</div>
</div>
<div class="modal fade bd-example-modal-lg5" id="demo-customers" tabindex="-1" style="overflow:scroll;">
    <div class="modal-dialog modal-dialog-centered appointment_popup_main">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel-2">{{statusChangeAction}}  User</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure want to {{statusChangeAction}} User?</p>
            </div>
            <div class="modal-footer">  
                 <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success"  data-dismiss="modal" (click)="changeStatus()">{{statusChangeAction}}</button>
            </div>
        </div>
    </div>
  </div>