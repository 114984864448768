import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import {GetappointmentsService} from '../../../getappointments.service'
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-customer-requests',
  templateUrl: './customer-requests.component.html',
  styleUrls: ['./customer-requests.component.css']
})
export class CustomerRequestsComponent implements OnInit {

  public dtOptions: any = {};
public showTable: boolean = false;
    getReqests:[];
    startDate:string;
  endDate:string;
  requestFilter1: string;
  requestFilter2: string;
  userName: string;
  clientId: any;
    constructor(private user:GetappointmentsService,private SpinnerService: NgxSpinnerService) {
     
     }

     getcustRequest()
     {
      this.showTable = false;
      this.user
      .getReqests()
      .pipe(take(1))
      .subscribe((data: any) => {

        this.getReqests = data;
        this.requestFilter1="show";
        this.requestFilter2="hide";
        this.showTable = true;
        this.SpinnerService.hide();
        console.log(data);
      });
     }
     getStartDate(startDate :string,)
     {
       this.startDate=startDate;
    
     }
   
     getEndDate(endDate :string,)
     {
       this.endDate=endDate;
    
      
     }
   
     getFilterdData( )
  {
    this.SpinnerService.show();
    this.requestFilter1="hide";
    this.requestFilter2="show";
    this.showTable=false;
    this.user
    .getFilterdCustomerRequsts(this.startDate,this.endDate)
    .pipe(take(1))
    .subscribe((data: any) => {
      this.showTable=true;
      this.getReqests = data;
      this.SpinnerService.hide();
      console.log(data);
    });
  }

  //get back to review from filters 
 requestClearFilter()
 {
  this.requestFilter1="show";
      this.requestFilter2="hide";
     (<any>$("#demo-7")).modal('hide');
   this.getcustRequest();
 }
     ngOnInit(): void {
      this.userName  = localStorage.getItem("usernamek");
      this.user.getClientId();
      this.clientId  = localStorage.getItem("ClientId");
      this.getcustRequest();
      this.SpinnerService.show();

      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 5,
      lengthMenu : [ 5, 10, 25, 50, 100],
        processing: true,
        dom:
        "<'row'<'col-sm-6'i><'col-sm-6'f>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'row'<'col-sm-4'l><'col-sm-8'p>>" +
      "<'row'<'col-sm-6'B>>",
      buttons: [
        'excel', 'print',
    {
     extend:'pdfHtml5',
     text:'Pdf',
     orientation:'landscape',
     
    }],
      };

    }

  }
