import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Router, ActivatedRoute} from '@angular/router';
import {ForgotpasswordService } from '../services/forgotpassword.service';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-recoverypassword',
  templateUrl: './recoverypassword.component.html',
  styleUrls: ['./recoverypassword.component.css']
})
export class RecoverypasswordComponent implements OnInit {
  [x: string]: any;
  recoverypasswordForm: FormGroup;
  recoverypasswordData = [];
  data: string[];
  constructor(private fb: FormBuilder,
    private user: ForgotpasswordService  ,
    private route: ActivatedRoute,
    private SpinnerService: NgxSpinnerService,
    private router: Router, ) { }

  ngOnInit(): void {
    this.initForm();
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
  Object.keys(formGroup.controls).forEach(field => {  //{2}
    const control = formGroup.get(field);             //{3}
    if (control instanceof FormControl) {             //{4}
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {        //{5}
      this.validateAllFormFields(control);            //{6}
    }
  });
}

isValidInput(fieldName): boolean {
  return this.recoverypasswordForm.controls[fieldName].invalid &&
    (this.recoverypasswordForm.controls[fieldName].dirty || this.recoverypasswordForm.controls[fieldName].touched);
}
initForm(): void {
  this.recoverypasswordForm = this.fb.group({
    email: ['', [Validators.required,
    Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')]]
});
}
  onSubmit(){
this.SpinnerService.show();
    if (this.recoverypasswordForm.valid) {
      
     this.custemail =this.recoverypasswordForm.value;
     alert
      this.user
        .recoverypasswordData(this.custemail)
        .pipe(take(1))
        .subscribe((res: any) => {
          console.log(res);
          this.SpinnerService.hide();
          if (res.statusCode=== 1){
           
            swal.fire(
              
              'Success',
              "Please Check Your Email for Reset Password Link.",
            'success'
         );
            //  window.alert("Please check your email for reset password Link");
             // this.router.navigate(['/login']);
          }
          else{ 
            if (res.statusCode===2){
              this.SpinnerService.hide();
              swal.fire(
                'Cancelled!',
                'User not found with Entered Email Address.',
                'error'
              );
             // window.alert('Please Enter a valied email');

          }
         
        }
        });
    }
  }

}
