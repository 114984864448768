<app-newnav></app-newnav>
<!--Social Icons --->
<div class="text-center socialIcons  w3-hide-small w3-hide-medium">
  <a href="https://www.facebook.com/Mtacpro/" target="_blank"><img style="width: 33px; height: 33px; margin-top: 25px;"
      src="../assets/socials icons/facebook - white.png" alt=""></a><br>
  <a href="https://www.instagram.com/mtacpro/" target="_blank"><img style="width: 33px; height: 33px;" src="../assets/socials icons/instagram - white.png" alt=""></a><br>
  <a href="https://mobile.twitter.com/manteio_it"  target="_blank"><img style="width: 33px; height: 33px;" src="../assets/socials icons/twitter - white.png" alt=""></a><br>
  <a href="https://in.linkedin.com/company/manteioitsolutions" target="_blank"><img style="width: 33px; height: 33px;" src="../assets/socials icons/linkedin - white.png" alt=""></a>
</div>
<!----------->

<div>
  <div
    style="background: url('../../../assets/Contactus–1.png'); background-repeat: no-repeat; background-size: 100% 200%; height: 507.37px;">
    <div class="contactUs">
      <p class="contactP" style=" font-weight: bold; color: white; font-family: 'konsenssemiBold', sans-serif">Contact
        <span style="color: orange;"> us</span></p>

    </div>
    <div class="contactUsPara">
      <p class="text-center" style="color: white;">We're here to answer any questions you might have.
        We look forward to hear from you.</p>
    </div>

    <!--Circle Images-->

    <div class="posiAbsolute design8 w3-hide-small w3-hide-medium">
      <marquee behavior="slide" direction="down">
        <img src="../../assets/Design 8.png" alt="">
      </marquee>

    </div>
    <div class="posiAbsolute design9 w3-hide-small w3-hide-medium">
      <marquee behavior="slide" direction="down">
        <img src="../../assets/Design 9.png" alt="">
      </marquee>

    </div>
    <div class="posiAbsolute design10 w3-hide-small w3-hide-medium">
      <marquee behavior="slide" direction="down">
        <img src="../../assets/Design 10.png" alt="">
      </marquee>

    </div>
    <div class="posiAbsolute design6 w3-hide-small w3-hide-medium">
      <marquee behavior="slide" direction="down">
        <img src="../../assets/Desgin 6.png" alt="">
      </marquee>

    </div>
    <div class="posiAbsolute design7 w3-hide-small w3-hide-medium">
      <marquee behavior="slide" direction="down">
        <img src="../../assets/Design 7.png" alt="">
      </marquee>

    </div>
    <!---->

  </div>
</div>


<!---About us-->
<div class="w3-row text-center aboutUsDiv" style="margin-left: 0; margin-right: 0;">
  <p class="aboutUsPara" style="font-family: 'konsenssemiBold', sans-serif">About us</p>
  <hr style="margin-left: 45%;" width="7.5%">
  <hr style="margin-left: 49%;" width="7.5%">
</div>
<div class="container">
  <div class="row" style="margin-left: 0; margin-right: 0;">
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 rowCol1" style="padding-top: 50px;">
      <p class="rowCol1Para1" style="line-height: 1.6;font-family: 'Sofia Pro', Regular; color: #333333; text-align: left; margin-bottom: 10px;">Your compact solution that
        glides you ahead of the curve.</p>
      <p class="rowCol1Para2" style="font-family: 'Sofia Pro', Regular;text-align: left; margin-bottom: 10px;">What MTacPro is all about.</p>
      
      <p class="rowCol1Para3" style="font-family: 'Sofia Pro', Regular;text-align: left;">Making the switch to a clever and smooth
        MTacPro is really effortless.
        Go beyond conventional measurement and take the steps that will generate real business value. MTacPro is
        designed to be scalable and sustainable for all business needs alike.</p>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 rowCol2">
      <marquee behavior="slide" direction="" scrollamount="20">
        <img src="../../assets/about us image.png" alt="" class="rowCol2Img imageWidth">
      </marquee>

    </div>

  </div>
</div>

<br><br><br>

<!--Mobile Side 
  <div class="mobileAbsolute">
    <div>
      <div class="text-center">
        <p class="pp" style="font-size: 39px; color: #FC0E40;letter-spacing: 1.17px; font-weight: bold; 
      font-family: sans-serif;">Contact us</p>
      </div>
      <div>
        <p class="mobilePara">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
          laying out print, graphic or web designs.
          The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of
          Cicero's De.</p>
      </div>
    </div>
  
    <div style="margin-left: 2%;">
      <img class="img100" src="../assets/mobile1.png" alt="">
    </div>
  </div>
   MObile Side-->
<!--
    
  
  <img class="img100" src="../../assets/Contact us-BG.png" alt="" style="height: 736.62px; width: 100%;">-->

<!-- Start Transparent Form 
  <div class="formTrans form100" style=" height: auto; border-radius: 10px; position: absolute; color: white; 
  background-image: url(../../assets/Contact\ us-BG.png);background: transparent; background-color: #7e787829 ">
  <form class="" style="padding: 5%; background: transparent;">
    <div class="w3-row">
      <div class="w3-col l6 m6 s12" style="padding-right:5% ;">
        <label>First Name</label>
        <input type="text" class="form-control inputBackground">
      </div>
      <div class="w3-col l6 m6 s12" style="padding-right:5% ;">
        <label>First Name</label>
        <input type="text" class="form-control inputBackground">
      </div>
    </div>
    <div class="w3-row">
      <label>First Name</label>
        <input type="text" class="form-control inputBackground">
    </div>
    <div class="w3-row" style="padding-top: 5%;">
      
      <div class="w3-col l2 m3 s4">
        <label style="padding-bottom: 0%; padding-top: 0%; ">Mobile</label>
        <input type="text" class="form-control inputBackground">
      </div>
      <div class="w3-col l10 m9 s8" style="padding-left:5%">
        <br>
        <input type="text" class="form-control inputBackground">
      </div>
    </div>
    <div class="w3-row">
      <label>Description</label><br>
        <textarea name="" id="" cols="30" rows="8" class="form-control inputBackground"></textarea>
    </div>
    <div class="w3-row text-center" style="padding-top: 5%;">
      <button class="btn btn" style="background-color: #E26246; color: white;">Submit</button>
    </div>
  </form>
  </div>
   End Transparent Form-->
<!------------------------------------------------------------------------------------------------->
<div style="background-image: url(../../assets/Contact\ us-BG.png); width: 100%; background-position: center; 
  background-repeat: no-repeat; background-size: 100% 200%; margin-left: 0; margin-right: 0;">
  <div class="container">

    <div class="row">


      <!--Mobile Side-->
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
        <div>
          <div class="row2Col1Div3">
            <div class="text-center">
              <p class="row2Col1Para1" style="font-family: 'konsenssemiBold', sans-serif">Contact us</p>
              <hr style="margin-left: 37%; color: white;" width="17.5%">
              <hr style="margin-left: 49%; color: white;" width="17.5%">
            </div><br>
            <div style="">
              <p class="row2Col1Para2">Need assitance with MTacPro? We'll get you the help you need. Get in touch with
                us now !</p>
            </div>
          </div>
          <div>

            <img class="row2Col1Img" src="../../../assets/286 [Converted] copy.png" style="width: 90%;" alt="">
          </div>
        </div>
      </div>
      <!--MObile Side-->
      <!-- Start Transparent Form-->
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
        <div class="row2Col2" style="    
     ">
          <form class="row2Col2Form" [formGroup]="loginForm" (ngSubmit)="submitForm(loginForm.value)"
            style="background-color: #f9f9f933; border-radius: 12px;" novalidate>
            <div class="w3-row" style="margin-left: 0; margin-right: 0; padding-bottom: 10px;">
              <div class="w3-col l6 m6 s12 row2Col2FormFirstNameDiv">
                <label style="color: #E26246;">First Name<span> *</span></label>
                <input type="text" class="form-control inputBackground" formControlName="first">
                <div *ngIf="isSubmitClicked && loginFormControl.first.errors" class="alert-danger" >
                  <div *ngIf="loginFormControl.first.errors.required">
                    First Name is required.
                  </div>
                  <!--<div *ngIf="loginFormControl.first.errors.pattern">
                    Must be a valid email format.
                  </div>-->
                </div>
              </div>
              <div class="w3-col l6 m6 s12 row2Col2FormFirstNameDiv" style="padding-right: 0px;">
                <label style="color: #E26246;">Last Name</label>
                <input type="text" class="form-control inputBackground" formControlName="last">
              </div>
            </div>
            <div class="w3-row" style="margin-left: 0; margin-right: 0; padding-bottom: 10px;">
              <div class="w3-col l6 m6 s12 row2Col2FormFirstNameDiv">
                <label style="color: #E26246;">Email<span> *</span></label>
                <input type="text" class="form-control inputBackground" formControlName="email">
                <div *ngIf="isSubmitClicked && loginFormControl.email.errors" class="alert-danger">
                  <div *ngIf="loginFormControl.email.errors.required">
                    Email is required.
                  </div>
                  <div *ngIf="loginFormControl.email.errors.pattern">
                    Must be a valid email format.
                  </div>
                </div>
              </div>
              <div class="w3-col l6 m6 s12 row2Col2FormFirstNameDiv" style="padding-right: 0px;">
                <label style="color: #E26246;">Mobile Number</label>
                <input type="text" class="form-control inputBackground" formControlName="mobile">
                
              </div>
            </div>
            <div class="w3-row" style="margin-left: 0; margin-right: 0;padding-bottom: 10px;">
                <label style="color: #E26246;">Subject</label>
                <input type="text" class="form-control inputBackground " formControlName="subject">
               

            </div>
             <!--<div class="w3-row row2Col2FormFirstNameDiv"
              style="margin-left: 0; margin-right: 0; padding-right: 0px;padding-bottom: 10px;">
              <div class="w3-col l2 m3 s4">
                <label style="padding-bottom: 0%; padding-top: 0%; margin-bottom: 0px; color: #E26246;">Mobile</label>
                <input type="text" class="form-control inputBackground" placeholder="+1">-->
                <!--<select name="" id=""   style="background-color: #272130;width: 100%; color: white; border-radius: 4%; 
                        border-color: rgb(68, 66, 66); height: 34px;"><option value="+91" style="color: white;">+91</option></select>-->
               <!--</div>
              <div class="w3-col l10 m9 s8" style="padding-left:5%">
                <br>
                <input type="text" class="form-control inputBackground">
              </div>
            </div>-->
            <div class="w3-row" style="margin-left: 0; margin-right: 0;">
              <label style="color: #E26246;">Message<span> *</span></label><br>
              <textarea formControlName="message" name="" id="" cols="30" rows="8" class="form-control inputBackground"></textarea>
              <div *ngIf="isSubmitClicked && loginFormControl.message.errors" class="alert-danger" >
                <div *ngIf="loginFormControl.message.errors.required">
                  Message is required.
                </div>
                <!--<div *ngIf="loginFormControl.first.errors.pattern">
                  Must be a valid email format.
                </div>-->
              </div>
            </div>
            <div class="w3-row text-center" style="padding-top: 5%; margin-left: 0; margin-right: 0;">
              <button class="btn btn" style="background: linear-gradient(to right top, #E96443, #904965); color: white;" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
  <!--End Transparent Form-->
</div>
<!--------------------------------------------------------------------------------------------------->
<div class="w3-row"
  style="background-color: #2C2C2C; padding-top: 2%; padding-bottom: 1%;margin-left: 0; margin-right: 0;">
  <div class="w3-col l4 m4" style="color: white;padding-left: 5%;">
    <img style=" width: 150px; height: 67.58px;" src="../../../assets/designs/mtaclogo_FileQA (1).png" alt="">
    <p style="font-size: 14px; padding-top: 10px;">Take actions that make an impact. Providing services
      for all to grow and prosper.</p>
  </div>
  <div class="w3-col l4 m4" style=" color: white;padding-left: 5%;">
    <p style="font-size: 16px;">Contact <span style="color: #E26246;">us</span> </p>
    <p style="font-size: 14px;"> <img style="padding-right: 1%;" src="../assets/mail.png" alt=""> connect@manteio.in</p>
    <p style="font-size: 14px;"><img style="padding-right: 1%;" src="../assets/call.png" alt=""> +91 9391385170</p>
    <p style="font-size: 14px;"> <img style="padding-right: 2%;" src="../assets/pin.png" alt="">The Platina, B-605,
      Gachibowli, Telangana 500032</p>
  </div>
  <div class="w3-col l4 m4" style=" color: white;padding-left: 5%;">
    <p style="font-size: 16px; margin-bottom: 0px; margin-left: 50px;">Powered <span style="color: #E26246;">by</span></p>
    <img style="width: 222px; height: 59px;" src="../assets/Manteio logo.png" alt=""><br>
    <a href="https://www.facebook.com/Mtacpro/" target="_blank" style="padding-left: 14%;"><img src="../assets/facebook.png" alt=""></a>
    <a href="https://www.instagram.com/mtacpro/" target="_blank" style="padding-left: 2%;"><img src="../assets/instagram.png" alt=""></a>
    <a href="https://in.linkedin.com/company/manteioitsolutions" target="_blank" style="padding-left: 2%;"><img src="../assets/linkedin.png" alt=""></a>
    <a href="https://mobile.twitter.com/manteio_it"  target="_blank" style="padding-left: 2%;"><img src="../assets/twitter.png" alt=""></a>
    <a href="https://www.youtube.com/channel/UC7E1pqLn5KASrzgSLY4xAPQ" target="_blank" style="padding-left: 2%;"><img src="../assets/youtube.png" alt=""></a>
  </div>
</div>
<div style="background-color: #202020;height: 28px;">
  <p class="text-center" style="font-size: 9px; color: white; padding-top: 0.5%;"> © Copyright 2020. All rights
    reserved.</p>
</div>


<!-- 

<div class="design4 ABC">
  <img src="../../../assets/designs/Design 4.png" alt="" class="design4Img">
</div>
 -->
<!--design 03-->
<div class="design03 ABC">
  <img src="../../../assets/designs/Design 3.png" alt="">
</div>
<!---->
<!--design 05-->
<div class="design05">
  <img src="../../assets/designs/Design 05.png" alt="">
</div>
<!---->

<!--circle images on mobile-->

<marquee class=" w3-hide-large" style="z-index:2;position:absolute;left:88%;top:57px;font-family:Cursive;
font-size:14pt;color:#ffcc00;height:90px; overflow: hidden; width: 12%;" scrollamount="3" direction="down"> <img
    src="../../assets/Design 8.png" alt="" style="width: 80%;"></marquee>
<marquee class=" w3-hide-large" style="z-index:2;position:absolute;left:80%;top:57px;font-family:Cursive;
font-size:14pt;color:#ffcc00;height:80px; overflow: hidden; width: 12%;" scrollamount="4" direction="down"><img
    src="../../assets/Design 9.png" alt="" style="width: 80%;"></marquee>
<marquee class=" w3-hide-large" style="z-index:2;position:absolute;left:70%;top:57px;font-family:Cursive;
font-size:14pt;color:#ffcc00;height:80px; overflow: hidden; width:12%;" scrollamount="5" direction="down"> <img
    src="../../assets/Design 10.png" alt="" style="width: 80%;"></marquee>
<marquee class="  w3-hide-large" style="z-index:2;position:absolute;left:60%;top:57px;font-family:Cursive;
font-size:14pt;color:#ffcc00;height:118px; overflow: hidden; width: 12%;" scrollamount="3" direction="down"> <img
    src="../../assets/Desgin 6.png" alt="" style="width: 80%;"></marquee>
<marquee class="  w3-hide-large" style="z-index:2;position:absolute;left: 50%;top:57px;font-family:Cursive;
font-size:14pt;color:#ffcc00;height:124px; overflow: hidden; width: 12%;" scrollamount="2" direction="down"><img
    src="../../assets/Design 7.png" alt="" style="width: 80%;"></marquee>


<!--design 11-->
<div class="design11">
  <img src="../../../assets/designs/Design 11.png" alt="">
</div>
<!---->
<!--design 12-->
<div class="design12">
  <img src="../../../assets/designs/Design 12.png" alt="">
</div>
<!---->
<!--design 13-->
<div class="design13">
  <img src="../../../assets/designs/Design 13.png" alt="">
</div>
<!---->
<!--design 14-->
<div class="design14">
  <img src="../../../assets/designs/Design 14.png" alt="">
</div>
<!---->
<!--design 16-->
<div class="design16">
  <img src="../../../assets/designs/Design 16.png" alt="">
</div>
<!---->


<!--design 01-->
<div class="design01">
  <img src="../../../assets/designs/Design 01.png" alt="" class="design01Img">
</div>
<!---->
<!--design 4
<style>
.ABC{
    animation-name: rotate;
animation-duration: 10s;
animation-direction: reverse;
}
@keyframes rotate {
0% {
transform: rotate(0);
}
100% {
transform: rotate(360deg);
}
}
</style>-->