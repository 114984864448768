import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { GetappointmentsService } from 'src/getappointments.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
 public getUsersByClient :any=[]


  constructor(private user:GetappointmentsService) { }

  ngOnInit(): void {

    $(function(){
      var current = location.pathname;
      $('#nav li a').each(function(){
          var $this = $(this);
          // if the current path is like this link, make it active
          if($this.attr('routerLink').indexOf(current) !== -1){
              $this.addClass('active');
          }
      })

      
  })
 
  }
 
}
