import { Component, OnInit } from '@angular/core';
import {ReviewsService} from '../../../reviews.service';
import { take } from 'rxjs/operators';
import{ ReviewsData} from './ReviewData'
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2';
import {GetappointmentsService} from '../../../getappointments.service'
@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit {
  private clickedReview: any = {};
  public statusChangeAction: string;
  rating=0;
  public dtOptions: any = {};
  public dtOptionsOthers: any = {};
public showTable: boolean = false;
startDate:string;
endDate:string;
reviewFilter1: string;
reviewFilter2: string;
public ReviewData: ReviewsData = {
  reviewTotalCount: 0,
  reviewFiveCount: 0,
  reviewFourCount: 0,
  reviewThreeCount: 0,
  revieTwoCount: 0,
  reviewOneCount: 0,
  recommedationCount: 0,
  ratingAverage:0,
  allReviews:[],
  fbReviews:[],
  googleReviews:[],
  mTacReviews:[],
  otherReviews:[],
  twitterReviews:[],
  yahooReviews:[],
  yelpReviews:[]
};
  clientId: string;
  constructor(private user:ReviewsService,private SpinnerService: NgxSpinnerService,private app:GetappointmentsService) {

   }
   showShortDesciption = true
   alterDescriptionText() {
      this.showShortDesciption = !this.showShortDesciption
   }
  public ngOnInit(): void {
 //   this.user.getClientId();
    this.clientId  = localStorage.getItem("ClientId");
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [ 5, 10, 25, 50, 100],
      processing: true,
      dom:
      "<'row'<'col-sm-6'i><'col-sm-6'f>>" +
    "<'row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-4'l><'col-sm-8'p>>" +
    "<'row'<'col-sm-6'B>>",
      buttons: [
           'excel', 'print',
      {
        extend:'pdfHtml5',
        text:'Pdf',
        orientation:'landscape',
        
    }],
    
  
      columnDefs: [{
        targets: [5],
        createdCell: function(cell) {
          var $cell = $(cell);


          $(cell).contents().wrapAll("<div class='content'></div>");
          var $content = $cell.find(".content");

          $(cell).append($("<button >Read more</button>"));
          const $btn = $(cell).find("button");

          $content.css({
            "height": "46px",
            "overflow": "hidden"
          })
          $btn.css({
            "height": "20px",
            "font-size": "11px",
            "border": "none",
            "background": "#211E50",
            "color":"#fff",
            "float": "right",
            "margin-top": "5px",

          })
          $cell.data("isLess", true);

          $btn.click(function() {
            var isLess = $cell.data("isLess");
            $content.css("height", isLess ? "auto" : "46px")
            $(this).text(isLess ? "Read less" : "Read more")
            $cell.data("isLess", !isLess)
          })
        }
      }]
    };


    this.dtOptionsOthers = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [ 5, 10, 25, 50, 100],
      processing: true,
      dom:
      "<'row'<'col-sm-6'i><'col-sm-6'f>>" +
    "<'row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-4'l><'col-sm-8'p>>" +
    "<'row'<'col-sm-6'B>>",
    buttons: [
      'excel', 'print',
 {
   extend:'pdfHtml5',
   text:'Pdf',
   orientation:'landscape',
   
}],
      columnDefs: [{
        targets: [6],
        createdCell: function(cell) {
          var $cell = $(cell);


          $(cell).contents().wrapAll("<div class='content'></div>");
          var $content = $cell.find(".content");

          $(cell).append($("<button >Read more</button>"));
          const $btn = $(cell).find("button");

          $content.css({
            "height": "46px",
            "overflow": "hidden"
          })
          $btn.css({
            "height": "20px",
            "font-size": "11px",
            "border": "none",
            "background": "#211E50",
            "color":"#fff",
            "float": "right",
            "margin-top": "5px",

          })
          $cell.data("isLess", true);

          $btn.click(function() {
            var isLess = $cell.data("isLess");
            $content.css("height", isLess ? "auto" : "46px")
            $(this).text(isLess ? "Read less" : "Read more")
            $cell.data("isLess", !isLess)
          })
        }
      }]
    };
    this.getReviews();
  }
  getStartDate(startDate :string,)
     {
       this.startDate=startDate;
     }
   
     getEndDate(endDate :string,)
     {
       this.endDate=endDate; 
     }
     getFilterdData( )
     {
       this.SpinnerService.show();
       this.showTable=false;
       this.user
       .FilteredReviews(this.startDate,this.endDate)
       .pipe(take(1))
       .subscribe((data: any) => {
         this.showTable=true;
         this.reviewFilter1="hide";
      this.reviewFilter2="show";
         this.ReviewData=data;
       
         this.SpinnerService.hide();
         console.log(data);
       });
     }
  private getReviews(): void{
this.SpinnerService.show();
this.showTable = false;
    this.user.getReviews().pipe(take(1)).subscribe((data:any) =>{
      console.warn(data);
      this.ReviewData=data;
     
      this.reviewFilter1="show";
      this.reviewFilter2="hide";
      this.showTable = true;
      console.log(data);
      this.SpinnerService.hide();
    })
  }
 //get back to review from filters 
 reviewClearFilter()
 {
  this.reviewFilter1="show";
      this.reviewFilter2="hide";
     (<any>$("#demo-7")).modal('hide');
   this.getReviews();
 }
  public statusClick( review: any): void {
    this.clickedReview = review;
    this.statusChangeAction = review.reviewStatus ? 'De-activate' : 'Activate';
    // this.modalService.open(modalContent, { backdrop: 'static', keyboard: false });
  }
 
  public changeStatus(): void {
   
  
    this.user.changeUserStatus(this.clickedReview.reviewId).pipe(take(1)).subscribe((res: any) => {
      if (res.statusCode === 1) {
        this.clickedReview.reviewStatus = !this.clickedReview.reviewStatus;
        swal.fire(
          'Success',
          'Review '+this.statusChangeAction+' Succesfull.',
        'success'
       );
      }
      else{
        if (res.statusCode===2){
          swal.fire(
            'Cancelled!',
            'Review Not Found',
            'warning'
          );
        }
        //  window.alert('This Email already Registred');
      }
     
    });
  }

  public getSocialMediaImg(mediaId: number): string{
    switch(mediaId) {
      case 1: {
        return 'http://mtacpro.com/assets/images/logo-crop.png'
        break;

      }
      case 2: {
        return 'https://www.facebook.com/images/fb_icon_325x325.png'

        break;

      }
      case 3: {
        return 'https://logosvector.net/wp-content/uploads/2015/09/google-plus-new-icon-logo.png'
        break;

      }
       case 4: {
       return 'https://cdn2.iconfinder.com/data/icons/metro-uinvert-dock/256/Twitter_NEW.png'
         break;

       }
      case 5: {
        return 'https://cdn.iconscout.com/icon/premium/png-256-thumb/review-59-918101.png'
       
        break;

      }
      case 6: {
        return 'https://png.pngtree.com/png-clipart/20190613/original/pngtree-yahoo-purple-icon-png-image_3591532.jpg'

        break;
    }
    
    case 7: {
      return 'https://cdn3.iconfinder.com/data/icons/popular-services-brands/512/yelp-512.png'

      break;
  }
      }
    }
   
  public getSocialMediaName(mediaId: number): string{
    switch(mediaId) {
      case 1: {
        return 'MTac'
        break;

      }
      case 2: {
        return 'Facebook'

        break;

      }
      case 3: {
        return 'Google'
        break;

      }
      case 4: {
        return 'Twitter'
        break;

      }
      case 5: {
        return 'Others'
        break;

      }
      case 6: {
        return 'Yahoo'
        break;

      }
      case 7: {
        return 'Yelp'
        break;

      }
    }

      }
      // public ReviewStatus(reviewStatus:boolean) :string{
      //   if(reviewStatus===true)
      //   {
      //        return "Enabled";
      //   }
      //   else{
      //     return "Disabled";
      //   }
      // }
}


//'copy', 'csv', 'excel', 'pdf', 'print'
