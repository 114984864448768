import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../../app/services/authentication.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  [x: string]: any;
  LoginData = [];
 public hitechlogo :boolean=false
 public  hitechName : boolean =false
  user =  this.AuthenticationService;
  constructor(private AuthenticationService: AuthenticationService) {
    this.AuthenticationService.currentUserEmail;
    this.AuthenticationService.currentUserName;
  }

  ngOnInit(): void {
  this.UserName  =localStorage.getItem("usernamek");
  if(this.UserName.toLowerCase()=="hi-tech")
  {
    this.hitechlogo=true;
    this.hitechName=false;
  }
  else{
    this.hitechlogo=false;
    this.hitechName=true;
  }
    $(function () {
      (<any>$(window)).bind("beforeunload", function () {
        this.logout();

      })
  });
  }

  logout() {
    this.AuthenticationService.logout();
}
}
