import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from '../app/login/login.component';
import{RecoverypasswordComponent} from '../app/recoverypassword/recoverypassword.component';
import{WelcomepasswordComponent} from '../app/welcomepassword/welcomepassword.component';
import{DashboardComponent} from '../app/dashboard/dashboard.component';
import {AppointmentsComponent} from '../app/components/appointments/appointments.component';
import {ReviewsComponent} from '../app/components/reviews/reviews.component';
import {RemindersComponent} from '../app/components/reminders/reminders.component';
import {CustomersComponent} from '../app/components/customers/customers.component';
import {DashboardhomeComponent} from '../app/components/dashboardhome/dashboardhome.component';
import {ReportsComponent} from '../app/components/reports/reports.component';
import {SettingsComponent} from '../app/components/settings/settings.component';
import {MtacComponent} from '../app/components/mtac/mtac.component'
import { AuthGuard } from './guard/AuthGuard';
import {AllreviewsComponent} from '../app/components/allreviews/allreviews.component';
import{ CustomerRequestsComponent} from '../app/components/customer-requests/customer-requests.component'
import{CarInspectionComponent} from '../app/components/car-inspection/car-inspection.component'
import { from } from 'rxjs';
import { IndustriesComponent } from './components/industries/industries.component';
import { ContactusComponent } from './components/contactus/contactus.component';
const routes: Routes = [
  {
    path: "dashboard",
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
        { path: '', component: DashboardhomeComponent},
        // { path: 'app-dashboardhome', component: DashboardhomeComponent},
        // { path: 'app-appointments', component: AppointmentsComponent},
        // { path: "reviews", component: ReviewsComponent},
        // { path: 'app-Reminders', component: RemindersComponent},
        // { path: 'app-Reports', component: ReportsComponent},
        // { path: 'app-Customers', component: CustomersComponent},
        // { path: 'app-Customersrequests', component: CustomerRequestsComponent},
        // { path: 'app-Settings', component: SettingsComponent},
      ],
  },
  { path: "", component: MtacComponent},
  {path:'industries', component:IndustriesComponent},
  {path:'contactus', component:ContactusComponent},
  {path:'mtac', component:MtacComponent},
  { path: 'dashboardhome',canActivate: [AuthGuard], component: DashboardhomeComponent},
  { path: 'appointments',canActivate: [AuthGuard], component: AppointmentsComponent},
  { path: "reviews",canActivate: [AuthGuard], component: ReviewsComponent},
  { path: 'Reminders',canActivate: [AuthGuard], component: RemindersComponent},
  { path: 'Reports',canActivate: [AuthGuard], component: ReportsComponent},
  { path: 'Customers',canActivate: [AuthGuard], component: CustomersComponent},
  { path: 'Customersrequests',canActivate: [AuthGuard], component: CustomerRequestsComponent},
  { path: 'Settings',canActivate: [AuthGuard], component: SettingsComponent},
  { path: "mtac", component: MtacComponent},
  { path: "login",canActivate: [AuthGuard], component: LoginComponent },
  { path: "allreviews", component: AllreviewsComponent },
  { path: "recoverypassword", component: RecoverypasswordComponent },
  { path: "welcomepassword", component: WelcomepasswordComponent },
  { path: "inspection", component: CarInspectionComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration:'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


// children: [}
//   { path: '', component: DashboardhomeComponent},
//   { path: 'app-dashboardhome', component: DashboardhomeComponent},
//   { path: 'app-appointments', component: AppointmentsComponent},
//   { path: 'app-reviews', component: ReviewsComponent},
//   { path: 'app-Reminders', component: RemindersComponent},
//   { path: 'app-Reports', component: ReportsComponent},
//   { path: 'app-Customers', component: CustomersComponent},
//   { path: 'app-Settings', component: SettingsComponent},
// ],
// component: DashboardComponent
