
        <div  class="sidebar-offcanvas bg-light pl-0 sidebarnavs_width" id="sidebar" role="navigation">
            <ul class="nav flex-column sticky-top pl-0  sidebarnavs">
                <li  class="nav-item"><a class="nav-link sidea" routerLink="/dashboardhome" [routerLinkActive]="'active'">
                  <span><i class="fa fa-th-large" aria-hidden="true"></i></span>Dashboard</a></li>
                <li  class="nav-item"><a class="nav-link sidea" routerLink="/appointments" [routerLinkActive]="'active'">
                  <span><i class="fa fa-database" aria-hidden="true"></i></span>Appointments</a></li>

                  <!-- <li class="nav-item"><a class="nav-link sidea" routerLink="/inspection" [routerLinkActive]="'active'">
                    <span><i class="fa fa-database" aria-hidden="true"></i></span> NC Inspection</a></li> -->
                  
                <li  class="nav-item"><a class="nav-link sidea" routerLink="/reviews" [routerLinkActive]="'active'">
                  <span><i class="fa fa-calendar" aria-hidden="true"></i></span>Reviews</a></li>
                <li  class="nav-item"><a class="nav-link sidea" routerLink="/Reminders" [routerLinkActive]="'active'">

                  <span><i class="fa fa-bell" aria-hidden="true"></i></span>Reminders</a></li>
                <li class="nav-item"><a class="nav-link sidea" routerLink="/Customers" [routerLinkActive]="'active'">
                  <span><i class="fa fa-users" aria-hidden="true"></i></span>Customers</a></li>

                  <li  class="nav-item"><a class="nav-link sidea" routerLink="/Customersrequests" [routerLinkActive]="'active'">
                    <span><i class="fa fa-phone" aria-hidden="true"></i></span> Enquires</a></li>
                <li  class="nav-item"><a class="nav-link sidea" routerLink="/Reports" [routerLinkActive]="'active'">
                  <span><i class="fa fa-line-chart" aria-hidden="true"></i></span>Reports</a></li>
                <li  class="nav-item"><a class="nav-link sidea" routerLink="/Settings" [routerLinkActive]="'active'">
                  <span><i class="fa fa-cog" aria-hidden="true"></i></span> Settings</a></li>
            </ul>
        </div>

