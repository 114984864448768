<app-navbar></app-navbar>
<div class="row mainrow_margin">
   <div class="col-md-2">
    <app-sidenav></app-sidenav>
   </div>
   <div class="col-md-10">
<div class="row" style="margin-top:3%;">
  <div class="col-md-4">
    <h4 style="margin-left: 20px;font-weight: 600;
    font-size: 22px;
    font-family: 'Poppins';">Customer Reviews</h4>
  </div>
  <div class="col-md-4">

  </div>
  <div class="col-md-4">
    <button   *ngIf="reviewFilter1 == 'show'" type="button" class="btn" style="width: 150px;
    border: #211e50;
    margin-left: 165px;
    width: 115px;
    border: 1px solid #6f09e8;
    color: #343a40;" data-toggle="modal" data-target="#demo-7">Filter <i style="color: #6f09e8;
      font-size: 20px;
      margin-left: 5px;" class="fa fa-filter" aria-hidden="true"></i></button>

  </div>
<div>
  <button type="button"  (click)= reviewClearFilter() *ngIf="reviewFilter2 == 'show'" class="btn " style="width: 150px;border:#211e50;margin-left: 56rem;  width: 150px;
  border: 1px solid #6f09e8;
  color: #343a40;margin-bottom: 10px;" >Clear Filter <i style="color: #6f09e8;
font-size: 20px;
margin-left: 5px;" class="fa fa-filter" aria-hidden="true"></i></button>
</div>
</div>

<!-- <div class="row" style="margin-left: 2%;">
  <button class="btn btnmtac" routerLinkActive="mat-accent">MTac</button>
  <button class="btn btnmtac">Others</button>
</div> -->
<div class="row" style="margin-left: 2%;">
 <div class="col-md-6">

 </div>
 <div class="col-md-6">

</div>
</div>
<div class="container" style="margin-top:20px;margin-left:-10px;">

<div>
  <div class="container">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item btnmtac">
        <a class="nav-link active" data-toggle="tab" href="#mtac">MTac</a>
      </li>
      <li class="nav-item btnmtac">
        <a class="nav-link" data-toggle="tab" href="#Facebook">Facebook</a>
      </li>
      <li class="nav-item btnmtac">
        <a class="nav-link" data-toggle="tab" href="#Twitter">Twitter</a>
      </li>
      <li class="nav-item btnmtac">
        <a class="nav-link" data-toggle="tab" href="#Google">Google</a>
      </li>
      <li class="nav-item btnmtac">
        <a class="nav-link" data-toggle="tab" href="#yelp">Yelp</a>
      </li>
      <li class="nav-item btnmtac">
        <a class="nav-link" data-toggle="tab" href="#yahoo">Yahoo</a>
      </li>
      <li class="nav-item btnmtac">
        <a class="nav-link" data-toggle="tab" href="#Allreviews">All Reviews</a>
      </li>
      <li class="nav-item btnmtac">
        <a class="nav-link" data-toggle="tab" href="#Others">Others</a>
      </li>
    </ul>

    <!-- Tab panes -->
    <div class="tab-content">
      <div id="mtac" class="container tab-pane active" style="margin-left: -10px;"><br>

        <div class="table-responsive">
          <table class="table table-striped table-bordered" *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="width:100%;font-size: 13.5px;">
            <thead class="thead-dark">
              <tr>
                <th style="width: 100px!important;">Date</th>
                <th>Name</th>
                <!-- <th>phoneNo</th> -->
                <th>Email</th>
                <th>Rating</th>
                <th>Review</th>
                <th>Comment</th>
                <th style="width: 120px;"> Status <br><small style="color: #5cb85c ;">Enable </small>/<small style="color: #d9534f;"> Disable</small></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor= "let ReviewDatas of ReviewData.mTacReviews">
                <td>{{ReviewDatas.dateofService | date:'MM-dd-yyyy'}}</td>
                <td>{{ReviewDatas.firstName}} {{ReviewDatas.lastName}}</td>
                <!-- <td>{{ReviewDatas.phone}}</td> -->
                <td>{{ReviewDatas.email}}</td>
                <td><ngb-rating [max]="5" [(rate)]="ReviewDatas.rating" [readonly]="true"></ngb-rating></td>
                <!-- <td>{{ReviewDatas.rating}} </td> -->
                <td style="width:250px;">{{ReviewDatas.review}}</td>
                <td style="width:250px;"> {{ReviewDatas.comment}}</td>
                <!-- <td style="width:250px;">
                  <div [ngClass]="{'show-less': showShortDesciption}">
                    {{ReviewDatas.comment}}
                  </div>
                 <button type="button" class="readmore_button" (click)="alterDescriptionText(ReviewDatas.reviewId)">
                  {{ showShortDesciption ? 'Read more': 'SHOW LESS' }}
                </button></td> -->
                <!-- <td>{{ReviewStatus(ReviewDatas.reviewStatus)}}</td> -->
                <td style="text-align: center;">
                  <i class="fa fa-toggle-on fa-2x" data-toggle="modal" data-target=".bd-example-modal-lg5" [ngClass]="ReviewDatas.reviewStatus?'active':'fa-rotate-180 inactive'"
                      (click)="statusClick(ReviewDatas)"></i>
              </td>
                <!-- <td>{{ReviewDatas.recommendation}}</td> <td>{{ReviewDatas.vechileNumber}}</td> -->
               </tr>
            </tbody>
          </table>
    </div>
        </div>

      <div id="Facebook" class="container tab-pane fade" style="margin-left: -10px;"><br>
        <div class="table-responsive">
          <table class="table table-striped table-bordered" *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="width:100%; font-size: 13.5px;">
            <thead class="thead-dark">
              <tr>
                <th style="width: 100px;">Date</th>
                <th>Name</th>
                <!-- <th>phoneNo</th> -->
                <th>Email</th>
                <th>Rating</th>
                <th>Review</th>
                <th>Comment</th>
                <th style="width: 120px;"> Status <br><small style="color: #5cb85c ;">Enable </small>/<small style="color: #d9534f;"> Disable</small></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor= "let ReviewDatas of ReviewData.fbReviews">
                <td>{{ReviewDatas.dateofService | date:'MM-dd-yyyy'}}</td>
                <td>{{ReviewDatas.firstName}} {{ReviewDatas.lastName}}</td>
                <!-- <td>{{ReviewDatas.phone}}</td> -->
                <td>{{ReviewDatas.email}}</td>
                <td><ngb-rating [max]="5" [(rate)]="ReviewDatas.rating" [readonly]="true"></ngb-rating></td>
                <!-- <td>{{ReviewDatas.rating}} </td> -->
                <td style="width:250px;">{{ReviewDatas.review}}</td>
                <td style="width:250px;">
                    {{ReviewDatas.comment}}
               </td>
                <!-- <td>{{ReviewStatus(ReviewDatas.reviewStatus)}}</td> -->
                <!-- <td>{{ReviewDatas.recommendation}}</td> <td>{{ReviewDatas.vechileNumber}}</td> -->
                <td  style="text-align: center;">
                  <i class="fa fa-toggle-on fa-2x" data-toggle="modal" data-target=".bd-example-modal-lg5" [ngClass]="ReviewDatas.reviewStatus?'active':'fa-rotate-180 inactive'"
                      (click)="statusClick(ReviewDatas)"></i>
              </td>
               </tr>
            </tbody>
          </table>
    </div>
         </div>
         <div id="Twitter" class="container tab-pane fade" style="margin-left: -10px;"><br>
          <div class="table-responsive">
            <table class="table table-striped table-bordered" *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="width:100%; font-size: 13.5px;">
              <thead class="thead-dark">
                <tr>
                  <th style="width: 100px;">Date</th>
                  <th>Name</th>
                  <!-- <th>phoneNo</th> -->
                  <th>Email</th>
                  <th>Rating</th>
                  <th>Review</th>
                  <th>Comment</th>
                  <th style="width: 120px;"> Status <br><small style="color: #5cb85c ;">Enable </small>/<small style="color: #d9534f;"> Disable</small></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor= "let ReviewDatas of ReviewData.twitterReviews">
                  <td>{{ReviewDatas.dateofService | date:'MM-dd-yyyy'}}</td>
                  <td>{{ReviewDatas.firstName}} {{ReviewDatas.lastName}}</td>
                  <!-- <td>{{ReviewDatas.phone}}</td> -->
                  <td>{{ReviewDatas.email}}</td>
                  <td><ngb-rating [max]="5" [(rate)]="ReviewDatas.rating" [readonly]="true"></ngb-rating></td>
                  <!-- <td>{{ReviewDatas.rating}} </td> -->
                  <td style="width:250px;">{{ReviewDatas.review}}</td>
                  <td style="width:250px;">
                      {{ReviewDatas.comment}}
                 </td>
                  <!-- <td>{{ReviewStatus(ReviewDatas.reviewStatus)}}</td> -->
                  <!-- <td>{{ReviewDatas.recommendation}}</td> <td>{{ReviewDatas.vechileNumber}}</td> -->
                  <td  style="text-align: center;">
                    <i class="fa fa-toggle-on fa-2x" data-toggle="modal" data-target=".bd-example-modal-lg5" [ngClass]="ReviewDatas.reviewStatus?'active':'fa-rotate-180 inactive'"
                        (click)="statusClick(ReviewDatas)"></i>
                </td>
                 </tr>
              </tbody>
            </table>
      </div>
           </div>
         <div id="Google" class="container tab-pane fade" style="margin-left: -10px;"><br>
          <div class="table-responsive">
            <table class="table table-striped table-bordered" *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="width:100%;  font-size: 13.5px;">
              <thead class="thead-dark">
                <tr>
                  <th style="width: 100px;">Date</th>
                  <th>Name</th>
                  <!-- <th>phoneNo</th> -->
                  <th>Email</th>
                  <th>Rating</th>
                  <th>Review</th>
                  <th>Comment</th>
                  <th style="width: 120px;"> Status <br><small style="color: #5cb85c ;">Enable </small>/<small style="color: #d9534f;"> Disable</small></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor= "let ReviewDatas of ReviewData.googleReviews">
                  <td>{{ReviewDatas.dateofService | date:'MM-dd-yyyy'}}</td>
                  <td>{{ReviewDatas.firstName}} {{ReviewDatas.lastName}}</td>
                  <!-- <td>{{ReviewDatas.phone}}</td> -->
                  <td>{{ReviewDatas.email}}</td>
                  <td><ngb-rating [max]="5" [(rate)]="ReviewDatas.rating" [readonly]="true"></ngb-rating></td>
                  <!-- <td>{{ReviewDatas.rating}} </td> -->
                  <td style="width:250px;">{{ReviewDatas.review}}</td>
                  <td style="width:250px;">
                      {{ReviewDatas.comment}}
                </td>
                <td  style="text-align: center;">
                  <i class="fa fa-toggle-on fa-2x" data-toggle="modal" data-target=".bd-example-modal-lg5" [ngClass]="ReviewDatas.reviewStatus?'active':'fa-rotate-180 inactive'"
                      (click)="statusClick(ReviewDatas)"></i>
              </td>
                  <!-- <td>{{ReviewStatus(ReviewDatas.reviewStatus)}}</td> -->
                  <!-- <td>{{ReviewDatas.recommendation}}</td> <td>{{ReviewDatas.vechileNumber}}</td> -->
                 </tr>
              </tbody>
            </table>
      </div>
           </div>
           <div id="yelp" class="container tab-pane fade" style="margin-left: -10px;"><br>
            <div class="table-responsive">
              <table class="table table-striped table-bordered" *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="width:100%; font-size: 13.5px;">
                <thead class="thead-dark">
                  <tr>
                    <th style="width: 100px;">Date</th>
                    <th>Name</th>
                    <!-- <th>phoneNo</th> -->
                    <th>Email</th>
                    <th>Rating</th>
                    <th>Review</th>
                    <th>Comment</th>
                    <th style="width: 120px;"> Status <br><small style="color: #5cb85c ;">Enable </small>/<small style="color: #d9534f;"> Disable</small></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor= "let ReviewDatas of ReviewData.yelpReviews">
                    <td>{{ReviewDatas.dateofService | date:'MM-dd-yyyy'}}</td>
                    <td>{{ReviewDatas.firstName}} {{ReviewDatas.lastName}}</td>
                    <!-- <td>{{ReviewDatas.phone}}</td> -->
                    <td>{{ReviewDatas.email}}</td>
                    <td><ngb-rating [max]="5" [(rate)]="ReviewDatas.rating" [readonly]="true"></ngb-rating></td>
                    <!-- <td>{{ReviewDatas.rating}} </td> -->
                    <td style="width:250px;">{{ReviewDatas.review}}</td>
                    <td style="width:250px;">
                        {{ReviewDatas.comment}}
                   </td>
                    <!-- <td>{{ReviewStatus(ReviewDatas.reviewStatus)}}</td> -->
                    <!-- <td>{{ReviewDatas.recommendation}}</td> <td>{{ReviewDatas.vechileNumber}}</td> -->
                    <td  style="text-align: center;">
                      <i class="fa fa-toggle-on fa-2x" data-toggle="modal" data-target=".bd-example-modal-lg5" [ngClass]="ReviewDatas.reviewStatus?'active':'fa-rotate-180 inactive'"
                          (click)="statusClick(ReviewDatas)"></i>
                  </td>
                   </tr>
                </tbody>
              </table>
        </div>
             </div>

             <div id="yahoo" class="container tab-pane fade" style="margin-left: -10px;"><br>
              <div class="table-responsive">
                <table class="table table-striped table-bordered" *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="width:100%; font-size: 13.5px;">
                  <thead class="thead-dark">
                    <tr>
                      <th style="width: 100px;">Date</th>
                      <th>Name</th>
                      <!-- <th>phoneNo</th> -->
                      <th>Email</th>
                      <th>Rating</th>
                      <th>Review</th>
                      <th>Comment</th>
                      <th style="width: 120px;"> Status <br><small style="color: #5cb85c ;">Enable </small>/<small style="color: #d9534f;"> Disable</small></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor= "let ReviewDatas of ReviewData.yahooReviews">
                      <td>{{ReviewDatas.dateofService | date:'MM-dd-yyyy'}}</td>
                      <td>{{ReviewDatas.firstName}} {{ReviewDatas.lastName}}</td>
                      <!-- <td>{{ReviewDatas.phone}}</td> -->
                      <td>{{ReviewDatas.email}}</td>
                      <td><ngb-rating [max]="5" [(rate)]="ReviewDatas.rating" [readonly]="true"></ngb-rating></td>
                      <!-- <td>{{ReviewDatas.rating}} </td> -->
                      <td style="width:250px;">{{ReviewDatas.review}}</td>
                      <td style="width:250px;">
                          {{ReviewDatas.comment}}
                     </td>
                      <!-- <td>{{ReviewStatus(ReviewDatas.reviewStatus)}}</td> -->
                      <!-- <td>{{ReviewDatas.recommendation}}</td> <td>{{ReviewDatas.vechileNumber}}</td> -->
                      <td  style="text-align: center;">
                        <i class="fa fa-toggle-on fa-2x" data-toggle="modal" data-target=".bd-example-modal-lg5" [ngClass]="ReviewDatas.reviewStatus?'active':'fa-rotate-180 inactive'"
                            (click)="statusClick(ReviewDatas)"></i>
                    </td>
                     </tr>
                  </tbody>
                </table>
          </div>
               </div>
      <div id="Others" class="container tab-pane fade" style="margin-left: -10px;"><br>

<div class="table-responsive">
  <table class="table table-striped table-bordered" *ngIf="showTable" datatable [dtOptions]="dtOptionsOthers"  style="width:100%; font-size: 13.5px;">
    <thead class="thead-dark">
      <tr>
        <th>Source</th>
        <th style="width: 100px;">Date</th>
        <th>Name</th>
        <!-- <th>phoneNo</th> -->
        <th>Email</th>
        <th>Rating</th>
        <th>Review</th>
        <th>Comment</th>
        <th style="width: 120px;"> Status <br><small style="color: #5cb85c ;">Enable </small>/<small style="color: #d9534f;"> Disable</small></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor= "let ReviewDatas of ReviewData.otherReviews">
        <td><img height="50px" width="50px" src="{{getSocialMediaImg(ReviewDatas.sourceId)}}"/>  {{ getSocialMediaName(ReviewDatas.sourceId)}} </td>

        <td>{{ReviewDatas.dateofService | date:'MM-dd-yyyy'}}</td>
        <td>{{ReviewDatas.firstName}} {{ReviewDatas.lastName}}</td>
        <!-- <td>{{ReviewDatas.phone}}</td> -->
        <td>{{ReviewDatas.email}}</td>
        <td><ngb-rating [max]="5" [(rate)]="ReviewDatas.rating" [readonly]="true"></ngb-rating></td>
        <!-- <td>{{ReviewDatas.rating}} </td> -->
        <td style="width:250px;">{{ReviewDatas.review}}</td>
        <td style="width:250px;"> {{ReviewDatas.comment}} </td>
        <!-- <td>{{ReviewStatus(ReviewDatas.reviewStatus)}}</td> -->
        <td  style="text-align: center;">
          <i class="fa fa-toggle-on fa-2x" data-toggle="modal" data-target=".bd-example-modal-lg5" [ngClass]="ReviewDatas.reviewStatus?'active':'fa-rotate-180 inactive'"
              (click)="statusClick(ReviewDatas)"></i>
      </td>
        <!-- <td>{{ReviewDatas.recommendation}}</td> <td>{{ReviewDatas.vechileNumber}}</td> -->
       </tr>
    </tbody>
  </table>
</div>
        </div>

        <div id="Allreviews" class="container tab-pane fade" style="margin-left: -10px;">
          <div class="table-responsive">
            <table class="table table-striped table-bordered" *ngIf="showTable" datatable [dtOptions]="dtOptionsOthers"  style="width:100%; font-size: 13.5px;">
              <thead class="thead-dark">
                <tr>
                  <th>Source</th>
                  <th style="width: 100px;">Date</th>
                  <th>Name</th>
                  <!-- <th>phoneNo</th> -->
                  <th>Email</th>
                  <th>Rating</th>
                  <th>Review</th>
                  <th>Comment</th>
                  <th style="width: 120px;"> Status <br><small style="color: #5cb85c ;">Enable </small>/<small style="color: #d9534f;"> Disable</small></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor= "let ReviewDatas of ReviewData.allReviews">
                  <td><img height="50px" width="50px" src="{{getSocialMediaImg(ReviewDatas.sourceId)}}"/>  {{ getSocialMediaName(ReviewDatas.sourceId)}} </td>

                  <td>{{ReviewDatas.dateofService | date:'MM-dd-yyyy'}}</td>
                  <td>{{ReviewDatas.firstName}} {{ReviewDatas.lastName}}</td>
                  <!-- <td>{{ReviewDatas.phone}}</td> -->
                  <td>{{ReviewDatas.email}}</td>
                  <td><ngb-rating [max]="5" [(rate)]="ReviewDatas.rating" [readonly]="true"></ngb-rating></td>
                  <!-- <td>{{ReviewDatas.rating}} </td> -->
                  <td style="width:250px;">{{ReviewDatas.review}}</td>
                  <td style="width:250px;"> {{ReviewDatas.comment}} </td>
                  <!-- <td>{{ReviewStatus(ReviewDatas.reviewStatus)}}</td> -->
                  <td  style="text-align: center;">
                    <i class="fa fa-toggle-on fa-2x" data-toggle="modal" data-target=".bd-example-modal-lg5" [ngClass]="ReviewDatas.reviewStatus?'active':'fa-rotate-180 inactive'"
                        (click)="statusClick(ReviewDatas)"></i>
                </td>
                  <!-- <td>{{ReviewDatas.recommendation}}</td> <td>{{ReviewDatas.vechileNumber}}</td> -->
                 </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
  </div>

</div>
</div>
<script>
   setTimeout(function () {
  $(function () {
    $('#user-table').DataTable();
  });
}, 3000);
</script>

</div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
    </ngx-spinner>
    <div class="modal fade bd-example-modal-lg5" id="demo-customers" tabindex="-1" style="overflow:scroll;">
      <div class="modal-dialog modal-dialog-centered appointment_popup_main">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel-2">{{statusChangeAction}}  Review</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  <p>Are you sure want to {{statusChangeAction}} Review?</p>
              </div>
              <div class="modal-footer">
                   <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-success"  data-dismiss="modal" (click)="changeStatus()">{{statusChangeAction}}</button>
              </div>
          </div>
      </div>
    </div>

    <div class="modal fade bd-example-modal-lg5" id="demo-7" tabindex="-1" style="overflow:scroll;">
      <div class="modal-dialog modal-dialog-centered appointment_popup_main">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel-2">Select Start Date and End Date </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                <h6  ><b>Date Range</b></h6>
               <div class="row">
                <div class="col-md-6 form-group">
                  <label class="form_row_heading">From<span class="text-danger">*</span></label>
                   <input type="text" placeholder="MM/DD/YYYY" (change)="getStartDate($event.target.value)"
                   onfocus="(this.type='date' )"  id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="dropOffDate"  name="dropDate" #from>


                  </div>
                  <div class="col-md-6 form-group">
                    <label class="form_row_heading">To<span class="text-danger">*</span></label>
                     <input type="text" placeholder="MM/DD/YYYY" (change)="getEndDate($event.target.value)"
                     onfocus="(this.type='date' )"  id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="dropOffDate"  name="dropDate" #to>


                    </div>
              </div>
               </div>
               <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
               <button type="submit" class="btn btn-success"   data-dismiss="modal"  [disabled]="(from.value.length === 0  || to.value.length === 0  )"  (click)="getFilterdData()">Submit</button>
           </div>
          </div>
      </div>
    </div>
