import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { LoginService } from '../services/login.service';
import { LoginData } from './logins';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { NgxSpinnerService } from "ngx-spinner";
import {AuthGuard} from "../guard/AuthGuard";
import swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  LoginData = [];
  data: string[];

  public isSubmitClicked: boolean = false;
  AuthGuard: any;
  constructor(private fb: FormBuilder, private user: LoginService, private route: ActivatedRoute,
    private router: Router, private authService: AuthenticationService,private SpinnerService: NgxSpinnerService) {}

  ngOnInit(): void {
  
    this.initForm();
  }
  validateAllFormFields(formGroup: FormGroup) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }

  // onSubmit(){
  //   if (this.loginForm.valid) {
  //     console.log('form submitted');

  //   } else {
  //     this.validateAllFormFields(this.loginForm); //{7}
  //   }

  // }

  isValidInput(fieldName): boolean {
    return (
      this.loginForm.controls[fieldName].invalid &&
      (this.loginForm.controls[fieldName].dirty ||
        this.loginForm.controls[fieldName].touched)
    );
  }
  initForm(): void {
    this.loginForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'
          ),
        ],
      ],
      password: ['', Validators.required],
    });
  }
  public get loginFormControl() { return this.loginForm.controls; }

  public submitForm(): void {

    this.isSubmitClicked = true;
    if (this.loginForm.valid) {
      this.SpinnerService.show();
      this.user
        .LoginData(this.loginForm.value)
        .pipe(take(1))
        .subscribe((res: any) => {
          if (res.statusCode=== 1){
            this.authService.logdin(res.data.token);
            localStorage.setItem("usernamek", res.data.userName);
            localStorage.setItem("RoleId",res.data.roleId);
            this.SpinnerService.hide();
            if(res.data.roleId===2 )
            {
              
              this.router.navigate(['/dashboard']);
            }
            else
            {
              this.router.navigate(['/appointments']);
            }
           
            
        //     swal.fire(
        //       'Success',
        //       'Login Succesfull.',
        //     'success'
        //  );
          }
          else{
            if (res.statusCode!==1){
              this.SpinnerService.hide();
              swal.fire(
                'Error',
                "Please Enter a valid Username and Password.",
              'error'
           );
             // window.alert('Please Enter a valied Username and password');

          }
        }
        });
    }
  }
}
