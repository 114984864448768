import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.scss']
})
export class IndustriesComponent implements OnInit {
  slideConfig = { "slidesToScroll": 0.2, "mobileFirst":true, "autoplay":true , "focusOnChange": true, "slidesPerRow": 5, "rows": 2, 
  "infinite":true, "autoplaySpeed": 300000, "responsive": []};
  constructor() { }

  ngOnInit(): void {
    
  }

}
