import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import {GetappointmentsService} from '../../../getappointments.service';
import {FormBuilder,FormGroup,Validators,FormControl,} from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import{selectedServices} from '../appointments/selectedServices'
import{ServicesTechnicionsArray} from '../appointments/ServicesTechnicionsArray'
import{BindservicesTechsArray} from'../appointments/BindservicesTechsArray'
import{ CarInspectionService} from 'src/app/services/car-inspection.service'
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
//import{Services} from '../appointments/ServicesData'
import { from } from 'rxjs';
import { extend } from 'jquery';
@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit {
  public RoleId:number
  public colmd2:boolean=false;
  public colmd12:boolean=false;
  public dtOptions: any = {};
  public dtOptionsone: any = {};
public showTable: boolean = false;
walkinAddForm: FormGroup;
walkinUpdateForm: FormGroup;
Inspection: FormGroup;
selectedItemsForService:[]
selectedItemsforTech: ServicesTechnicionsArray []=[];
bindSelected: BindservicesTechsArray []=[];
sData :[]
  data=[];
  datacompleted = [];
  getallactiveappointmentsdata=[];
  makesdata: [];
  modelsdata:[];
  Servicesdata:  [];
  techsdata:[];
  carInspectionList:[];
  startDate:string;
  endDate:string;
    pipe = new DatePipe('en-US');
     now = Date.now();
  public appointment: any = {
    appointmentId: 0,
    firstName: '',
    lastName: '',
    phoneNo: '',
    email:'',
    vechileBrand:'',
    vechileModel: '',
    vechileManufacturingYear:'',
    preferredContact:'',
    dropOffDate:'',
    dropOffTime:'',
    serviceRequestedMessage:'',
    vehicleIdentificationNumber:'',
    status:'',
    mileage:0,
    customerId:0,
    clientId:0,
    reviewStatus:'',
    createdOn:'',
    reviewRemindCount:0,
    serviceRemindCount:0,
    services:'',
    comments:[''],
    appointmentServices:[],
 appointmentFrom:''
  };
  today: Date;
  appointmentFilter1: string;
  appointmentFilter2: string;
  clientId: any;
  constructor(private user:GetappointmentsService,
    private fb: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private car:CarInspectionService) 
    {
    this.today=new Date();
    this.SpinnerService.show();
      this.walkinAddForm = this.fb.group({
        firstName: ['',Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(50)])],
        lastName: [''],
        phoneNo: ['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        email:['',Validators.compose([Validators.required,Validators.email,Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")])],
        vechileBrand: ['',Validators.required],
        vechileModel: ['',Validators.required],
        mileage: ['',],
        // mileage: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
        VehicleIdentificationNumber:[''],
       // VehicleIdentificationNumber:['',Validators.compose([Validators.required, Validators.minLength(17)])],
        vechileManufacturingYear: ['',Validators.required],
        preferredContact: ['',Validators.required],
        dropOffDate: ['',Validators.required],
        dropOffTime :['',Validators.required],
        status: [''],
        comments:[''],
        AppointmentFrom:[''],
        reviewStatus: ['',],
        services:[this.selectedItemsForService,Validators.required],
        Technitions:[this.selectedItemsforTech],
        clientId: this.clientId,
        
      });

      this.walkinUpdateForm = this.fb.group({
        appointmentId: [''],
        firstName: ['',Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(50)])],
        lastName: [''],
        phoneNo: ['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        email:['',Validators.compose([Validators.required,Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
        vechileBrand: ['',Validators.required],
        vechileModel: ['',Validators.required],
        vechileManufacturingYear: ['',Validators.required],
        preferredContact: ['',Validators.required],
        dropOffDate :['',Validators.required],
        dropOffTime: ['',Validators.required],
        status: [''],
        customerId:[''],
        createdOn:[''],
        clientId: [''],
        reviewRemindCount:[''],
        // serviceRemindCount:[''],
        reviewStatus: [''],
        appointmentFrom:'',
        mileage:[0, [Validators.required, Validators.pattern("^[0-9]*$")]],
        comments:[''],
        VehicleIdentificationNumber:['',Validators.compose([Validators.required, Validators.minLength(17)])],
        services:[this.selectedItemsForService]
      });

      
 
    this.Inspection = this.fb.group({
      firstName: ['',Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(50)])],
        lastName: [''],
        phoneNo: ['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        email:['',Validators.compose([Validators.required,Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
        vechileBrand: ['',Validators.required],
        vechileModel: ['',Validators.required],
        // mileage: ['',Validators.required],
        // VehicleIdentificationNumber:['',Validators.compose([Validators.required, Validators.minLength(17)])],
        mileage: ['',],
        VehicleIdentificationNumber:['',],
        vechileManufacturingYear: ['',Validators.required],
        preferredContact: [''],
        dropOffDate: ['',Validators.required],
        dropOffTime :['',Validators.required],
        status: [''],
        comments:[''],
        AppointmentFrom:[''],
        reviewStatus: ['',],
        services:[this.selectedItemsForService],
        Technitions:[this.selectedItemsforTech],
        clientId: this.clientId,
    });
   // this.user.getClientId();
  
    

 
  

   }

   getAllServices()
   {
    this.user
    .getServices()
    .pipe(take(1))
    .subscribe((data: any) => {
  
      this.Servicesdata = data;
     
    });
  }
getAllTechnicions()
{
  this.user
  .getTechnicions( )
  .pipe(take(1))
  .subscribe((data: any) => {

    this.techsdata = data;
   
  });
}
   getAllMakes()
   {
    this.user
    .getmake()
    .pipe(take(1))
    .subscribe((data: any) => {

      this.makesdata = data;
 console.log(this.makesdata)
    });
   }
   getModels( id :number)
   {
    this.SpinnerService.show()
     this.user
     .getModelsById(id)
     .pipe(take(1))
     .subscribe((data: any) => {
 
       this.modelsdata = data;
       this.SpinnerService.hide()
     
     });
   }
 
   
   public get walkinAddFormControl() { return this.walkinAddForm.controls; }
   //Add Appointment
 WalkinAdd() : void{
  this.showTable = false;
  this.today=new Date();
  const data = {
    firstName: this.walkinAddFormControl["firstName"].value,
    lastName: this.walkinAddFormControl["lastName"].value,
    phoneNo: this.walkinAddFormControl["phoneNo"].value,
    email: this.walkinAddFormControl["email"].value,
    vechileBrand: this.walkinAddFormControl["vechileBrand"].value,
    vechileModel: this.walkinAddFormControl["vechileModel"].value,
    vechileManufacturingYear: this.walkinAddFormControl["vechileManufacturingYear"].value,
    preferredContact: this.walkinAddFormControl["preferredContact"].value,
    dropOffDate :this.walkinAddFormControl["dropOffDate"].value,
    dropOffTime :this.walkinAddFormControl["dropOffTime"].value,
    mileage :parseInt(this.walkinAddFormControl["mileage"].value),
    VehicleIdentificationNumber :this.walkinAddFormControl["VehicleIdentificationNumber"].value,
    Comments :this.walkinAddFormControl["comments"].value,
    status:"1",
    AppointmentFrom:"Walk-In",
   // services: this.selectedItemsForService,
    clientId: JSON.parse(this.clientId),
    AssignTechnicians:this.selectedItemsforTech,
    from:['add']
  }
  
  
   if (data) {
    this.SpinnerService.show();
     this.user
       .WalkinAppointmentAddandUpdate(data)
      .pipe(take(1))
       .subscribe((res: any) => {
         this.SpinnerService.hide();
       if (res.statusCode=== 1){

        // this.user.getallactiveappointments().subscribe((data: any) => {
        //   console.warn(data)
        // this.getallactiveappointmentsdata =data;
        // this.showTable=true;
        // })
        swal.fire(
           'Success',
           'Appointment Added Succesfully.',
         'success'
      );
           this.selectedItemsForService=[]
         this.selectedItemsforTech=[];
         this.bindSelected=[];
         window.location.reload(); 
         }
         else{
           if (res.statusCode!==1){
            swal.fire(
              'Cancelled!',
              'Please enter valid details.',
              'error'
            );
         
         }
       }
       });
   }
 }

//get Appointment by Id
GetAppointmentById( appointmentId :number)
{
  this.appointment = null;
  this.SpinnerService.show();
  this.user
  .getAppointmentById(appointmentId)
  .pipe(take(1))
  .subscribe((data: any) => {
    
    this.appointment = data;
    let  dateFormat = this.pipe.transform(this.appointment.dropOffDate, 'yyyy-MM-dd');
    this.walkinUpdateForm.controls['firstName'].setValue(this.appointment.firstName);
    this.walkinUpdateForm.controls['lastName'].setValue(this.appointment.lastName);
    this.walkinUpdateForm.controls['phoneNo'].setValue(this.appointment.phoneNo);
    this.walkinUpdateForm.controls['email'].setValue(this.appointment.email);
    this.walkinUpdateForm.controls['vechileBrand'].setValue(this.appointment.vechileBrand);
    this.walkinUpdateForm.controls['vechileModel'].setValue(this.appointment.vechileModel);
    this.walkinUpdateForm.controls['vechileManufacturingYear'].setValue(this.appointment.vechileManufacturingYear);
    this.walkinUpdateForm.controls['preferredContact'].setValue(this.appointment.preferredContact);
    this.walkinUpdateForm.controls['dropOffDate'].setValue(dateFormat);
    this.walkinUpdateForm.controls['dropOffTime'].setValue(this.appointment.dropOffTime);
    this.walkinUpdateForm.controls['status'].setValue(this.appointment.status);
    this.walkinUpdateForm.controls['appointmentId'].setValue(this.appointment.appointmentId);
    this.walkinUpdateForm.controls['customerId'].setValue(this.appointment.customerId);
    this.walkinUpdateForm.controls['clientId'].setValue(this.appointment.clientId);
    this.walkinUpdateForm.controls['reviewRemindCount'].setValue(this.appointment.reviewRemindCount);
    // this.walkinUpdateForm.controls['serviceRemindCount'].setValue(this.appointment.serviceRemindCount);
    this.walkinUpdateForm.controls['reviewStatus'].setValue(this.appointment.reviewStatus);
    this.walkinUpdateForm.controls['createdOn'].setValue(this.appointment.createdOn);
    this.walkinUpdateForm.controls['mileage'].setValue(this.appointment.mileage);
    this.walkinUpdateForm.controls['VehicleIdentificationNumber'].setValue(this.appointment.vehicleIdentificationNumber);
    this.walkinUpdateForm.controls['appointmentFrom'].setValue(this.appointment.appointmentFrom);
    this.walkinUpdateForm.controls['comments'].setValue(this.appointment.comments);

   // this.bindSelected= [];
this.bindSelected.length = 0;

    if(this.appointment.appointmentServices!==null)
    {
      this.appointment.appointmentServices.forEach(element => {
        let selectedService: any = this.Servicesdata.find((_: any) => _.serviceId === element.serviceId);
        let techName: any = this.techsdata.find((_: any) => _.userId ===element.technicianId);
        selectedService.isCheched = true;
        this.bindSelected.push({ServiceId:selectedService.serviceId,TechnicianId:techName.userId,TechnicianName:techName.userName,ServiceName:selectedService.serviceName,RemoveId:''});
      
      });
      
    }
    this.SpinnerService.hide();
   
  });
}


 
public get walkinUpdateFormControl() { return this.walkinUpdateForm.controls; }

WalkinUpdate() : void{
  this.SpinnerService.show();
  const data = {
    appointmentId: this.walkinUpdateFormControl["appointmentId"].value,
    firstName: this.walkinUpdateFormControl["firstName"].value,
    lastName: this.walkinUpdateFormControl["lastName"].value,
    phoneNo: this.walkinUpdateFormControl["phoneNo"].value,
    email: this.walkinUpdateFormControl["email"].value,
    vechileBrand: this.walkinUpdateFormControl["vechileBrand"].value,
    vechileModel: this.walkinUpdateFormControl["vechileModel"].value,
    vechileManufacturingYear: this.walkinUpdateFormControl["vechileManufacturingYear"].value,
    preferredContact: this.walkinUpdateFormControl["preferredContact"].value,
    dropOffDate :this.walkinUpdateFormControl["dropOffDate"].value,
    dropOffTime :this.walkinUpdateFormControl["dropOffTime"].value,
    AssignTechnicians: this.bindSelected,
    status: this.walkinUpdateFormControl["status"].value,
    mileage: this.walkinUpdateFormControl["mileage"].value,
    VehicleIdentificationNumber: this.walkinUpdateFormControl["VehicleIdentificationNumber"].value,
    customerId: this.walkinUpdateFormControl["customerId"].value,
    clientId: this.walkinUpdateFormControl["clientId"].value,
    reviewRemindCount: this.walkinUpdateFormControl["reviewRemindCount"].value,
    // serviceRemindCount :this.walkinUpdateFormControl["serviceRemindCount"].value,
    reviewStatus :this.walkinUpdateFormControl["reviewStatus"].value,
    AppointmentFrom :this.walkinUpdateFormControl["appointmentFrom"].value,
    Comments :this.walkinUpdateFormControl["comments"].value,
    createdOn:this.walkinUpdateFormControl["createdOn"].value,
    }
console.log(data,"dashboard update");
  if (data) {
    this.user
       .WalkinAppointmentAddandUpdate(data)
       .pipe(take(1))
      .subscribe((res: any) => {
         if (res.statusCode=== 1){
          this.SpinnerService.hide();
          // this.user.getallactiveappointments().subscribe((data: any) => {
          //   console.warn(data)
          // this.getallactiveappointmentsdata =data;
          // this.showTable=true;
          // })
       
          
           swal.fire(
            'Success',
            'Appointment Updated Succesfully.',
          'success'
       );

     //  (<any>$("#demo_update")).modal('hide');
       
           this.walkinUpdateForm.reset();
           window.location.reload();
         
        }
        else{
          if (res.statusCode!==1){
            swal.fire(
              'Cancelled!',
              'Please enter valid details',
              'error'
            );
           // window.alert('Please enter valied details');
       }
     }
      });
   }
  }
    
    public get walkinAddForm1Control() { return this.Inspection.controls; }
    //add nc inspection appointment
    WalkinAddForinspection() : void{
      this.today=new Date();
    this.showTable=false;
      const data1 = {
        firstName: this.walkinAddForm1Control["firstName"].value,
        lastName: this.walkinAddForm1Control["lastName"].value,
        phoneNo: this.walkinAddForm1Control["phoneNo"].value,
        email: this.walkinAddForm1Control["email"].value,
        vechileBrand: this.walkinAddForm1Control["vechileBrand"].value,
        vechileModel: this.walkinAddForm1Control["vechileModel"].value,
        vechileManufacturingYear: this.walkinAddForm1Control["vechileManufacturingYear"].value,
        preferredContact: this.walkinAddForm1Control["preferredContact"].value,
        dropOffDate :this.walkinAddForm1Control["dropOffDate"].value,
        dropOffTime :this.walkinAddForm1Control["dropOffTime"].value,
        mileage :parseInt(this.walkinAddForm1Control["mileage"].value) ,
        VehicleIdentificationNumber :this.walkinAddForm1Control["VehicleIdentificationNumber"].value,
        Comments :this.walkinAddForm1Control["comments"].value,
        status:"1",
        AppointmentFrom:"Walk-In",
       // services: this.selectedItemsForService,
        clientId: JSON.parse(this.clientId),
        AssignTechnicians:this.selectedItemsforTech
      }
      
     
      
       if (data1) {
         this.SpinnerService.show();
         this.user
           .WalkinAppointmentAddandUpdate(data1)
          .pipe(take(1))
           .subscribe((res: any) => {
            if (res.statusCode=== 1){
    
              // this.user.getallactiveappointments().subscribe((data: any) => {
              //   console.warn(data)
              // this.getallactiveappointmentsdata =data;
              // this.showTable=true;
              // })
              swal.fire(
                 'Success',
                 'Appointment Added Succesfully.',
               'success'
            );
                //  (<any>$("#demo-4")).modal('hide');
                 this.selectedItemsForService=[]
               this.selectedItemsforTech=[];
               this.bindSelected=[];
               window.location.reload();
                
               
               }
               else{
                 if (res.statusCode!==1){
                  swal.fire(
                    'Cancelled!',
                    'Please enter valid details.',
                    'error'
                  );
               
               }
             }
           });
       }
     }

    GetAppointmentByIdExsting( appointmentId :number)
   {
    this.SpinnerService.show();
    this.appointment.length = 0;
     this.user
     .getAppointmentById(appointmentId)
     .pipe(take(1))
     .subscribe((data: any) => {
      this.appointment = data;
      let  dateFormat = this.pipe.transform(this.appointment.dropOffDate, 'MM-dd-yyyy');
      // this.selectedItemsForService = [];
      // if(this.appointment.appointmentServices!==null)
      // {
      //   this.appointment.appointmentServices.forEach(element => {
      //     let selectedService: any = this.Servicesdata.find((_: any) => _.serviceId === element.serviceId);
      //     selectedService.isCheched = true;
      //     this.selectedItemsForService.push(selectedService.serviceId);
      //   });
      // }
      
       this.walkinAddForm.controls['firstName'].setValue(this.appointment.firstName);
       this.walkinAddForm.controls['lastName'].setValue(this.appointment.lastName);
       this.walkinAddForm.controls['phoneNo'].setValue(this.appointment.phoneNo);
       this.walkinAddForm.controls['email'].setValue(this.appointment.email);
       this.walkinAddForm.controls['vechileBrand'].setValue(this.appointment.vechileBrand);
       this.walkinAddForm.controls['vechileModel'].setValue(this.appointment.vechileModel);
       this.walkinAddForm.controls['vechileManufacturingYear'].setValue(this.appointment.vechileManufacturingYear);
       this.walkinAddForm.controls['preferredContact'].setValue(this.appointment.preferredContact);
       //this.walkinAddForm.controls['dropOffDate'].setValue(dateFormat );
    //   this.walkinAddForm.controls['dropOffTime'].setValue(this.appointment.dropOffTime);
     //  this.walkinAddForm.controls['mileage'].setValue(this.appointment.mileage);
       this.walkinAddForm.controls['VehicleIdentificationNumber'].setValue(this.appointment.vehicleIdentificationNumber);
     });
     this.SpinnerService.hide();
   }
    // view appoinments
    GetAppointmentviewById( appointmentId :number)
    {
      this.SpinnerService.show();
      this.user
      .getAppointmentById(appointmentId)
      .pipe(take(1))
      .subscribe((data: any) => {
        // this.selectedItemsForService=this.appointment.appointmentServices.appointmentId;
//console.log(this.selectedItemsForService);

        this.appointment = data;
      
      });
      this.SpinnerService.hide();
    }


  //For get completed appointments data on existing customers pop ups.
    getAllAppointmnets(){
      this.user.getalldata().subscribe((datacompleted: any) => {
       
       // console.log("this is comleted");
        this.datacompleted=<string[]>datacompleted;
        this.showTable = true;
      })
    }

  public VechileStatus(id: string): string{
    switch(id) {
      case "1": {
        return 'Confirmed'
        break;

      }
      case "2": {

       return 'Technician Assigned'
        break;

      }
      case "3": {

  return 'IN-Progress'
        break;

      }
      case "4": {

      return 'Work Done-Technician'
        break;

      }
      case "5": {

        return 'Confirmed by Supervisor'
          break;
  
        }
        case "6": {

          return 'Pickup Scheduled'
            break;
    
          }
          case "7": {

            return 'Cancelled'
              break;
      
            }
            case "8": {

              return 'Re-scheduled'
                break;
        
              }
              case "9": {

                return 'Accepted'
                  break;
          
                }
    }

      }

  getserviceId(e:any, id:number,servicename:string){

    if(e.target.checked){
         
          
       
          this.selectedItemsforTech.push({ServiceId: id, TechnicianId:0,TechnicianName:'',ServiceName:servicename,RemoveId:id.toString()}); //to add when checked
          this.bindSelected.push({ServiceId: id,TechnicianId:0,TechnicianName:'', ServiceName: servicename,RemoveId:id.toString()}); //to  add item when checked and adding  serviceid as string in RemoveId


    }else{
     
     

   // this.selectedItemsForService = this.selectedItemsForService.filter(item => item.ServiceId !== id);//to remove when uncheked
    this.selectedItemsforTech = this.selectedItemsforTech.filter(item => item.ServiceId !== id); // to remove unchked item when technicion changed
    this.bindSelected = this.bindSelected.filter(item => item.ServiceId !== id);//to remove service when uncheked
 
    }

   /// console.log(this.selectedItemsForService);
   
  }
  getTechId(technitionId :string,serviceId:number,serviceName:string)
  {
    
  
  if (this.selectedItemsforTech.find((x: ServicesTechnicionsArray) => x.ServiceId === serviceId)) {
   
     this.selectedItemsforTech = this.selectedItemsforTech.filter(item => item.RemoveId !== serviceId.toString());//if service id already exist then to update technicion id remove  this item
     this.selectedItemsforTech = this.selectedItemsforTech.filter(item => item.ServiceId !== serviceId);//if service id already exist then to update technicion id remove  this item
    
   }

    let techName: any = this.techsdata.find((_: any) => _.userId.toString() ===technitionId);
  
    
   
   this.selectedItemsforTech.push({ServiceId:serviceId , TechnicianId : parseInt (technitionId), TechnicianName:techName.userName,ServiceName:serviceName,RemoveId:''});//add  same item deleted above   with new techncion id
  
  }

  getTechId2(technitionId :string,serviceId:number,serviceName:string,TechnicianName:string)
  {
    if (this.bindSelected.find((x: BindservicesTechsArray) => x.ServiceId === serviceId)) {
   
      this.bindSelected = this.bindSelected.filter(item => item.ServiceId !== serviceId);//if service id already exist then to update technicion id remove  this item
     
    }
    this.bindSelected = this.bindSelected.filter(item => item.RemoveId !== serviceId.toString());//to remove same service having serviceId string //double services so deleting 1 having string
    let techName: any = this.techsdata.find((_: any) => _.userId.toString() ===technitionId);
    this.bindSelected.push({ServiceId:serviceId , TechnicianId :parseInt(technitionId) ,TechnicianName:techName.userName,ServiceName:serviceName,RemoveId:''});//
    
  
  }

  getStartDate(startDate :string,)
  {
    this.startDate=startDate;
  
   
  }

  getEndDate(endDate :string,)
  
  {
    this.endDate=endDate;
 
   
  }

  //get appoitnments data in specified dates range
  getFilterdData( )
  {
    this.showTable=false;
    this.user
    .getFilterdDataAppointments(this.startDate,this.endDate)
    .pipe(take(1))
    .subscribe((data: any) => {
     // this.getallactiveappointmentsdata.length=0;
     this.appointmentFilter1="show";
     this.appointmentFilter2="hide";
      this.showTable=true;
      this.getallactiveappointmentsdata=data;
    
     
    
    });
  }

  //get back to appointmnets from filters 
  AppoitnmentsfromFilter()
  {
    
      (<any>$("#demo-7")).modal('hide');
    this.getActiveAppointments();
  }

closepopup() {

  window.location.reload();
}

//For get completed appointments data on existing customers pop ups.
getallData()
{
this.getAllAppointmnets();
}

AppointmentStatus(status :string,)
{
  if(status==="6")
  {
this.WalkinUpdate();
  }

 else{
  
 }
}
  GetAppointmentByIdExstingForInspections( appointmentId :number)
  {
    
    this.appointment.length = 0;
    this.user
    .getAppointmentById(appointmentId)
    .pipe(take(1))
    .subscribe((data: any) => {
     this.appointment = data;
     let  dateFormat = this.pipe.transform(this.appointment.dropOffDate, 'yyyy-MM-dd');
   
    
     this.Inspection.controls['firstName'].setValue(this.appointment.firstName);
     this.Inspection.controls['lastName'].setValue(this.appointment.lastName);
      this.Inspection.controls['phoneNo'].setValue(this.appointment.phoneNo);
      this.Inspection.controls['email'].setValue(this.appointment.email);
      this.Inspection.controls['vechileBrand'].setValue(this.appointment.vechileBrand);
      this.Inspection.controls['vechileModel'].setValue(this.appointment.vechileModel);
      this.Inspection.controls['vechileManufacturingYear'].setValue(this.appointment.vechileManufacturingYear);
      this.Inspection.controls['preferredContact'].setValue(this.appointment.preferredContact);
   //   this.Inspection.controls['dropOffDate'].setValue(dateFormat );
    //  this.Inspection.controls['dropOffTime'].setValue(this.appointment.dropOffTime);
      //this.Inspection.controls['mileage'].setValue(this.appointment.mileage);
      this.Inspection.controls['VehicleIdentificationNumber'].setValue(this.appointment.vehicleIdentificationNumber);
    });
  }


  public get InspectionFormControl() { return this.Inspection.controls; }
  InspectionAdd() : void{
  
   
     
      
       if (this.Inspection.value) {
         this.car
           .AddCarinspection(this.Inspection.value)
          .pipe(take(1))
           .subscribe((res: any) => {
           if (res.statusCode=== 1){
            swal.fire(
              'Success',
              'Appointment Added Succesfully.',
            'success'
         );
             
             //  (<any>$("#demo-1")).modal('hide');
               window.location.reload();
               this.Inspection.reset();
              
               this.car
               .getCarInspections()
               .pipe(take(1))
               .subscribe((data: any) => {
                
                 this.carInspectionList = data;
                
                 this.showTable = true;
                 this.SpinnerService.hide();
              
               });
              
             }
             else{
               if (res.statusCode!==1){
                swal.fire(
                  'Cancelled!',
                  'Please enter valied details',
                  'error'
                )
             }
           }
           });
       }
     }

     //get all active  appointments 
     getActiveAppointments() : void{
      this.showTable = false;
      this.appointmentFilter1="hide";
      this.appointmentFilter2="show";
      this.user.getallactiveappointments().subscribe((data: any) => {
        this.getallactiveappointmentsdata =data;
        console.log(this.getallactiveappointmentsdata)
          this.showTable = true;
          this.SpinnerService.hide();  
        })
     }
  ngOnInit(): void {
    this.RoleId = parseInt(localStorage.getItem("RoleId"));
    if(this.RoleId === 4){
   this.colmd2=false;
   this.colmd12=true;
    }
    else{
      this.colmd2=true;
      this.colmd12=false;
    }
   this.user.getClientId();
   this.clientId  = localStorage.getItem("ClientId");
   this.getAllMakes();
   this.getAllServices();
   this.getAllTechnicions();
    this.appointmentFilter1="hide";
    this.appointmentFilter2="show";
   this.getActiveAppointments();
    jQuery(document).ready(function() {
      // click on next button
      jQuery('.form-wizard-next-btn').click(function() {
        var parentFieldset = jQuery(this).parents('.wizard-fieldset');
        var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
        var next = jQuery(this);
        var nextWizardStep = true;
        parentFieldset.find('.wizard-required').each(function(){
          var thisValue = jQuery(this).val();
    
          if( thisValue == "") {
            jQuery(this).siblings(".wizard-form-error").slideDown();
            nextWizardStep = false;
          }
          else {
            jQuery(this).siblings(".wizard-form-error").slideUp();
          }
        });
        if( nextWizardStep) {
          next.parents('.wizard-fieldset').removeClass("show");
          currentActiveStep.removeClass('active').addClass('activated').next().addClass('active');
          next.parents('.wizard-fieldset').next('.wizard-fieldset').addClass("show");
          jQuery(document).find('.wizard-fieldset').each(function(){
            if(jQuery(this).hasClass('show')){
              var formAtrr = jQuery(this).attr('data-tab-content');
              jQuery(document).find('.form-wizard-steps .form-wizard-step-item').each(function(){
                if(jQuery(this).attr('data-attr') == formAtrr){
                  jQuery(this).addClass('active');
                  var innerWidth = jQuery(this).innerWidth();
                  var position = jQuery(this).position();
                  jQuery(document).find('.form-wizard-step-move').css({"left": position.left, "width": innerWidth});
                }else{
                  jQuery(this).removeClass('active');
                }
              });
            }
          });
        }
      });
      //click on previous button
      jQuery('.form-wizard-previous-btn').click(function() {
        var counter = parseInt(jQuery(".wizard-counter").text());;
        var prev =jQuery(this);
        var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
        prev.parents('.wizard-fieldset').removeClass("show");
        prev.parents('.wizard-fieldset').prev('.wizard-fieldset').addClass("show");
        currentActiveStep.removeClass('active').prev().removeClass('activated').addClass('active');
        jQuery(document).find('.wizard-fieldset').each(function(){
          if(jQuery(this).hasClass('show')){
            var formAtrr = jQuery(this).attr('data-tab-content');
            jQuery(document).find('.form-wizard-steps .form-wizard-step-item').each(function(){
              if(jQuery(this).attr('data-attr') == formAtrr){
                jQuery(this).addClass('active');
                var innerWidth = jQuery(this).innerWidth();
                var position = jQuery(this).position();
                jQuery(document).find('.form-wizard-step-move').css({"left": position.left, "width": innerWidth});
              }else{
                jQuery(this).removeClass('active');
              }
            });
          }
        });
      });
      //click on form submit button
      jQuery(document).on("click",".form-wizard .form-wizard-submit" , function(){
        var parentFieldset = jQuery(this).parents('.wizard-fieldset');
        var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
        parentFieldset.find('.wizard-required').each(function() {
          var thisValue = jQuery(this).val();
          if( thisValue == "" ) {
            jQuery(this).siblings(".wizard-form-error").slideDown();
          }
          else {
            jQuery(this).siblings(".wizard-form-error").slideUp();
          }
        });
      });
      // focus on input field check empty or not
      jQuery(".form-control").on('focus', function(){
        var tmpThis = jQuery(this).val();
        if(tmpThis == '' ) {
          jQuery(this).parent().addClass("focus-input");
        }
        else if(tmpThis !='' ){
          jQuery(this).parent().addClass("focus-input");
        }
      }).on('blur', function(){
        var tmpThis = jQuery(this).val();
        if(tmpThis == '' ) {
          jQuery(this).parent().removeClass("focus-input");
          jQuery(this).siblings('.wizard-form-error').slideDown("3000");
        }
        else if(tmpThis !='' ){
          jQuery(this).parent().addClass("focus-input");
          jQuery(this).siblings('.wizard-form-error').slideUp("3000");
        }
      });
    });
    
    this.today=new Date();
     
   // this.today=new Date();

//   // extra demo 3 content


  (<any>$(document)).ready(function() {
    function filterColumns ( i: string ) {
      $('#examplepopups').DataTable().column( i ).search(
        (<any>$('#cols'+i+'_filter')).val(),
        (<any>$('#cols'+i+'_smart')).val(),
      ).draw();
  }
      $('#examplepopups').DataTable();
      $('input.column_filter').on( 'keyup click', function () {
          filterColumns( $(this).parents('tr').attr('data-column') );
      } );
  } );
    $("#cols0_filter").keyup(function(){
      if($(this).val()) {
          $("#showDivs").show();
      } else {
          $("#showDivs").hide();
      }
  });
  $("#cols1_filter").keyup(function(){
    if($(this).val()) {
        $("#showDivs").show();
    } else {
        $("#showDivs").hide();
    }
});
$("#cols2_filter").keyup(function(){
  if($(this).val()) {
      $("#showDivs").show(); 
  } else {
      $("#showDivs").hide();
  }
});

  // extra content end 
  (<any>$(document)).ready(function() {
    function filterColumn ( i: string ) {
      $('#examplepopup').DataTable().column( i ).search(
        (<any>$('#col'+i+'_filter')).val(),
        (<any>$('#col'+i+'_smart')).val(),
      ).draw();
  }
      $('#examplepopup').DataTable();
      $('input.column_filter').on( 'keyup click', function () {
          filterColumn( $(this).parents('tr').attr('data-column') );
      } );
  } );
    $("#col0_filter").keyup(function(){
      if($(this).val()) {
          $("#showDiv").show();
      } else {
          $("#showDiv").hide();
      }
  });
  $("#col1_filter").keyup(function(){
    if($(this).val()) {
        $("#showDiv").show();
    } else {
        $("#showDiv").hide();
    }
});
$("#col2_filter").keyup(function(){
  if($(this).val()) {
      $("#showDiv").show();
  } else {
      $("#showDiv").hide();
  }
});
  $(document).ready(function(){
    if(  $('#examplepopup tr').length >= 5 ) {
         $('#examplepopup').addClass('add-scroll');
     }
   });

  $(document).ready(function(){
    (<any>$("#smartwizard"))
    .smartWizard({
    selected: 0,
    theme: 'dots',
    autoAdjustHeight:false,
    transitionEffect:'fade',
    showStepURLhash: false,

    });
    });

    $('.modal #demo-4').on('hidden.bs.modal', function(){
      $(this).find('form')[0].reset();
  });

    $('.modal').on('hidden.bs.modal', function(){
      $(this).find('form')[0].reset();
  });

    $('.modal demo_update').on('hidden.bs.modal', function(){
      $(this).find('form')[0].reset();
  });

  $('.modal #demo-2').on('hidden.bs.modal', function(){
      $(this).find('form')[0].reset();
  });
 
    this.SpinnerService.show();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
    lengthMenu : [ 5, 10, 25, 50, 100],
    order: [[ 0, 'desc' ], [ 1, 'desc' ]],
      processing: true,
      dom:
      "<'row'<'col-sm-6'i><'col-sm-6'f>>" +
    "<'row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-4'l><'col-sm-8'p>>" +
    "<'row'<'col-sm-6'B>>",

      //"sDom": 'Rfrt<lipB>',
      // dom:'frtlipB',
      buttons: [
           'excel', 'print',
      {
        extend:'pdfHtml5',
        text:'Pdf',
        orientation:'landscape',
        
    }]
    };
  
  
  //this.selectedItemsForService = new Array<selectedServices>();

  this.selectedItemsforTech = new Array<ServicesTechnicionsArray>();
  this.bindSelected = new Array<BindservicesTechsArray>();
  
  }

  smartWizard(arg0: { selected: 0; theme: 'dots'; autoAdjustHeight: false; transitionEffect: 'fade'; showStepURLhash: false; }) {
    throw new Error('Method not implemented.');
  }

}

//'copy', 'csv', 'excel', 'pdf', 'print'

