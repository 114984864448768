import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {ForgotpasswordService } from '../services/forgotpassword.service';
import { take } from 'rxjs/operators';
import swal from 'sweetalert2';
import { MustMatch } from './confirmed.validator';
@Component({
  selector: 'app-welcomepassword',
  templateUrl: './welcomepassword.component.html',
  styleUrls: ['./welcomepassword.component.css']
})
export class WelcomepasswordComponent implements OnInit {
  userId:string;
  wellcomeForm:FormGroup;
  submitted = false;
  constructor(  private router: Router,private user: ForgotpasswordService,private fb: FormBuilder,private route: ActivatedRoute,private SpinnerService: NgxSpinnerService) { }
  ngOnInit(): void {
    
    this.userId= this.route.snapshot.queryParamMap.get("param");
    console.log(this.userId);

    this.wellcomeForm = this.fb.group({
   
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
  }, {
      validator: MustMatch('password', 'confirmPassword')
  });
  }
  get f() { return this.wellcomeForm.controls; }

  CheckValidation(){
   
  this.submitted = true;

        // stop here if form is invalid
        if (this.wellcomeForm.valid) {
          this.setPassword();
        }
        else
        {
           return;
        }

}
public get setpasswordFormControl() { return this.wellcomeForm.controls; }
setPassword()
{
  this.SpinnerService.show();
  const data ={
    Param: this.userId,
    Newpassword:this.setpasswordFormControl["password"].value
  }
  console.log(data)
  if (data) {
    this.user.Setpassword(data)
     .pipe(take(1))
      .subscribe((res: any) => {
        console.log(res)
        this.SpinnerService.hide(); 
        if (res.statusCode=== 1){
          swal.fire(
            'Success',
            'Password Changed Succesfully.',
          'success'
       );
         // this.wellcomeForm.reset();
           // window.alert('Password Changed Succesfully.');
            this.router.navigate(['/login']);
              this.SpinnerService.hide();
          }
          else{
            if (res.statusCode===2){
              swal.fire(
                'Cancelled',
                'User Not Available.',
              'error'
           );
            //  window.alert('User Not Available');
          }
          else {
            if (res.statusCode===3){
              swal.fire(
                'Cancelled', 
                "Enterd Password can't be previous Password.",
              'error'
           );
            //  window.alert("Enterd Password can't be previous Password");
          }
        }
    }
      });
    }
}
}

