<app-navbar></app-navbar>
<div class="row mainrow_margin">
  <div class="col-md-2">
    <app-sidenav></app-sidenav>
  </div>
  <div class="col-md-10">
    <div class="col main pt-1 mt-1">
      <div class="main_section">
        <h4>Dashboard</h4>
      </div>
      <div class="row dashboardappointments ">
        <div class="container">
          <h5 class="appoinment_heading">Appointment Details</h5>
          <div class="table-responsive">
            <table class="table table-bordered Appointmenttable" id="example">
              <thead class="thead-dark">
                <tr>
                  <th>Ticket Id</th>
                  <th style="    width: 106px;">First Name</th>
                  <th>Last Name</th>
                  <th>Contact <br> Details</th>
                  <th style="width:300px;">Service Date</th>
                  <th style="width:300px;">Services Requested</th>
                  <th style="width:300px;">Additional Services</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Year</th>
                  <th>Status</th>
                  <th style="width: 500px;">Details</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of gettodayandinprogressappointmentsData">
                  <td>{{data.appointmentId}}</td>
                  <td>{{data.firstName}}</td>
                  <td>{{data.lastName}}</td>
                  <td>{{data.phoneNo}}</td>
                  <!-- <td>{{datas.email}}</td> -->
                  <td style="width: 160px;">{{data.dropOffDate | date:'MM-dd-yyyy'}}</td>
                  <!-- <td>{{datas.preferredContact}}</td> -->
                  <td>{{data.serviceRequested}}</td>
                  <td>{{data.additionalServices}}</td>
                  <td>{{data.vechileBrand}}</td>
                  <td>{{data.vechileModel}}</td>
                  <td>{{data.vechileManufacturingYear}}</td>
                  <td>{{VechileStatus(data.status)}}</td>
                  <td style="width:105px;"><button class="btn btn-primary viewbutton" data-toggle="modal"
                      data-target=".bd-example-modal-lg1" (click)="GetAppointmentById(data.appointmentId)"><i
                        class="fa fa-edit"></i> </button>
                    <button class="btn btn-primary eyebutton" data-toggle="modal" data-target=".bd-example-modal-lg5"
                      (click)="GetAppointmentviewById(data.appointmentId)"><i class="fa fa-eye"
                        aria-hidden="true"></i></button>
                  </td>
                  <!-- <td>{{datas.status}}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div class="viewmore">
            <a routerLink="/appointments">View More >></a>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-xl-4 col-sm-6 py-2">
          <div class="card bg-success text-white h-55">
            <div class="card-body bg-success">
              <h5 class="">Customers</h5>
              <h1 class="display-4"> {{dashboardData.totalcustomersCount}}</h1>
              <h6 style="float: right;"> <a style="color:#fff;" routerLink="/Customers">View More >></a></h6>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6 py-2">
          <div class="card text-white bg-danger h-55">
            <div class="card-body bg-danger">
              <h5 class="">Total Reviews</h5>
              <h1 class="display-4">{{dashboardData.totalReviewCount}}</h1>
              <h6 style="float: right;"> <a style="color:#fff;" routerLink="/reviews">View More >></a></h6>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6 py-2">
          <div class="card text-white bg-info h-55">
            <div class="card-body bg-info">
              <h5 class="">Reminders</h5>
              <h1 class="display-4">{{dashboardData.totalRemindersCount}}</h1>
              <h6 style="float: right;"> <a style="color:#fff;" routerLink="/Reminders">View More >></a></h6>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-md-6">
          <div class="card appointment_grfcss" style="padding:10px!important;">
            <h5 class="section_twoheading">Appointments</h5>
            <ul class="nav nav-tabs filterbox" role="tablist">
              <li class="nav-item btnmtac1">
                <a class="nav-link active" data-toggle="tab" (click)="getchartnumber1(3)">3M</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber1(6)">6M</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber1(11)">YTD</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber1(12)">1Y</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber1(36)">3Y</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber1(40)">MAX</a>
              </li>
            </ul>

            <canvas class="convas_section" baseChart #visitSaleChart1 [chartType]="'bar'"
              [datasets]="visitSaleChartData1" [labels]="visitSaleChartLabels1" [options]="visitSaleChartOptions1"
              [colors]="visitSaleChartColors1"></canvas>

            <br>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card appointment_grfcss" style="padding:10px!important;">
            <h5 class="section_twoheading">Reviews</h5>
            <ul class="nav nav-tabs filterbox" role="tablist">
              <li class="nav-item btnmtac1">
                <a class="nav-link active" data-toggle="tab" (click)="getchartnumber3(3)">3M</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber3(6)">6M</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber3(11)">YTD</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber3(12)">1Y</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber3(36)">3Y</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber3(40)">MAX</a>
              </li>
            </ul>

            <canvas class="convas_section" baseChart #visitSaleChart1 [chartType]="'bar'"
              [datasets]="visitSaleChartData3" [labels]="visitSaleChartLabels3" [options]="visitSaleChartOptions3"
              [colors]="visitSaleChartColors3"></canvas>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="card appointment_grfcss" style="padding:10px!important;">
            <h5 class="section_twoheading" style="margin-left:25px;">Service Reminders</h5>

            <ul class="nav nav-tabs filterbox" role="tablist">
              <li class="nav-item btnmtac1">
                <a class="nav-link active" data-toggle="tab" (click)="getchartnumber2(3)">3M</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber2(6)">6M</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber2(11)">YTD</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber2(12)">1Y</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber2(36)">3Y</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber2(40)">MAX</a>
              </li>
            </ul>

            <canvas class="convas_section" baseChart #visitSaleChart1 [chartType]="'bar'"
              [datasets]="visitSaleChartData2" [labels]="visitSaleChartLabels2" [options]="visitSaleChartOptions2"
              [colors]="visitSaleChartColors2"></canvas>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card appointment_grfcss" style="padding:10px!important;">
            <h5 class="section_twoheading" style="margin-left:25px;">Review Reminders</h5>
            <ul class="nav nav-tabs filterbox" role="tablist">
              <li class="nav-item btnmtac1">
                <a class="nav-link active" data-toggle="tab" (click)="getchartnumber4(3)">3M</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber4(6)">6M</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber4(11)">YTD</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber4(12)">1Y</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber4(36)">3Y</a>
              </li>
              <li class="nav-item btnmtac1">
                <a class="nav-link" data-toggle="tab" (click)="getchartnumber4(40)">MAX</a>
              </li>
            </ul>
            <canvas class="convas_section" baseChart #visitSaleChart1 [chartType]="'bar'"
              [datasets]="visitSaleChartData4" [labels]="visitSaleChartLabels4" [options]="visitSaleChartOptions4"
              [colors]="visitSaleChartColors4"></canvas>
          </div>
        </div>



      </div>
    </div>

    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
      <p style="color: white">Please Wait. </p>
    </ngx-spinner>

    <div class="modal fade bd-example-modal-lg1" id="demo_update" tabindex="-1" role="dialog"
      aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content" style="padding-left:15px;padding-right:15px;">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closepopup()" style="position: absolute;
      right: 5px;border-radius: 50%;top: 5px;width: 35px;height: 35px;">
            <i class="fa fa-times" style="color:#fff;font-size: 20px;
      margin-left: -3px;" aria-hidden="true"></i></button>

          <h4 class="modal-title col-md-9 text-danger" id="exampleModalLabel" style="margin-top: 15px;">Update
            Appointment</h4>
          <div class="modal-body">
            <section class="wizard-section">
              <div class="row no-gutters">
                <div class="col-lg-12 col-md-12">
                  <div class="form-wizard">
                    <form class="contactform" [formGroup]="walkinUpdateForm" (ngSubmit)="WalkinUpdate()">
                      <div class="form-wizard-header">
                        <ul class="list-unstyled form-wizard-steps clearfix">
                          <li class="active">
                            <span>1</span><br>
                            <p>Service Information</p>
                          </li>
                          <li><span>2</span><br>
                            <p>Customer Information</p>
                          </li>
                          <li><span>3</span><br>
                            <p>Technician Information</p>
                          </li>
                        </ul>
                      </div>
                      <fieldset class="wizard-fieldset show">

                        <div class=" row">
                          <div class="col-md-8 form-group">
                            <label class="form_row_heading">Selected Services:<span class="text-danger">*</span></label>
                            <div style="height: 80px;
                        overflow-y: scroll;">
                              <div style="column-count: 3;
                        line-height: 25px;">
                                <div id="ddlMake" *ngFor="let services of Servicesdata">
                                  <input type="checkbox" name="services" formControlName="services"
                                    checked="{{services.isCheched}}" [value]="services.serviceId"
                                    (change)="getserviceId($event,services.serviceId,services.serviceName)">
                                  {{services.serviceName}}
                                </div>
                              </div>
                            </div>


                          </div>

                          <div class="col-md-3 form-group">
                            <label class="form_row_heading">Status <span class="text-danger">*</span></label>
                            <select class="form-control" id="status" formControlName="status" name="status"
                              (change)="AppointmentStatus($event.target.value)">

                              <option value="1">Confirmed</option>
                              <option value="2">Technician Assigned</option>
                              <option value="3">In-Progress</option>
                              <option value="4">Work Done-Technician</option>
                              <option value="5">Confirmed by Supervisor</option>
                              <option value="6">Pickup Scheduled</option>
                              <option value="7">Cancelled</option>
                              <option value="8">Re-schedule</option>
                              <option value="9">Accepted</option>
                            </select>
                          </div>
                        </div>

                        <div class=" row">
                          <div class="col-md-10 form-group">
                            <div class="row">
                              <div class="col-md-6 form-group">
                                <label class="form_row_heading">Additional services/Comments</label>
                                <textarea type="text" class="form-control" id="comments" style="height: 100px;"
                                  formControlName="comments" placeholder="Enter your comments"
                                  name="comments"></textarea>
                              </div>
                              <div class="col-md-3 form-group">
                                <label class="form_row_heading">Service Date <span class="text-danger">*</span></label>
                                <input type="text" placeholder="MM/DD/YYYY" onfocus="(this.type='date' )" id="date"
                                  bsDatepicker minDate="today" class="form-control wizard-required"
                                  formControlName="dropOffDate" id="dropDate" name="dropDate">
                                <div class="wizard-form-error">Select Service date</div>
                              </div>

                              <div class="col-md-3 form-group">


                                <label class="form_row_heading">Appointment Time <span
                                    class="text-danger">*</span></label>
                                <input type="time" min="07:30" max="18:00" class="form-control wizard-required"
                                  id="dropTime" formControlName="dropOffTime" name="dropTime">

                                <div class="wizard-form-error">Time Is Required</div>
                              </div>


                            </div>


                          </div>

                        </div>


                        <div class="form-group clearfix">
                          <a href="javascript:;" class="form-wizard-next-btn float-right">Next</a>
                        </div>
                      </fieldset>
                      <fieldset class="wizard-fieldset">

                        <div class="row">

                          <div class="col-md-6 form-group">

                            <label class="form_row_heading">Vehicle Details</label>
                          </div>


                        </div>

                        <div class=" row" style="margin-top: -15px">
                          <div class="col-md-3 form-group">
                            <label class="font-weight-bold">Year <span class="text-danger">*</span></label>
                            <select aria-readonly="true" class="form-control wizard-required" id="txtYear1"
                              formControlName="vechileManufacturingYear" name="txtYear1">
                              <option style="color: black;" value="{{appointment.vechileManufacturingYear}}">
                                {{appointment.vechileManufacturingYear}} </option>
                              <option value="2021">2021</option>
                              <option value="2020">2020</option>
                              <option value="2019">2019</option>
                              <option value="2018">2018</option>
                              <option value="2017">2017</option>
                              <option value="2016">2016</option>
                              <option value="2015">2015</option>
                              <option value="2014">2014</option>
                              <option value="2013">2013</option>
                              <option value="2012">2012</option>
                              <option value="2011">2011</option>
                              <option value="2010">2010</option>
                              <option value="2009">2009</option>
                              <option value="2008">2008</option>
                              <option value="2007">2007</option>
                              <option value="2006">2006</option>
                              <option value="2005">2005</option>
                              <option value="2004">2004</option>
                              <option value="2003">2003</option>
                              <option value="2002">2002</option>
                              <option value="2001">2001</option>
                              <option value="2000">2000</option>

                            </select>
                            <div class="wizard-form-error">year Is Required</div>

                          </div>
                          <div class="col-md-3 form-group">

                            <!--  <input type="text" class="form-control" id="txtMake" placeholder=" Make(Brand) ">-->
                            <label class="font-weight-bold">Make<span class="text-danger">*</span></label>
                            <select class="form-control" id="ddlMake1" name="ddlMake1" formControlName="vechileBrand"
                              (change)="getModels($event.target.value)">
                              <option style="color: black;" value="{{appointment.vechileBrand}}">
                                {{appointment.vechileBrand}} </option>
                              <option *ngFor="let makes of makesdata" [value]="makes.makeId">{{makes.makeName}}</option>
                            </select>
                            <span style="font-weight: bold;color: red;"
                              *ngIf="!walkinUpdateForm.controls['vechileBrand'].valid&&walkinUpdateForm.controls['vechileBrand'].dirty">
                              <span *ngIf="walkinUpdateForm.controls.vechileBrand.errors.required">year is
                                Required.</span>
                            </span>
                          </div>

                          <div id="ddlModel" class="form-group col-md-3">
                            <label class="font-weight-bold">Model <span class="text-danger">*</span></label>
                            <select class="form-control" formControlName="vechileModel" name="vechileModel1">
                              <option style="color: black;" value="{{appointment.vechileModel}}">
                                {{appointment.vechileModel}} </option>
                              <option *ngFor="let models of modelsdata" [value]="models.modelName">{{models.modelName}}
                              </option>
                            </select>

                          </div>

                        </div>

                        <div class="row">

                          <div class="col-md-3 form-group">
                            <label class="font-weight-bold">Mileage</label>
                            <input type="text" class="form-control " id="mileage" formControlName="mileage"
                              placeholder="Car Mileage" name="mileage">

                          </div>
                          <div class="col-md-3 form-group">
                            <label>VIN Number</label>
                            <input type="text" class="form-control " id="mileage"
                              formControlName="VehicleIdentificationNumber" placeholder="Example:SHUDGUD5457"
                              name="VehicleIdentificationNumber" maxlength="17">

                          </div>
                          <div class="col-md-6 form-group">
                            <label class="font-weight-bold" style="margin-top: 36px;">Preferred Contact Method:<span
                                class="text-danger">*</span></label>
                            <label class="radio-inline ml-4">
                              <input readonly class="mr-1 " formControlName="preferredContact" type="radio"
                                name="preferredContact" id="any" value="Any" checked><b>Any</b>
                            </label>
                            <label class="radio-inline ml-4">
                              <input readonly class="mr-1 " formControlName="preferredContact" type="radio"
                                name="preferredContact" id="mobile" value="Mobile"><b>Mobile</b>
                            </label>
                            <label class="radio-inline ml-4">
                              <input readonly class="mr-1 " formControlName="preferredContact" type="radio"
                                name="preferredContact" id="email" value="Email"><b>Email</b>
                            </label>


                          </div>
                        </div>

                        <div class="row">

                          <div class="col-md-6 form-group">

                            <label class="form_row_heading">Customer Details</label>
                          </div>


                        </div>
                        <div class="row" style="margin-top: -15px">
                          <div class="col-md-3 form-group">
                            <label>First Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control wizard-required" id="txtfName"
                              formControlName="firstName" placeholder="First Name" name="txtfName"
                              aria-controls="example" required>
                            <div class="wizard-form-error">First Name is Required</div>
                          </div>

                          <div class="col-md-3 form-group">
                            <label>Last Name </label>
                            <input type="text" class="form-control " id="txtlName" formControlName="lastName"
                              placeholder="Last Name" name="txtlName">
                            <!-- <div class="wizard-form-error">Last Name is Required</div> -->
                          </div>


                          <div class="col-md-3 form-group">

                            <label>Mobile Number <span class="text-danger">*</span></label>
                            <input type="text" class="form-control wizard-required" formControlName="phoneNo"
                              placeholder="Mobile Number" maxlength="10">
                            <div style="color: #d70b0b;"
                              *ngIf="walkinUpdateForm.controls.phoneNo.touched && walkinUpdateForm.controls.phoneNo.invalid">

                              <div style="color: #d70b0b; margin-top:15px ;"
                                *ngIf="walkinUpdateForm.controls.phoneNo.errors.pattern">Please, Enter 10 digit Mobile
                                Number.</div>
                            </div>
                            <div class="wizard-form-error">Mobile Number is Required</div>
                          </div>

                          <div class="col-md-3 form-group">
                            <label>Email <span class="text-danger">*</span></label>
                            <input type="email" class="form-control wizard-required" formControlName="email"
                              placeholder="Email">
                            <div style="color: #d70b0b;"
                              *ngIf="!walkinUpdateForm.controls['email'].valid&&walkinUpdateForm.controls['email'].dirty">

                              <span *ngIf="walkinUpdateForm.controls.email.errors.email">Invalid Email</span>
                            </div>
                            <div style="margin-top:15px ;" class="wizard-form-error"> Email is Required</div>
                          </div>



                        </div>

                        <div class="form-group clearfix">
                          <a href="javascript:;" class="form-wizard-previous-btn float-left">Previous</a>
                          <a href="javascript:;" class="form-wizard-next-btn float-right">Next</a>
                        </div>
                      </fieldset>

                      <fieldset class="wizard-fieldset">
                        <div class="row">
                          <div class="col-md-5">
                            <!-- <label class="form_row_heading">Technicians<span class="text-danger">*</span></label> -->
                            <div class="row">
                              <div class="col-md-4">
                                <label class="form_row_heading">Technicians<span class="text-danger">*</span></label>
                              </div>
                              <div class="col-md-2">

                              </div>
                              <div class="col-md-2">
                                <label class="form_row_heading">Id's</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">

                              <div class="col-md-7">
                                <label class="form_row_heading ">Selected Services <span
                                    class="text-danger">*</span></label>
                              </div>

                              <div class="col-md-5">
                                <label class="form_row_heading ">Technician Assigned <span
                                    class="text-danger">*</span></label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-5" style="    background-color: #f1f1f1;
                      padding: 15px;
                      height: 250px;
                      overflow-y: scroll;">


                            <div class="techs-div" *ngFor="let data of techsdata">


                              <div class="row">
                                <div class="col-md-4">
                                  {{data.userName}}
                                </div>
                                <div class="col-md-2">
                                  :
                                </div>
                                <div class="col-md-3">
                                  <b>{{data.userId}}</b>
                                </div>
                              </div>
                            </div>


                          </div>

                          <div class="col-md-7">
                            <div style="    background-color: #f1f1f1;
                          padding: 15px;
                          height: 250px;
                          overflow-y: scroll;">

                              <div *ngFor="let data of bindSelected" class="row">
                                <div class="col-md-6">{{data.ServiceName}} :
                                </div>

                                <div class="col-md-5">
                                  <select
                                    (change)="getTechId2($event.target.value,data.ServiceId,data.ServiceName,data.TechnicianName)"
                                    class="form-control">
                                    <option style="color: black;" value="{{data.TechnicianName}}">
                                      {{data.TechnicianName}} </option>
                                    <option *ngFor="let data1 of techsdata" value="{{data1.userId}}">{{data1.userName}}
                                    </option>

                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="form-group clearfix">
                          <a href="javascript:;" class="form-wizard-previous-btn float-left">Previous</a>
                          <button type="submit" style="float: right;"
                            class="btn  btn-danger text-white text-center">Submit</button>

                          <!-- <a href="javascript:;" class="form-wizard-submit float-right">Submit</a> -->
                        </div>

                        <input type="hidden" class="form-control" formControlName="appointmentId" name="appointmentId">
                        <input type="hidden" class="form-control" formControlName="customerId" name="customerId">
                        <input type="hidden" class="form-control" formControlName="clientId" name="clientId">
                        <input type="hidden" class="form-control" formControlName="reviewRemindCount"
                          name="reviewRemindCount">
                        <!-- <input type="text"   class="form-control"  formControlName="serviceRemindCount"    name="serviceRemindCount"> -->
                        <input type="hidden" class="form-control" formControlName="reviewStatus" name="reviewStatus">
                        <input type="hidden" class="form-control" formControlName="createdOn" name="createdOn">
                        <input type="hidden" class="form-control" formControlName="appointmentFrom"
                          name="appointmentFrom">
                        <input type="hidden" class="form-control" formControlName="comments" name="comments">
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

    <!-- view customer model popup -->
    <div class="modal fade bd-example-modal-lg5" id="demo-customers" tabindex="-1" style="overflow:scroll;">
      <div class="modal-dialog modal-xl appointment_popup_main">
        <div class="modal-content" style="padding-left:15px;padding-right:15px;">
          <h4 class="modal-title col-md-9 text-danger" id="exampleModalLabel" style="margin-bottom:0px;
            margin-top:15px;
            margin-left: 15px;">Appointment Details</h4>
          <button type="button" class="btn btn-danger" data-dismiss="modal" style="position: absolute;
            right: 4px;border-radius: 50%;
            top: 2px;">&times;</button>
          <button type="button" class="close" data-dismiss="modal"><i class="icon-xs-o-md"></i></button>
          <div class="modal-body" style="font-size: 13px;">


            <div class="row" style="margin-top: 10px; margin-left: 240px; font-size: 14px;">
              <div class="col-md-3">
                <label class="font-weight-bold">Customer ID </label>
                <label> : {{appointment.customerId}}</label>

              </div>

              <div class="col-md-3">
                <label class="font-weight-bold">Appointment ID</label>
                <label> : {{appointment.appointmentId}}</label>

              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">Appointment Type</label>

                <label>: {{appointment.appointmentFrom}}</label>
              </div>
            </div>
            <div class="row">

              <div class="col-md-6 form-group">

                <ul class="nav nav-tabs" style="height: 38px;margin-bottom: 15px;">
                  <li class="nav-item">
                    <a class="nav-link active"
                      style="
                        color: white;border-top-right-radius: 25px ;    height: 38px;font-weight: 700;     padding-bottom: 27px;">Customer
                      Details</a>
                  </li>


                </ul>
              </div>


            </div>
            <div class="row" style="margin-top: -15px">
              <div class="col-md-4 form-group">
                <label class="font-weight-bold"> Name </label>
                <label>: {{appointment.firstName}} {{appointment.lastName}}</label>

              </div>

              <div class="col-md-4 form-group">
                <label class="font-weight-bold">Mobile Number </label>
                <label>: {{appointment.phoneNo}}</label>
              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">Email </label>
                <label>: {{appointment.email}}</label>
              </div>




            </div>



            <div class="row">

              <div class="col-md-6 form-group">


                <ul class="nav nav-tabs" style="height: 38px; margin-bottom:10px ; ">
                  <li class="nav-item">
                    <a class="nav-link active"
                      style="
                          color: white;border-top-right-radius: 25px ;    height: 38px;font-weight: 700;padding-bottom: 27px; padding-right: 30px;">Vehicle
                      Details</a>
                  </li>

                </ul>
              </div>


            </div>


            <div class=" row" style="margin-top: -15px">
              <div class="col-md-4 form-group">
                <label class="font-weight-bold">Year </label>
                <label>: {{appointment.vechileManufacturingYear}}</label><br>
              </div>
              <div class="col-md-4 form-group">

                <!--  <input type="text" class="form-control" id="txtMake" placeholder=" Make(Brand) ">-->
                <label class="font-weight-bold">Make</label>
                <label>: {{appointment.vechileBrand}}</label><br>
              </div>
              <div id="ddlModel" class="form-group col-md-4">
                <label class="font-weight-bold">Model </label>
                <label>: {{appointment.vechileModel}}</label><br>
              </div>

            </div>


            <div class="row">

              <div class="col-md-4">
                <label class="font-weight-bold">Mileage </label>
                <label>: {{appointment.mileage}} miles</label>
              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">VIN </label>
                <label>: {{appointment.vehicleIdentificationNumber}}</label>
              </div>
            </div>

            <div class="row">

              <div class="col-md-6 form-group">



                <ul class="nav nav-tabs" style="height: 38px; margin-top: 15px; ">
                  <li class="nav-item">
                    <a class="nav-link active"
                      style="
                        color: white;border-top-right-radius: 25px ;    height: 38px;font-weight: 700;padding-bottom: 27px; padding-right: 30px;">Service
                      Details</a>
                  </li>

                </ul>
              </div>
            </div>
            <div class="row" style="margin-top: -10px;">
              <div class="col-md-4">
                <label class="font-weight-bold ">Services Requested </label>

                <label class="mt-3 mb-3"> : {{appointment.serviceRequested}}</label>
              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">Additional Services </label>

                <label class="mt-3 mb-3"> : {{appointment.additionalServices}}</label>
              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">Removed Services </label>

                <label class="mt-3 mb-3"> : {{appointment.removedServices}}</label>
              </div>
            </div>

            <div class="row" style="margin-top: 10px;">

              <div class="col-md-4">
                <label class="font-weight-bold">Drop Off Date</label>

                <label>: {{appointment.dropOffDate | date:'MM-dd-yyyy'}}</label>
              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">Drop Off Time</label>

                <label> : {{appointment.dropOffTime}}</label>
              </div>

              <div class="col-md-4">
                <label class="font-weight-bold">Comments</label>

                <label>: {{appointment.comments}}</label>
              </div>
            </div>

            <div style="margin-top: 15px;">
              <div class="row">
                <div class="col-md-6">
                  <label class="font-weight-bold">Appointment status</label>

                  <label> : {{VechileStatus(appointment.status)}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>