import { Injectable } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {ReviewsData} from './app/components/reviews/ReviewData'
import {allreviewsData} from './app/components/allreviews/allreviewss'
import { data } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  ClientId: any;

  constructor(private http:HttpClient) {
    this.ClientId  = localStorage.getItem("ClientId");
   }
  //  getClientId()
  //  {
  //    this.ClientId  = localStorage.getItem("ClientId");
  //    if(this.ClientId==null)
  //    {
       
  //    }
  //   return this.ClientId;
  //  }
  public getReviews(): Observable<any>{
    let url=`${environment.apiUrl}superAdmin/GetReviews?clientId=`+this.ClientId;
    
    return this.http.get<any>(url);
  }
  public getReviewsonAllReviews(ClientId): Observable<any>{
    let url=`${environment.apiUrl}superAdmin/GetReviews?clientId=`+ClientId;
    
    return this.http.get<any>(url);
  }


  GetAppointmentById(appointmentId){
    let url=`${environment.apiUrl}superAdmin/GetReviews?ClientId=`+this.ClientId+'&appointmentId='+appointmentId;
    return this.http.get(url);
  }
    public Addreview(data:any):Observable<any>{
    
     let url=`${environment.apiUrl}superAdmin/AddReviews`;
      console.log('form submitted services page');
      return this.http.post<any>(url,data);
    }

    changeUserStatus(reviewId) {
      let url=`${environment.apiUrl}superAdmin/changereviewstatus?ClientId=`+this.ClientId+"&ReviewId="+reviewId;
      return this.http.post<any>(url,data);
      
     
    }

     FilteredReviews(startDate,endDate){
      let url=`${environment.apiUrl}superAdmin/GetReviews?ClientId=`+this.ClientId+'&StartDate='+startDate+'&EndDate='+endDate;
      return this.http.get(url);
    }
 
}
